import React from 'react';
import { memo,useState, forwardRef, useImperativeHandle, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import {IKeyValuePair} from '../interfaces/IGeneric'
export interface PageAlertRefObject {
    Append: (new_item:string, anchor?:string) => void,
    Clear: () => void,
    Set: (new_item:string, anchor?:string) => void,
    GetItems: () => void
  }


const PageAlert = forwardRef((props: {alertType: string, alertHeader: string}, ref: Ref<PageAlertRefObject>)=> {
    
    const { t } = useTranslation();
    let type=props.alertType==null?'informational':props.alertType;
    let header=props.alertHeader==null?t('alert'):props.alertHeader;
    const [alertItems, setAlertMessages] = useState<IKeyValuePair[]>([]);
        
    useImperativeHandle(ref, () => ({ Append, Set, Clear, GetItems}));

    function Append(new_item, anchor) { setAlertMessages(values => ([...values, {key:anchor, value:new_item}])); }
    function Clear() { setAlertMessages([]); }
    function Set(new_item, anchor) { setAlertMessages([{key:anchor, value:new_item}]); }
    function GetItems() { return alertItems; }


    return(

        <div aria-live={"assertive"} role="alert" aria-atomic={true}>
            {
                alertItems.length>0?
                <div className={'ontario-alert ontario-alert--'+type}>
                    <div className="ontario-alert__header">
                        <div className="ontario-alert__header-icon">
                            <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href={'#ontario-icon-alert-'+type}></use>
                            </svg>
                        </div>
                        <h2 className="ontario-alert__header-title ontario-h4" role="heading" aria-level={1}>{header}</h2>
                    </div>
                    <div className="ontario-alert__body">
                        {type=='error' && alertItems.length>1?<p>{t('form-validation-error-p')}</p>:<></>}
                        <ul>
                        {alertItems.map((item, index) => {
                            return(
                                item.key!=null && item.key!=undefined && item.key!=''?
                                <li key={index}><a href={"#"+item.key}>{t(item.value)}</a></li>
                                :
                                <li key={index}>{t(item.value)}</li>                            
                            )
                            }
                        )}
                        </ul>                            
                    </div>
                </div>
                :<></>
            }
        </div>



    )
    
    
});




export default (PageAlert)