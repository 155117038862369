import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {Link,useNavigate} from 'react-router-dom';
// @ts-ignore
import ODS_HEADER from '../../components/Header.tsx';
import ODS_FOOTER from '../../components/Footer.js';
import UpdateEmployeeIndex from "../../components/Intranet/UpdateEmployeeIndex.tsx";
import StepIndicator from '../../components/StepIndicator.tsx';
import BreadCrumbs from '../../components/BreadCrumb.tsx'
import {GetPath, formatPageTitle} from "../../js/utils"

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";


const UpdateEmpIndexPage = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    document.title = formatPageTitle(t('title-update-employee'), t);
    document.documentElement.lang=(i18n.language);
    const [currLocale, setCurrLocale] = useState(i18n.language);
        
    //creating function to load ip address from the API
    const getData = async () => {
        //const res = await axios.get('https://geolocation-db.com/json/')
        //console.log(res.data);
        //setIP(res.data.IPv4)
    }

    return (
        <>
        <AuthenticatedTemplate>        
          <div id="layout-header"><ODS_HEADER onLocaleChange={setCurrLocale} /></div>
          <noscript>{t('no-javascript')}</noscript>
          <main id="main-content" style={{"minHeight":"80%"}}>
            <BreadCrumbs>
            </BreadCrumbs>
              <div className="ontario-row">
                <div className="ontario-main-content ontario-small-12 ontario-columns ontario-margin-top-24-!">                
                    <div id="app-main">
                        <StepIndicator href={'home'} title={'title-update-employee'} label={'back-to-home'} hideBackLink={true}></StepIndicator>
                        <div className="top-margin-2">
                            <div className="ontario-callout" >
                                <p style={{maxWidth:'none'}}>
                                Request will be directed to the corresponding <Link to={GetPath('org',i18n.language)+'?id=' +  encodeURIComponent(3385)} >Ministry Lead Coordinator</Link> for processing within 48 hours.
                                </p>
                            </div>
                            <UpdateEmployeeIndex></UpdateEmployeeIndex>
                        </div>
                    </div>
                </div>
              </div>
          </main>
          <div id="layout-footer" className='autoTopMargin'><ODS_FOOTER /></div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate></UnauthenticatedTemplate>
        </>
    );
};
  

    

export default UpdateEmpIndexPage;


