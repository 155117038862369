import React, { ReactNode, useEffect } from 'react';
import { memo} from 'react';
import { Link } from "react-router-dom";
import {isIntranet} from '../js/ui'
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

export interface IBreadCrumb
{
  children: ReactNode;
   
}

const BreadCrumb = (props) =>{
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const location = useLocation();
    var cl = i18n.language;
    var content = React.Children.toArray(props.children); //:IKeyValuePair[]=props.children;
    
    useEffect(() => {
      if(location.pathname.toString().toUpperCase().startsWith('/FR/') && cl!='fr'){
          i18n.changeLanguage('fr');
      }
      else if(!location.pathname.toString().toUpperCase().startsWith('/FR/') && cl!='en'){
          i18n.changeLanguage('en');
      }
      
    },[]);
    

    return (
      <span className='breadcrumb-container noprint'>
        <div className="ontario-row">
          <div className='ontario-column ontario-margin-top-24-!'>
            <nav aria-label={t('breadcrumb')}>
                <ul className='breadcrumbs'>
                  <li>
                    {
                      location.pathname=="" || location.pathname=="/" || location.pathname=="/fr/"?
                      <Link to={cl=='fr'?'/fr/':'/'} onClick={() => window.location.reload()}>{isIntranet()?t('breadcrumb-home-intranet'):t('breadcrumb-home')}</Link>
                      :
                      <Link to={cl=='fr'?'/fr/':'/'}>{isIntranet()?t('breadcrumb-home-intranet'):t('breadcrumb-home')}</Link>
                    }

                  </li>                  
                  {                    
                    React.Children.map(content, (item:ReactNode, index) => {
                      
                      if(React.isValidElement(item) && item.props.children!= null){
                        return(
                          <li key={index}>{item}</li>
                        )
                      }                      
                    }             
                    )
                  }
                </ul>
            </nav>
          </div>
        </div>
      </span>
    );
}


export default memo(BreadCrumb)