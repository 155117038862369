import React, { useState, useContext, createContext, useEffect, useCallback, memo, useRef, forwardRef, Ref, useImperativeHandle } from 'react';
import {useSearchParams} from "react-router-dom";

import { Trans, useTranslation } from 'react-i18next';
import {useLocation, useNavigate, Link} from 'react-router-dom';

// @ts-ignore
import SearchResult from './SearchResult.tsx';
// @ts-ignore
import { SearchEmployee, SearchOrganization} from './APICalls';
// @ts-ignore
import PageAlert, {PageAlertRefObject} from '../PageAlert.tsx';

import {calculateTotalPages, calculateResultPageRange, sanitizeString, decodeHtml, GetCurrentQuery, StringifyQueryObject, browserDetect, oniOS} from '../../js/utils';
import {tablistTabOnClick, tablistTabOnKeyUp, scrollToTop} from '../../js/ui';
// @ts-ignore
import DropdownButton, {DropdownButtonItem} from '../DropdownButton.tsx'
// @ts-ignore
import Pagination from '../Pagination.tsx'

// @ts-ignore
import {ISearchParameters, ISearchFormVM, newISearchParameters} from '../../interfaces/ISearchForm.tsx'
import {IKeyValuePair} from '../../interfaces/IGeneric'


import i18n from '../../i18n/config';



const MemoizedSortOptions = memo(SortOptions);


const SearchParamContext = createContext<ISearchParameters>({} as ISearchParameters);
const SearchFormContext = createContext<ISearchFormVM>({} as ISearchFormVM);

interface IResultObj {
  founditems?:any,
  searchparams?: ISearchParameters,
  loadfinished:boolean
}


export interface SearchFormRefObj {  
  ClickSearchButton: () => void  
}

const SearchForm = forwardRef((props, ref: Ref<SearchFormRefObj>)=> {
  
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  let current_query= GetCurrentQuery();
  
  const searchInput = useRef<HTMLInputElement>(null);
  const searchButton = useRef<HTMLButtonElement>(null);
  const headerRef = useRef<HTMLHeadingElement>(null);  

  let defaultSortLabel_Emp = 'sort-option0';
  let defaultSortLabel_Org = 'sort-option0';
  
  if(current_query.sortlabel==null){
    var curr_searchtype = current_query.searchtype==null?1:current_query.searchtype;
    if(curr_searchtype==2){
      current_query.sortlabel=defaultSortLabel_Org;
    }
    else{
      current_query.sortlabel=defaultSortLabel_Emp;
    }
  }

  const [queryString] = useSearchParams();
    
  //const [keywords, setKeywords] = useState(queryString.get('keywords') != null && queryString.get('keywords') !=''?queryString.get('keywords'):'');
  const [inputs, setInputs] = useState({ 'searchkeyword': queryString.get('keywords') });
  
  const [searchparams, setSearchparams] = useState<ISearchParameters>();
  const [founditems, setFounditems] = useState([]);
  const [searchFormVM, setSearchFormVM] = useState<ISearchFormVM>({'setSearchparams': setSearchparams, 'setFounditems': setFounditems});
  const [filterLink, setFilterLink] = useState('');
  // @ts-ignore
  const [resultObj, setResultObj] = useState<IResultObj>({'founditems':[], 'searchparams':{}, 'loadfinished': false});
  const alert_ref = useRef<PageAlertRefObject>(null);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
  }
  
  const doSearch = async (search_par:ISearchParameters) => {
    scrollToTop();
    //if(searchInput.current) searchInput.current.focus();
    //console.log('handleSubmit');
    if(search_par.searchtype==2/*OrgSearch*/){
      var result = await SearchOrganization(search_par);
      //console.log(result);
      if(result.error_message==null || result.error_message==''){
        if(result.organizations !=null){
          setFounditems(result.organizations);          
        }
        else{
          setFounditems(result);
        }
        //window.location.hash = "#search_results";
      }
      else{
        alert_ref.current?.Set(result.error_message);
        //setAlertMessages(values => ([...values, t(result.error_message)]));
      }    
    }
    else{ /* Default to 1=Employee */
      var result = await SearchEmployee(search_par);//AjaxCall(q);
      
      if(result.error_message==null || result.error_message==''){
        if(result.individuals !=null){
          var sorted = result.individuals;//result.individuals.sort((a, b) => a.firstName.localeCompare(b.firstName));
          //console.log(sorted);
          setFounditems(sorted);
              
        }
        else{
          setFounditems(result);
          
        }
        window.location.hash = "#search_results";
      }
      else{
        alert_ref.current?.Set(result.error_message);
        //setAlertMessages(values => ([...values, t(result.error_message)]));
      }
          
    }
    
  }  

  const handleSubmit = (search_par:ISearchParameters) => {
    var x = StringifyQueryObject(search_par);
    //console.log(x);
    navigate({
      pathname: location.pathname,
      search: x
    });
    doSearch(search_par);
    if(search_par.searchtype == 2) setFilterLink("?" + x.replace('searchtype=2','searchtype=1'));
    else setFilterLink("?" + x.replace('searchtype=1','searchtype=2'));
  }  

  const searchOnClick = (event) => {
    event.preventDefault();
    var raw_keyword = inputs.searchkeyword?.trim();
    var cleaned_keyword = sanitizeString(raw_keyword); 
    
    if(cleaned_keyword == null || cleaned_keyword.length < 2){      
      //Means the keyword prior to trimming contained all special characters
      if(raw_keyword != null && raw_keyword.length >1)
        alert_ref.current?.Set('error-search-keyword-invalid');
      else
        alert_ref.current?.Set('error-search-keyword-blank');
    }
    else{
        
      //setKeywords(temp_keyword);
      // @ts-ignore
      setSearchparams(values => ({...values, 'keywords': cleaned_keyword, 'job': 0, 'page': 1}));      
      alert_ref.current?.Clear();
    }
  }

  const textboxEnter = (event) =>{
    if(event.keyCode === 13){
      searchOnClick(event);
    }
  }

  const filterSelect = (searchtype, event)=>{
    event.preventDefault();
    if(event.target =='button') tablistTabOnClick(event.target);
    if(searchparams!=undefined && searchparams.searchtype != searchtype){
      if(searchtype==2/* Organization Search */){
        setSearchparams(values => ({...values, 'searchtype':searchtype, 'sortcol':'RANK', 'sortlabel': defaultSortLabel_Org, 'page': 1}));
        setFilterLink(window.location.search.replace('searchtype=2','searchtype=1'))
        }
      else{
        setSearchparams(values => ({...values, 'searchtype':searchtype, 'sortcol':'RANK', 'sortlabel': defaultSortLabel_Emp, 'page': 1}));
        setFilterLink(window.location.search.replace('searchtype=1','searchtype=2'))
        }
      }    
  }

  const onReset = (event) =>{
    event.preventDefault();
    setInputs(values =>({...values, 'searchkeyword':''}));
    if(searchInput.current) searchInput.current.focus();
  }

  useEffect(() =>{
    setSearchparams({...newISearchParameters, ...current_query, 'locale':i18n.language });    
    
  }, [])

  useEffect(() => {
    if(searchparams!=undefined)
    {//console.log(searchparams);
      setResultObj(values => ({...values, 'loadfinished':false})); 
      handleSubmit(searchparams);}
  }, [searchparams])
  
  useEffect(() => {
    if(searchparams!=undefined){
    setResultObj({'founditems':founditems, 'searchparams':searchparams, 'loadfinished':true});
    }
    //if(headerRef.current){ headerRef.current.focus(); }  
  }, [founditems])
  
  function ClickSearchButton() { searchButton.current?.click(); }
  useImperativeHandle(ref, () => ({ ClickSearchButton }));
  //function SetKeywordInput(k){ if(searchInput.current){searchInput.current.value=k;} }
  


  const noResultsFoundFilter=(searchtype)=>{
    if(searchtype==2) { // no results found organizations
      let beforeLink = t('no-results-4').indexOf(t('employees'))
      return(
        <>
          {t('no-results-4').slice(0, beforeLink)}
          <Link to={filterLink} title={t('go-to-filter-by-employees')} onClick={(e) => {filterSelect(1,e)}}>
          {t('employees')}
          </Link>
          {t('no-results-4').slice(beforeLink+t('employees').length)}
          </>)
    } else { // no results found employees
      let beforeLink = t('no-results-5').indexOf(t('organizations'))
      return(
        <>
          {t('no-results-5').slice(0, beforeLink)}
            <Link to={filterLink} title={t('go-to-filter-by-organizations')} onClick={(e) => {filterSelect(2,e)}}>
              {t('organizations')}
            </Link>
          {t('no-results-5').slice(beforeLink+t('organizations').length)}
          </>)
    }
  }

  return (
    <>
    <SearchParamContext.Provider value={searchparams!}>
    <SearchFormContext.Provider value={searchFormVM}>
    <section role="search">
      <PageAlert ref={alert_ref} alertType={'error'} alertHeader={t('error')}></PageAlert>      
      <h1 className='ontario-h4 no-max-width' tabIndex={-1} ref={headerRef}>
        <MemoizedPageTitle result={resultObj}></MemoizedPageTitle>       
      </h1>
      <form onSubmit={(e)=>{e.preventDefault();}} style={{textAlign:"center"}} id="searchForm">
        <div className="ontario-columns ontario-medium-12 ontario-padding-left-0-! ontario-padding-right-0-! ontario-margin-bottom-40-!">
          <label htmlFor="ontario-search-input-field" className="ontario-show-for-sr">{t('search')}</label>
          <input 
            type="text" 
            name="searchkeyword" 
            id="ontario-search-input-field"
            autoComplete="off" 
            aria-autocomplete="none" 
            className='ontario-input ontario-search-input info-go'
            style={{maxWidth: "100%"}}
            required={true}    
            aria-required={true}  
            maxLength={100}    
            value={inputs.searchkeyword || ""} 
            onChange={handleChange}
            onKeyDown={textboxEnter}
            ref={searchInput}
            title={t('search-hint')}
            role="searchbox"
          />
          <input className="ontario-search-reset" type="reset" value="" aria-label="Clear field" onClick={onReset} />
          <button ref={searchButton} className="ontario-button--primary ontario-search-submit " type="button" aria-label={t('search')} onClick={searchOnClick}>      
            <span className="search-name" aria-hidden="true">{t('search')}</span>
            <svg className="ontario-icon search-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use href="#ontario-icon-search"></use>
            </svg>           
          </button>  
        </div>
        
        <div>
          <ul className="tab-list" aria-label={t('filter-results')}>
              
              <li className={searchparams==undefined || searchparams.searchtype==null || searchparams.searchtype==1?"tab-list-tab is-active":"tab-list-tab"} >
                  <button title={t('filter-by-employees')} data-order="1" role="" onClick={(e) => (filterSelect(1, e))} onKeyDown={(e) => (tablistTabOnKeyUp(e))} type="button">
                    <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                      <use xlinkHref="/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-filter"></use> 
                    </svg>
                    {t('employees')}
                  </button>
              </li>
              
              <li className={searchparams!=undefined && searchparams.searchtype==2?"tab-list-tab is-active":"tab-list-tab"} >
                  <button title={t('filter-by-organizations')} data-order="2" onClick={(e) => (filterSelect(2, e))} onKeyDown={(e) => (tablistTabOnKeyUp(e))} type="button">
                    <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                      <use xlinkHref="/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-filter"></use> 
                    </svg>
                    {t('organizations')}
                  </button>
              </li>

          </ul>
        </div>

        

      </form>   
    </section>   
    
      <section aria-live={"polite"} aria-atomic={true}>
          
        { 
          resultObj.loadfinished==true?
            founditems.length > 0 ? 
            <ShowSearchResults result={resultObj} /> :
            <div className='ontario-row ontario-margin-top-24-! ontario-margin-bottom-24-!'>
              <div className='ontario-column ontario-medium-12'>
                <span className='ontario-show-for-sr' >
                  <MemoizedPageTitle result={resultObj}></MemoizedPageTitle>
                </span>
                <span style={ { fontWeight: 'bold' } } >
                {t('no-results')}            
                </span>
                <ul>
                  <li>{searchparams!.searchtype==2? t('no-results-2'):t('no-results-1')}</li>
                  <li>
                    {noResultsFoundFilter(searchparams!.searchtype)}
                  </li>
                  <li>{t('no-results-3')}</li>
                </ul>
              </div>
            </div>
          :
          <></>
        }               
      </section>      
    </SearchFormContext.Provider>
    </SearchParamContext.Provider>
    
    </>
  )

});

const ShowSearchResults = ({ result }) =>
{   
    //console.log(result);
    let currentSortLabel = result.searchparams.sortlabel;
    const currSortLabelCallback = useCallback(() => {
      return currentSortLabel;
    }, [currentSortLabel]);

    let resultcount_for_this_page = result.founditems.length; //Items returned for this page
    
    let current_query= GetCurrentQuery();
    let zcurrent_query = StringifyQueryObject(current_query);
    let b = btoa(zcurrent_query);
    if(resultcount_for_this_page >0){
      var resultcount = result.founditems[0].totalRecords; //Total Item returned for all pages
      var totalPages = calculateTotalPages(resultcount);
      var item_range = calculateResultPageRange(result.searchparams.page, resultcount);
      var startcount = item_range[0], endcount=item_range[1];
      return (
        <div className='ontario-row ontario-margin-top-24-! ontario-margin-bottom-24-!'>
          <div className='ontario-column ontario-medium-12'>
            <div className='ontario-row'>
              <div className='ontario-column ontario-small-6'>
                <span style={{ fontWeight: 'bold', display:"none"}}>
                  <Trans i18nKey='search-results-found-1'>
                    {{resultcount}}{{startcount}}{{endcount}}
                  </Trans>
                </span> 
                <span className='ontario-show-for-sr' aria-live='polite' aria-atomic="true">
                  <MemoizedPageTitle result={result}></MemoizedPageTitle>
                </span>                
              </div>
              <div className='ontario-column ontario-small-6'>
                {
                  oniOS() || browserDetect()=='safari'
                  ?
                  <GhettoSortOptions sortlabel={currSortLabelCallback}></GhettoSortOptions>
                  :
                  <MemoizedSortOptions sortlabel={currSortLabelCallback}></MemoizedSortOptions>   
                }                             
              </div>
            </div>
              
              <SearchResult founditems={result.founditems} keywords={result.searchparams.keywords} emp_link_path={'emp'} org_link_path={'org'} use_current_query_in_links={true}></SearchResult>
            <div className='ontario-row'>
              <div className='ontario-column ontario-small-12'>
                <SearchPagination currentpage={result.searchparams.page} totalpages={totalPages}></SearchPagination>
              </div>
            </div> 
          </div>

        </div>
      );
    }
    else{
      return(
      <>
        <span className='ontario-show-for-sr' aria-live='assertive' aria-atomic="true">
          <MemoizedPageTitle result={result}></MemoizedPageTitle>
        </span>   
      </>)
    }
    
}

const MemoizedSearchResults = memo(ShowSearchResults);


function SortOptions(props){
  const { t } = useTranslation();
  const vm = useContext(SearchFormContext); 
  const searchparams = useContext(SearchParamContext); 
  
  var sortoptions  : IKeyValuePair[] = [];
  if(searchparams.searchtype==2)
  {
    sortoptions.push({key : "RANK_ASC", value : 'sort-option0'});
    sortoptions.push({key : "ORGNAME_ASC", value : 'sort-option5'});
    sortoptions.push({key : "ORGNAME_DESC", value : 'sort-option6'});
  }
  else{
    {
      sortoptions.push({key : "RANK_ASC", value : 'sort-option0'});
      sortoptions.push({key : "FIRSTNAME_ASC", value : 'sort-option1'});
      sortoptions.push({key : "FIRSTNAME_DESC", value : 'sort-option2'});
      sortoptions.push({key : "LASTNAME_ASC", value : 'sort-option3'});
      sortoptions.push({key : "LASTNAME_DESC", value : 'sort-option4'});
      sortoptions.push({key : "JOBTITLE_ASC", value : 'sort-option7'});
      sortoptions.push({key : "JOBTITLE_DESC", value : 'sort-option8'});
    }
  }
  //console.log(sortoptions);
  const sortSelect = async (return_key, return_value, e) => {    
    var s = return_key.split('_');
    var sortCol = s[0];
    var sortDir = s[1];
    vm.setSearchparams(values => ({...values, 'sortcol': sortCol, 'sortdir': sortDir, 'sortlabel': return_value, 'page': 1}));
  }
  //console.log('sort render');
  return(
      <DropdownButton title={t('sort-dropdown') + ': ' + t(searchparams!.sortlabel!=undefined?searchparams.sortlabel:'')} className='sort' id='search-sort' >
        {
          sortoptions.map((item, index)=>{
            return(
              <DropdownButtonItem itemkey={item.key} itemvalue={item.value} key={index} onClickFunction={sortSelect}>{t(item.value)}</DropdownButtonItem>
            )
          })
        }
      </DropdownButton>      
    )
}




/*
function SortOptions(props){
    const { t } = useTranslation();
    const vm = useContext(SearchFormContext); 
    const searchparams = useContext(SearchParamContext); 
    const dropdownBtnClick = (e) => {      
      toggleDropdownContent(e);
    }
  
    const sortSelect = async (sortCol, sortDir, e) => {
      e.preventDefault();
      vm.setSearchparams(values => ({...values, 'sortcol': sortCol, 'sortdir': sortDir, 'sortlabel': e.target.text, 'page': 1}));    
      toggleDropdownContent(e);
    }
    //console.log('sort render');
    return(
      
        <div className="dropdown sort" onBlur={(e) => (onBlurDropdownContent(e))} >
          <button className="dropdown-button" id="search-sort-toggler-btn" aria-controls="search-sort-toggler-dropdown" aria-label="open sort menu" type="button" onClick={(e) => (dropdownBtnClick(e))} aria-haspopup="true"> 
            <svg className="ontario-icon dropdown-button-icon-open" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use xlinkHref="#ontario-icon-chevron-down"></use> 
            </svg>
            <svg className="ontario-icon dropdown-button-icon-close" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use xlinkHref="#ontario-icon-chevron-up"></use> 
            </svg> 
            <span>{t('sort-dropdown')}: </span><span>{props.sortlabel()}</span>
          </button>
          <div id="search-sort-toggler-dropdown" className="dropdown-content" aria-labelledby='search-sort-toggler-btn'>
          {
            searchparams.searchtype==2?
            <ul>
              <li><a role="menuitem" onClick={(e) => (sortSelect('RANK', 'ASC', e))} href="/#">{t('sort-option0')}</a></li>
              <li><a role="menuitem" onClick={(e) => (sortSelect('ORGNAME', 'ASC', e))} href="/#">{t('sort-option5')}</a></li>
              <li><a role="menuitem" onClick={(e) => (sortSelect('ORGNAME', 'DESC', e))} href="/#">{t('sort-option6')}</a></li>
            </ul>:
            <ul>
              <li><a role="menuitem" onClick={(e) => (sortSelect('RANK', 'ASC', e))} href="/#">{t('sort-option0')}</a></li>
              <li><a role="menuitem" onClick={(e) => (sortSelect('FIRSTNAME', 'ASC', e))} href="/#">{t('sort-option1')}</a></li>
              <li><a role="menuitem" onClick={(e) => (sortSelect('FIRSTNAME', 'DESC', e))} href="/#">{t('sort-option2')}</a></li>
              <li><a role="menuitem" onClick={(e) => (sortSelect('LASTNAME', 'ASC', e))} href="/#">{t('sort-option3')}</a></li>
              <li><a role="menuitem" onClick={(e) => (sortSelect('LASTNAME', 'DESC', e))} href="/#">{t('sort-option4')}</a></li>
              <li><a role="menuitem" onClick={(e) => (sortSelect('JOBTITLE', 'ASC', e))} href="/#">{t('sort-option7')}</a></li>
              <li><a role="menuitem" onClick={(e) => (sortSelect('JOBTITLE', 'DESC', e))} href="/#">{t('sort-option8')}</a></li>
            </ul>
          }
          </div>
        </div>
      )
}
*/

const SearchPagination = ({currentpage, totalpages}) => {
    
  const vm = useContext(SearchFormContext);   

  const pageSelect = async (pageId, event) => {
    event.preventDefault();
    vm.setSearchparams(values => ({...values, 'page': pageId}));    
    //window.scrollToTop();    
  }

  return(

    <div>
       <Pagination currentpage={currentpage} totalpages={totalpages} onClickFunction={pageSelect}></Pagination>
    </div>

  )
}

function PageTitle({result}){
  const { t } = useTranslation();
  //const searchparams = useContext(SearchParamContext);
  var resultcount_for_this_page = result.founditems.length; //Items returned for this page
  var resultcount = 0;
  var startcount = 0, endcount=0;
  if(resultcount_for_this_page>0){
    resultcount=result.founditems[0].totalRecords; //Total Item returned for all pages
    var item_range = calculateResultPageRange(result.searchparams.page, resultcount);
    startcount = item_range[0]; 
    endcount=item_range[1];  
  }
  if(result.loadfinished==true){
    var keywords = decodeHtml(result.searchparams.keywords);
    var searchtype = "";
    var curr_searchtype = result.searchparams.searchtype==null?1:result.searchparams.searchtype;
      if(curr_searchtype==2){
        searchtype = t('organization');
      }
      else{
        searchtype = t('employee');
      }
      searchtype=searchtype.toLocaleLowerCase();
    //console.log(keywords);
    if(resultcount_for_this_page>0){
      return(
        <Trans i18nKey='search-results-found-2' shouldUnescape={true} >
          {{startcount}}{{endcount}}{{resultcount}}{{searchtype}}{{keywords}}   
        </Trans>
      )
    } else{
      return(
        <Trans i18nKey='search-results-found-no-result' shouldUnescape={true}>
          {{startcount}}{{searchtype}}{{keywords}}   
        </Trans>
      )
    }
  }
  else{
    return (<></>)
  }
}

const MemoizedPageTitle = memo(PageTitle);

///***************************** For Ghetto Apple Products, the Dropdown Button doesnt work for somereason on Apple/iOS (chrome+safari) **************************** */
function GhettoSortOptions(props){
  const { t } = useTranslation();
  const vm = useContext(SearchFormContext); 
  const searchparams = useContext(SearchParamContext); 

  function toggleDropdownContent(e) {
    e.stopPropagation();
    var container = e.target.closest('.dropdown');
        
    var closeDropdownContent = function(){
      container.classList.remove("open");
    }
  
    var openDropdownContent = function(){
      container.classList.add("open");
    }
  
    if(container.classList.contains('open')){
        closeDropdownContent();
    }
    else{
        openDropdownContent();
    }   
  
  }
  function onBlurDropdownContent(e){
    e.stopPropagation();
    if (!e.currentTarget.contains(e.relatedTarget)) {
        e.target.closest('.dropdown').classList.remove("open"); 
    }       
  }

  const dropdownBtnClick = (e) => {      
    toggleDropdownContent(e);
  }

  const sortSelect = async (sortCol, sortDir, label, e) => {
    e.preventDefault();
    vm.setSearchparams(values => ({...values, 'sortcol': sortCol, 'sortdir': sortDir, 'sortlabel': label, 'page': 1}));    
    toggleDropdownContent(e);
  }

  

  //console.log('sort render');
  return(
    
      <div className="dropdown sort" onBlur={(e) => (onBlurDropdownContent(e))} >
        <button className="dropdown-button" id="search-sort-toggler-btn" aria-controls="navigation" aria-label="open sort menu" type="button" onClick={(e) => (dropdownBtnClick(e))} > 
          <svg className="ontario-icon dropdown-button-icon-open" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
            <use xlinkHref="#ontario-icon-chevron-down"></use> 
          </svg>
          <svg className="ontario-icon dropdown-button-icon-close" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
            <use xlinkHref="#ontario-icon-chevron-up"></use> 
          </svg> 
          <span>{t('sort-dropdown')}: </span><span>{t(props.sortlabel())}</span>
        </button>
        <div id="search-sort-toggler-dropdown" className="dropdown-content">
        {
          searchparams.searchtype==2?
          <ul role="menu">
            <li role="presentation"><a role="menuitem" onClick={(e) => (sortSelect('RANK', 'ASC', 'sort-option0', e))} href="/#">{t('sort-option0')}</a></li>
            <li role="presentation"><a role="menuitem" onClick={(e) => (sortSelect('ORGNAME', 'ASC', 'sort-option5', e))} href="/#">{t('sort-option5')}</a></li>
            <li role="presentation"><a role="menuitem" onClick={(e) => (sortSelect('ORGNAME', 'DESC', 'sort-option6', e))} href="/#">{t('sort-option6')}</a></li>
          </ul>:
          <ul role="menu">
            <li role="presentation"><a role="menuitem" onClick={(e) => (sortSelect('RANK', 'ASC', 'sort-option0', e))} href="/#">{t('sort-option0')}</a></li>
            <li role="presentation"><a role="menuitem" onClick={(e) => (sortSelect('FIRSTNAME', 'ASC', 'sort-option1', e))} href="/#">{t('sort-option1')}</a></li>
            <li role="presentation"><a role="menuitem" onClick={(e) => (sortSelect('FIRSTNAME', 'DESC', 'sort-option2', e))} href="/#">{t('sort-option2')}</a></li>
            <li role="presentation"><a role="menuitem" onClick={(e) => (sortSelect('LASTNAME', 'ASC', 'sort-option3', e))} href="/#">{t('sort-option3')}</a></li>
            <li role="presentation"><a role="menuitem" onClick={(e) => (sortSelect('LASTNAME', 'DESC', 'sort-option4', e))} href="/#">{t('sort-option4')}</a></li>
            <li role="presentation"><a role="menuitem" onClick={(e) => (sortSelect('JOBTITLE', 'ASC', 'sort-option7', e))} href="/#">{t('sort-option7')}</a></li>
            <li role="presentation"><a role="menuitem" onClick={(e) => (sortSelect('JOBTITLE', 'DESC', 'sort-option8', e))} href="/#">{t('sort-option8')}</a></li>
          </ul>
        }
        </div>
      </div>
    )
}


export default (SearchForm)