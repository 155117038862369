function getAddressDisplay(address, city, province, postalcode, country, buildingname){
    let z='';
    //if(address != null && address.trim().length>0){
        let a1=address!=null && address.trim().length>0?address.trim():'';//address.trim();
        let c = city!=null && city.trim().length>0?", "+city.trim():'';
        let p = province!=null && province.trim().length>0?", "+province.trim():''; 
        postalcode = postalcode!=null && postalcode.trim().length>0?postalcode.trim().replaceAll(' ', ''):'';
        if(postalcode.length>=6){
            let temp = postalcode.slice(0, 3) + " " + postalcode.slice(3);
            postalcode=temp;
        }
        let pc = postalcode!=null && postalcode.trim().length>0?", "+postalcode.trim():'';
        let co = country!=null && country.trim().length>0?", "+country.trim():'';
        let b = buildingname!=null && buildingname.trim().length>0?buildingname.trim()+", ":'';
        z= b+a1+c+p+pc+co;
        //console.log(src);
    //}
    return z;
}

function getAddressQuery(address, city, province, postalcode, country){
    let q='';
    //if(address != null && address.trim().length>0){
        let a1=address!=null && address.trim().length>0?address.trim().replaceAll(' ', '+'):'';//address.trim().replaceAll(' ', '+');
        let c = city!=null && city.trim().length>0?",+"+city.trim().replaceAll(' ', '+'):'';
        let p = province!=null && province.trim().length>0?",+"+province.trim().replaceAll(' ', '+'):''; 
        let pc = postalcode!=null && postalcode.trim().length>0?",+"+postalcode.trim().replaceAll(' ', '+'):'';
        let co = country!=null && country.trim().length>0?",+"+country.trim().replaceAll(' ', '+'):'';        
        q= a1+c+p+pc+co;
        //console.log(src);        
    //}
    return q;
}

function getGoogleMapsLink(address, city, province, postalcode, country){
    let map_url = process.env.REACT_APP_GOOGLEMAPSURL;
    let q = getAddressQuery(address, city, province, postalcode, country);
    if(q.length>0){
        return map_url+'/?q='+q;
    }
    else{
        return '';
    }
}

function getGoogleMapsEmbeddedLink(address, city, province, postalcode, country){
    let url = getGoogleMapsLink(address, city, province, postalcode, country);
    let embedQuery = "&t=&z=13&ie=UTF8&iwloc=&output=embed";
    
    if(url.length>0){
        return url+embedQuery;
    }
    else{
        return '';
    }
}


export { getAddressDisplay, getGoogleMapsLink, getGoogleMapsEmbeddedLink };