import { useState } from 'react';
import React from 'react';
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { useLocation, useNavigate } from "react-router"
import Home from './pages/Home';
import EmpPage from './pages/EmpPage'
import OrgPage from './pages/OrgPage'
import Help from './pages/Help';
// @ts-ignore
import Redirect from './Redirect.tsx'
import UpdateEmpIndexPage from './pages/Intranet/UpdateEmpIndexPage'
import UpdateEmpResultsPage from './pages/Intranet/UpdateEmpResultsPage'
// @ts-ignore
import AddEmpPage from './pages/Intranet/AddEmpPage.tsx'
// @ts-ignore
import EditEmpPage from './pages/Intranet/EditEmpPage.tsx'
// @ts-ignore
import DeleteEmpPage from './pages/Intranet/DeleteEmpPage.tsx'
// @ts-ignore
import MoveEmpPage from './pages/Intranet/MoveEmpPage.tsx'
import ConfirmationPage from './pages/Intranet/ConfirmationPage'
import Spinner from './components/Spinner';
// @ts-ignore
import ResultSearch from './pages/Results.tsx'

import { useIsAuthenticated } from "@azure/msal-react";



function App(){
  
  const [loading, setLoading] = useState(true);
  const isAuthenticated = useIsAuthenticated();
  
  var inner_routes;
  

  if(!isAuthenticated){
    inner_routes = (
      <Route>        
          <Route path="index" element={<Home />} />                           
          <Route path="results" element={<ResultSearch />} />
          <Route path="emp" element={<EmpPage />} />
          <Route path="org" element={<OrgPage />} />
          <Route path="help" element={<Help />} />
          <Route path="fr" element={<Navigate to="index" replace />}  />
          <Route path="*" element={<Home />} />
      </Route>
    )  
  }
  else{
    inner_routes = (
      <Route>        
          <Route path="index" element={<Home />} />                           
          <Route path="results" element={<ResultSearch />} />
          <Route path="emp" element={<EmpPage />} />
          <Route path="org" element={<OrgPage />} />
          <Route path="help" element={<Help />} />
          <Route path="fr" element={<Navigate to="index" replace />}  />
          {/*<Route path="*" element={<Navigate to="index" replace />} />     
            <Route path="contact" element={<SendMailPage></SendMailPage>} />*/}   
            <Route path="forms" element={<UpdateEmpIndexPage></UpdateEmpIndexPage>} />
            <Route path="updateempresults" element={<UpdateEmpResultsPage></UpdateEmpResultsPage>} />
            <Route path="addemp" element={<AddEmpPage></AddEmpPage>} />
            <Route path="editemp" element={<EditEmpPage></EditEmpPage>} />
            <Route path="deleteemp" element={<DeleteEmpPage></DeleteEmpPage>} />
            <Route path="moveemp" element={<MoveEmpPage></MoveEmpPage>} />
            <Route path="confirmation" element={<ConfirmationPage></ConfirmationPage>} />
            <Route path="*" element={<Navigate to="index" replace />} />
      </Route>
    )  
  }
  //const base = '/:locale(en|fr)?';
  const location = useLocation();
  const navigate = useNavigate();
  //console.log(location);
  var p = location.pathname;
  var has_double_slash = false;
  while(p.includes("//")){
    has_double_slash=true;
    p=p.replace("//", "/")
  }
  if(has_double_slash){
    navigate({
      pathname: p,
      search: location.search,
      hash: location.hash   
    });
  }

  return(
      <>
        <Spinner></Spinner>        
          
          <Routes>
          
              <Route path="/">
                <Route index element={<Home />} />                       
                {inner_routes}                                       
              </Route>
              
              <Route path="/fr"> 
                {inner_routes}                                        
              </Route>
              
              <Route path="/infogo">
                <Route index element={<Redirect />} /*element={<Navigate to="/index" replace />}*/ />
                <Route path="home.html" element={<Redirect />} />
              </Route>
              
          </Routes>                
      </>
      
  )

}




export { App };
