import React, {ReactNode} from 'react';

interface IHintExpander {
  id: string;
  title: string;
  children: ReactNode;
}
 
const InfogoHintExpander = (props:IHintExpander) =>{    

    /*************** OPEN/CLOSE ***********************/
    const toggleContent = (e) => {
      var container = e.target.closest('.ontario-hint-expander__container')
      var button = container.getElementsByClassName('ontario-hint-expander__button');
      var content = container.getElementsByClassName('ontario-hint-expander__content');
      
      if(!container.classList.contains("ontario-expander--active")) {
        container.classList.add('ontario-expander--active');
        button[0].setAttribute("aria-expanded", "true")
        content[0].classList.add('ontario-expander__content--opened')
        content[0].setAttribute("aria-hidden","false")
      } else {
        container.classList.remove('ontario-expander--active')
        button[0].setAttribute("aria-expanded", "false")
        content[0].classList.remove('ontario-expander__content--opened')
        content[0].setAttribute("aria-hidden","true")
      }
    }
    return(
      <div className="ontario-hint-expander__container ontario-margin-bottom-40-!">
        <button type="button" className="ontario-hint-expander__button" id={"hint-expander-button-id-"+props.id} aria-controls={"hint-expander-content-"+props.id} aria-expanded="false" data-toggle="ontario-collapse" onClick={(e)=>{toggleContent(e);}}>
            <span className="ontario-hint-expander__button-icon--close"><svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="#ontario-icon-chevron-up"></use>
                </svg></span>
            <span className="ontario-hint-expander__button-icon--open">
                <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="#ontario-icon-chevron-down"></use>
                </svg>
            </span>
            {props.title}
        </button>
        <div className="ontario-hint-expander__content" id={"hint-expander-content-"+props.id} aria-labelledby={"hint-expander-button-id-"+props.id} aria-hidden="true" data-toggle="ontario-expander-content">
          {props.children}
        </div>
      </div>
    );
}
export default (InfogoHintExpander)