import React from 'react';
import { memo } from 'react';
import { IOrg } from '../../interfaces/IOrganization';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import {ITreeNode, Tree} from '../TreeView.tsx'
import {GetPath, formatOrgDisplayName} from '../../js/utils';

export interface IOrgTree{
  org:any,
  expanded:boolean,
  do_not_show_last_node:boolean,
  allow_link:boolean,
  allow_expand_collapse:boolean,
  querystring?:string
}

const OrgTreeView = (props:IOrgTree) =>{
    var agencies_id = -1000000;
    var org_item:IOrg = props.org;
    const { i18n } = useTranslation();
    var current_locale = i18n.language;
    var has_path = org_item.path!=null && org_item.path.length >0?true:false;
    var show_tree = false; 
    var b = props.querystring!=null && props.querystring.length>0?"&"+props.querystring:"";
    var _path = GetPath('org', i18n.language);
    var tree_data:ITreeNode[] = [];
    var current_node:ITreeNode;
    //create tree
    if(org_item!=null && org_item.orgName!=null && org_item.orgName.length>0){
      show_tree=true;
      org_item.path?.forEach(org => {
        var orgid = 0;
        if(org.orgId != null && org.orgId!=0){
          orgid=org.orgId;
        }
        else if(props.org.id != null && props.org.id!=0){
          orgid=props.org.id;
        }
        else if(props.org._id != null && props.org._id!=0){
          orgid=props.org._id;
        }
        var fullOrgName = formatOrgDisplayName(org.orgPrefix, org.orgName);
        var fullOrgNameFr = formatOrgDisplayName(org.orgPrefixFr, org.orgNameFr);
        var allow_link_loc = props.allow_link && orgid>agencies_id;
        var node:ITreeNode = {key: orgid.toString(), title: current_locale=='fr'?fullOrgNameFr:fullOrgName, children: [], expandedByDefault:props.expanded!=null && props.expanded==true?true:false, url:allow_link_loc?_path+'?id='+orgid.toString()+b:'', collapsable:props.allow_expand_collapse!=null && props.allow_expand_collapse==true?true:false }
        //console.log(node);
        if(tree_data.length<1){
          node={...node, isRoot:true}
          tree_data.push(node);
          current_node=tree_data[0];
        }
        else{
          current_node.children.push(node);
          current_node=current_node.children[0];
        }    
      });
      if(props.do_not_show_last_node==null || !props.do_not_show_last_node){
        var last_node_orgid = 0;
        if(props.org.orgId != null && props.org.orgId!=0){
          last_node_orgid=props.org.orgId;
        }
        else if(props.org.id != null && props.org.id!=0){
          last_node_orgid=props.org.id;
        }
        else if(props.org._id != null && props.org._id!=0){
          last_node_orgid=props.org._id;
        }
        var fullOrgName = formatOrgDisplayName(org_item.orgPrefix, org_item.orgName);
        var fullOrgNameFr = formatOrgDisplayName(org_item.orgPrefixFr, org_item.orgNameFr);
        var allow_link_loc = props.allow_link && last_node_orgid>agencies_id;
        var last_node:ITreeNode = {key: last_node_orgid.toString(), title: current_locale=='fr'?fullOrgNameFr:fullOrgName, children: [], expandedByDefault:props.expanded!=null && props.expanded==true?true:false, url:allow_link_loc?_path+'?id='+last_node_orgid.toString()+b:'', collapsable:props.allow_expand_collapse!=null && props.allow_expand_collapse==true?true:false }
        if(has_path){      
          current_node!.children.push(last_node);
        }
        else{
          //console.log("lastnode")
          last_node={...last_node, isRoot:true}
          tree_data.push(last_node);
        }
      }
    }
    
    //console.log(tree_data);
    if(show_tree){
      return(      
        <Tree treeData={tree_data} />      
      )
    }
    else{
      return(<></>)
    }
  }

  export default memo(OrgTreeView)