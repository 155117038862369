import { memo } from 'react';
import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';


import {getGoogleMapsLink, getAddressDisplay} from '../../js/address_helper';

import {BoldedText, BoldedTextForPhone, BoldedTextForAddress} from '../BoldedText'
import {formatDisplayName, formatOrgDisplayName, GetCurrentQuery, StringifyQueryObject, GetPath} from '../../js/utils';

// @ts-ignore
import {IIndividual, IIndividual_Assignment} from '../../interfaces/IIndividual'
import {IOrganization} from '../../interfaces/IOrganization'

interface ISearchResult{

  founditems:any[],
  keywords?: string,
  emp_link_path?:string,
  org_link_path?:string,
  use_current_query_in_links:boolean,
  disable_secondary_links?:boolean
}

const SearchResult = (props:ISearchResult) =>
{   
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    //console.log(org_link_path);
    let current_query= GetCurrentQuery();
    let zcurrent_query = StringifyQueryObject(current_query);
    let b = btoa(zcurrent_query);

    var keywords = props.keywords;
    var emp_link_path=props.emp_link_path;
    var org_link_path=props.org_link_path;
    var use_current_query_in_links = props.use_current_query_in_links;
    var disable_secondary_links = props.disable_secondary_links;

    var emp_has_link = emp_link_path!=null && emp_link_path.length>0?true:false;
    var org_has_link = org_link_path!=null && org_link_path.length>0?true:false;
    if(use_current_query_in_links==null || !use_current_query_in_links){
      b=''
    }
//console.log(founditems);
      return (
            <>  
              {props.founditems.map((founditem, index) => 
                { 
                  var searchType = founditem.individualId!==undefined?1:2;
                  //Employee Item
                  if(searchType==1){                    
                    var emp_item:IIndividual=founditem;
                    var isVacant = founditem.individualId<0?true:false;
                    var displayName = !isVacant?formatDisplayName(emp_item.firstName, emp_item.lastName, emp_item.middleName, i18n.language=='fr'?emp_item.honorificsFr:emp_item.honorifics):t('vacant');
                    var default_assignmentId=emp_item.assignments[0].assignmentId;
                    var _path = GetPath(emp_link_path, i18n.language);
                    var _pathOrg = GetPath(org_link_path, i18n.language);
                    return(
                      <div key={emp_item.individualId.toString()+"-"+default_assignmentId.toString()} id={"resultitem_"+index.toString()} className='search-item-result-card'>
                        <h2 className='search-result-item-header'>
                        {
                          emp_has_link?
                          <Link className='header no-deco' to={_path+'?id=' +  encodeURIComponent(default_assignmentId) + '&_b='+b} >
                            {
                              keywords!=''?
                              <BoldedText text={displayName} shouldBeBold={keywords}></BoldedText>
                              :
                              <strong>{displayName}</strong>
                            }
                          </Link>
                          :
                          <span>
                            {
                              keywords!=''?
                              <BoldedText text={displayName} shouldBeBold={keywords}></BoldedText>
                              :
                              <strong>{displayName}</strong>
                            }
                          </span>
                        }                        
                        </h2>
                        {emp_item.assignments.map((ass:IIndividual_Assignment, index2)=>
                            {
                              if(i18n.language=='fr'){
                                
                                return(
                                  <ul className='ul-container' key={index2}>
                                    <li><span></span>
                                    {
                                      /*emp_has_link && !disable_secondary_links?
                                      <Link className='ontario-margin-right-4-!' to={GetLocalePath(i18n.language)+emp_link_path+'?id=' +  encodeURIComponent(ass.assignmentId) + '&_b='+b} >
                                        <BoldedText text={ass.positionTitleFr} shouldBeBold={keywords}></BoldedText>
                                      </Link>
                                      :*/
                                      <span className='ontario-margin-right-4-!'>
                                        <BoldedText text={ass.positionTitleFr} shouldBeBold={keywords}></BoldedText>
                                      </span>
                                    }
                                    
                                      {
                                        ass.assignmentNameFr!=null && ass.assignmentNameFr.length>0?"("+ass.assignmentNameFr+")":""
                                      }
                                    </li>
                                    <li><span></span>
                                      {
                                          !disable_secondary_links&&org_has_link?
                                          <Link className='ontario-margin-right-4-!' to={_pathOrg+'?id=' +  encodeURIComponent(ass.orgId) + '&_b='+b} >
                                            {ass.orgNameFr}
                                          </Link>
                                          :
                                          <span className='ontario-margin-right-4-!'>
                                            {ass.orgNameFr}
                                          </span>
                                      }
                                      
                                      {"(" + ass.topOrgNameFr + ")"}
                                    </li>
                                    <li>
                                      <ul className='ul-container-inline'>                                      
                                      {
                                        ass.displayPhoneFr!=null && ass.displayPhoneFr.length>0?
                                        <li><span></span>
                                            <a className="phoneLink" href={"tel:" + ass.displayPhone}><BoldedTextForPhone text={ass.displayPhoneFr} shouldBeBold={keywords}></BoldedTextForPhone></a>                                      
                                        </li>
                                        :<></>
                                      }
                                      {
                                        ass.displayEmail!=null && ass.displayEmail.length>0?
                                        <li><span></span><a href={"mailto: " + ass.displayEmail} className="email">
                                            <BoldedText text={ass.displayEmail} shouldBeBold={keywords}></BoldedText>
                                          </a>
                                        </li>
                                        :<></>
                                      }                                                   
                                      </ul>
                                    </li>
                                  </ul>
                                )

                              }
                              else{

                                return(
                                  <ul className='ul-container' key={index2}>
                                    <li><span></span>
                                    {
                                      /*emp_has_link && !disable_secondary_links?
                                      <Link className='ontario-margin-right-4-!' to={GetLocalePath(i18n.language)+emp_link_path+'?id=' +  encodeURIComponent(ass.assignmentId) + '&_b='+b} >
                                        <BoldedText text={ass.positionTitle} shouldBeBold={keywords}></BoldedText>
                                      </Link>
                                      :*/
                                      <span className='ontario-margin-right-4-!'>
                                        <BoldedText text={ass.positionTitle} shouldBeBold={keywords}></BoldedText>
                                      </span>
                                    }
                                    
                                      {
                                        ass.assignmentName!=null && ass.assignmentName.length>0?"("+ass.assignmentName+")":""
                                      }
                                    </li>
                                    <li><span></span>
                                      {
                                          !disable_secondary_links?
                                          <Link className='ontario-margin-right-4-!' to={_pathOrg+'?id=' +  encodeURIComponent(ass.orgId) + '&_b='+b} >
                                            {ass.orgName}
                                          </Link>
                                          :
                                          <span className='ontario-margin-right-4-!'>{ass.orgName}</span>
                                      }
                                      {"(" + ass.topOrgName + ")"}
                                    </li>
                                    <li>
                                      <ul className='ul-container-inline'>
                                    {
                                      ass.displayPhone!=null && ass.displayPhone.length>0?
                                      <li><span></span><a className="phoneLink" href={"tel:" + ass.displayPhone}>
                                          <BoldedTextForPhone text={ass.displayPhone} shouldBeBold={keywords}></BoldedTextForPhone>  
                                          </a>                                      
                                      </li>
                                      :<></>
                                    }
                                    {
                                      ass.displayEmail!=null && ass.displayEmail.length>0?
                                      <li><span></span><a href={"mailto: " + ass.displayEmail} className="email">
                                          <BoldedText text={ass.displayEmail} shouldBeBold={keywords}></BoldedText>
                                        </a>
                                      </li>
                                      :<></>
                                    }                                                   
                                    </ul>
                                    </li>
                                  </ul>
                                )

                              }
                            }                       
                          )
                        }                       
                      
                      </div>)
                  }
                  //Organization Item
                  else if(searchType==2){
                    let has_address = false;
                    let address_url = "";
                    let address_display = "";
                    var org_item:IOrganization=founditem;
                    var fullOrgName = formatOrgDisplayName(org_item.orgPrefix, org_item.orgName);
                    var fullOrgNameFr = formatOrgDisplayName(org_item.orgPrefixFr, org_item.orgNameFr);
                    var _path = GetPath(org_link_path, i18n.language);
                    if(i18n.language=='fr'){

                      if(org_item.displayAddress!=null){
                        address_url=getGoogleMapsLink(org_item.displayAddress.streetFr, org_item.displayAddress.city, null, org_item.displayAddress.postalCode, null);
                        address_display = getAddressDisplay(org_item.displayAddress.streetFr, org_item.displayAddress.city, null, org_item.displayAddress.postalCode, null, org_item.displayAddress.buildingNameFr);
                        has_address=true;
                      }
                      
                      return(
                        <div key={index} id={"resultitem_"+index.toString()} className='search-item-result-card'>
                            <h2 className='search-result-item-header'>
                            {
                              org_has_link?
                              <Link className='header no-deco ontario-margin-right-4-!' to={_path+'?id=' +  encodeURIComponent(org_item.orgId!=null?org_item.orgId:0)  + '&_b='+b} >
                                {
                                  keywords!=''?
                                  <BoldedText text={fullOrgNameFr} shouldBeBold={keywords}></BoldedText>
                                  :
                                  <strong>{fullOrgNameFr}</strong>
                                }                                
                              </Link>
                              :
                              <span className='ontario-margin-right-4-!'>
                                {
                                  keywords!=''?
                                  <BoldedText text={fullOrgNameFr} shouldBeBold={keywords}></BoldedText>
                                  :
                                  <strong>{fullOrgNameFr}</strong>
                                }
                              </span>
                            }
                            <span className='header'>{"(" + org_item.topOrgNameFr + ")"}</span>
                            
                            </h2>
                            
                          
                          <ul className='ul-container'>
                            {org_item.displayPhoneFr!=null && org_item.displayPhoneFr.length>0?
                              <li><span></span><a className="phoneLink" href={"tel:" + org_item.displayPhone}>
                                <BoldedTextForPhone text={org_item.displayPhoneFr} shouldBeBold={keywords}></BoldedTextForPhone>
                                </a>                             
                              </li>
                              :<></>
                            }
                            {has_address?
                              <li><span></span><a href={address_url} target="blank" title={t('open-new-window-google-maps')}>
                                  <span style={{display: "inline-block", marginBottom: "2px", textDecoration: "underline"}}><BoldedTextForAddress text={address_display} shouldBeBold={keywords}></BoldedTextForAddress></span>
                                  <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" xlinkTitle={t('open-new-window')} aria-label={t('open-new-window')}>
                                    <use xlinkHref="#ontario-icon-new-window"></use>
                                  </svg>
                                </a>
                              </li>  
                              :<></>
                            }
                            {
                              org_item.descriptionFr!=null?
                              <li className='description'>
                                <BoldedText text={org_item.descriptionFr} shouldBeBold={keywords}></BoldedText>                              
                              </li>
                              :<></>
                            }                         
                          </ul>
                          
                        </div>)

                    }
                    else{

                      if(org_item.displayAddress!=null){
                        address_url=getGoogleMapsLink(org_item.displayAddress.street, org_item.displayAddress.city, null, org_item.displayAddress.postalCode, null);
                        address_display = getAddressDisplay(org_item.displayAddress.street, org_item.displayAddress.city, null, org_item.displayAddress.postalCode, null, org_item.displayAddress.buildingName);
                        has_address=true;
                      }
                      
                      return(
                        <div key={index} id={"resultitem_"+index.toString()} className='search-item-result-card'>
                            <h2 className='search-result-item-header'>
                            {
                              org_has_link?
                              <Link className='header no-deco ontario-margin-right-4-!' to={_path+'?id=' +  encodeURIComponent(org_item.orgId!=null?org_item.orgId:0)  + '&_b='+b} >
                                {
                                  keywords!=''?
                                  <BoldedText text={fullOrgName} shouldBeBold={keywords}></BoldedText>
                                  :
                                  <strong>{fullOrgName}</strong>
                                }
                              </Link>
                              :
                              <span className='ontario-margin-right-4-!'>
                                {
                                  keywords!=''?
                                  <BoldedText text={fullOrgName} shouldBeBold={keywords}></BoldedText>
                                  :
                                  <strong>{fullOrgName}</strong>
                                }
                              </span>
                            }
                            <span className='header'>{"(" + org_item.topOrgName + ")"}</span>
                            </h2>
                            
                          
                          <ul className='ul-container'>
                            {org_item.displayPhone!=null && org_item.displayPhone.length>0?
                              <li><span></span><a className="phoneLink" href={"tel:" + org_item.displayPhone}>
                                <BoldedTextForPhone text={org_item.displayPhone} shouldBeBold={keywords}></BoldedTextForPhone>                              
                                </a>
                              </li>
                              :<></>
                            }
                            {has_address?
                              <li><span></span><a href={address_url} target="blank" title={t('open-new-window-google-maps')}>
                                  <span style={{display: "inline-block", marginBottom: "2px", textDecoration: "underline"}}><BoldedTextForAddress text={address_display} shouldBeBold={keywords}></BoldedTextForAddress></span>
                                  <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" xlinkTitle={t('open-new-window')} aria-label={t('open-new-window')}>
                                    <use xlinkHref="#ontario-icon-new-window"></use>
                                  </svg>
                                </a>
                              </li>  
                              :<></>
                            }
                            {
                              org_item.description!=null?
                              <li className='description'>
                                <BoldedText text={org_item.description} shouldBeBold={keywords}></BoldedText>                              
                              </li>
                              :<></>
                            }                         
                          </ul>
                          
                        </div>)
                    }
                      
                  }
                  //UNKNOWN
                  else{
                    return(
                      <div key={index}>ERROR</div>
                    )
                  }
                }                
              )}
              
            </>
      );   
    

}



export default memo(SearchResult)