import {IAddress, IEmail, IPhone} from './IGeneric'
import {IOrg, IOrganization, IOrganization2} from './IOrganization'

//JSON return from GetEmployee
export interface IAssignment {
    active: boolean;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    head?:boolean;
    honorific?: string;
    honorificFr?: string;
    individualId?:number;
    individualName?: string;
    individualNameFr?: string;
    positionId: number;
    positionTitle?: string;
    positionTitleFr?: string;
    role: boolean;
    _id?: number;
    createTimestamp?:Date;
    updateTimestamp?:Date;
    
    addresses?: IAddress[];
    emails?:IEmail[];
    phones?:IPhone[];
    assignmentType?: IAssignmentType;
    associatedOrg?: IOrg;
    otherPositionAssignments?:IOtherAssignment[];
    reportsTo?:IOtherAssignment;

    detailedOrg?: IOrganization2;//Custom
    topOrg?:IOrganization;
    //lookups?:any;

}

export const newIAssignment: IAssignment = {
    individualId:0,
    positionId:0,
    active:true,
    role:false
  }

export interface IOtherAssignment {
    active: boolean;
    assignmentId: number;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    head?:boolean;
    
    individualName?: string;
    individualNameFr?: string;
    
    positionTitle?: string;
    positionTitleFr?: string;
    primaryEmail?: string;
    primaryPhone?: string;
    primaryPhoneFr?: string;
    role: boolean;    
    
    assignmentType?: IAssignmentType;
    associatedOrg?: IOrg;
    relationshipType?: IRelationship;
}

interface IAssignmentType{
    code?:string;
    label?:string;
    labelFr?:string;
}

interface IRelationship{
    code: string;
    label: string;
    labelFr:string;
}