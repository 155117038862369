import React from 'react';
import { memo} from 'react';
import { useTranslation } from 'react-i18next';
import { IKeyValuePair } from '../interfaces/IGeneric'

export interface IPagination
{
  currentpage:number;
  totalpages:number;
  onClickFunction:Function;
}
 


const Pagination = (props:IPagination) =>{
  const { t } = useTranslation();
  let currPage = parseInt(props.currentpage.toString());
  let totalPages = parseInt(props.totalpages.toString());
  let startPage = currPage<6?1:currPage-5;
  let lastPage = currPage+4>totalPages?totalPages:currPage+4;
  let pages : IKeyValuePair[] = [];
  //console.log(currPage);
  //console.log(lastPage);
  if(currPage>totalPages){currPage=totalPages;}

  for (let i = startPage; i <= lastPage; i++) {    
    pages.push({key : i.toString(), value : i});
  }

  const pageSelect = async (pageId, event) => {
    props.onClickFunction(pageId, event);        
  }

  return(
    <nav role="navigation" aria-label={t('search-pagination')}>
      <ul className='pagination'>
        {currPage>1?

          <>
          <li key="f">          
            <a href="/#" onClick={(e) => (pageSelect(1, e))} className="link" tabIndex={0} aria-label={t('first-page') + ': ' + t('go-to-first-page')}>
            <span className="material-icons" aria-hidden="true">
              keyboard_double_arrow_left
            </span>
              {t('first-page')}
            </a>
          </li>
          <li key="p">          
            <a href="/#" onClick={(e) => (pageSelect(currPage-1, e))} className="link" tabIndex={0} aria-label={t('go-to-previous-page')}>
              <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use xlinkHref="#ontario-icon-chevron-left"></use> 
              </svg>
              {t('previous')}
            </a>
          </li>
          </>:<></>        
        }
        {pages.map((page, index) => 
                  { 
                    var pagenumber = page.key;
                    return(
                    <li key={index} className={currPage===page.value?'is-active':''}  onClick={(e) => (pageSelect(page.value, e))}><a href="/#" className="link" tabIndex={0} aria-label={t('go-to-page-number') +' ' + pagenumber} aria-current={currPage===page.value?'true':'false'}>{pagenumber}</a></li>)
                  }                
                )
        }
        {lastPage<totalPages?<li><span>...</span></li>:<></>
          
        }
        {currPage<totalPages?
          <>
          <li key="n">
            <a href="/#" onClick={(e) => (pageSelect(currPage+1, e))} className="link" tabIndex={0} aria-label={t('go-to-next-page')}>
              {t('next')}
              <svg className="ontario-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use xlinkHref="#ontario-icon-chevron-right"></use> 
              </svg>
            </a>          
          </li>
          <li key="l">
            <a href="/#" onClick={(e) => (pageSelect(totalPages, e))} className="link" tabIndex={0} aria-label={t('last-page') + ': ' + t('go-to-last-page')}>
              {t('last-page')}
              <span className="material-icons" aria-hidden="true">
                keyboard_double_arrow_right
              </span>
            </a>          
          </li>
          </>:<></>
          
        }     
      </ul>
    </nav>
  )
}


export default memo(Pagination)