import React from 'react';
import { useState, useContext, createContext, memo, useRef} from 'react';
import {useSearchParams} from "react-router-dom";
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Trans, useTranslation } from 'react-i18next';
// @ts-ignore
import OrgTreeView from './OrgTree.tsx'
// @ts-ignore
import AssignmentList from './AssignmentList.tsx';
// @ts-ignore
import OrganizationList from './OrganizationList.tsx'
// @ts-ignore
import IndirectOrganizationList from './CategoryList.tsx'
// @ts-ignore
import CopyButton from '../CopyButton.tsx';
import {getGoogleMapsLink} from '../../js/address_helper';
import {isIntranet} from '../../js/ui';
import {formatDisplayName, formatOrgDisplayName, GetPath, capitalizeOnlyFirstLetter, getDateParts, isValidDate} from '../../js/utils';
import {ISearchItemDetailVM} from '../../interfaces/ISearchForm'
import {IAssignment} from '../../interfaces/IAssignment'
import { IOrganization2 } from '../../interfaces/IOrganization';
import { IAddress, IPhone } from '../../interfaces/IGeneric';


// @ts-ignore


const ItemDetailContext = createContext<ISearchItemDetailVM>({} as ISearchItemDetailVM)

function ShowSearchItemDetail({currentitem}){
    //console.log(props);
    const [searchParams] = useSearchParams();
    let b = searchParams.get('_b');
    
    
    const detailElement = useRef<HTMLDivElement>(null);

    const handlePrint = useReactToPrint({
      content: () => detailElement.current,
    });

    
    const [itemDetailVM, setItemDetailVM] = useState<ISearchItemDetailVM>({'handlePrint': handlePrint, 'b': b!=null && b!=''?('_b='+b):''});
    
    var itemType = currentitem.individualId!==undefined?1:2;/* 1= Employee, 2= Org */

    if(itemType==1){
      var emp_item:IAssignment = currentitem;
      return(
        <ItemDetailContext.Provider value={itemDetailVM}>
          <div ref={detailElement}>
            <EmployeeDetail currentitem={emp_item}></EmployeeDetail>
          </div>
        </ItemDetailContext.Provider>
        )
    }
    else if(itemType==2){
      return(
        <ItemDetailContext.Provider value={itemDetailVM}>
          <div ref={detailElement}>
            <OrganizationDetail currentitem={currentitem}></OrganizationDetail>
          </div>
        </ItemDetailContext.Provider>
        )
    }
    else{
      return(
        <ItemDetailContext.Provider value={itemDetailVM}>
          <div ref={detailElement}>
            <TestDetail currentitem={currentitem}></TestDetail>
          </div>
        </ItemDetailContext.Provider>
        )
    }
      
  }

const EmployeeDetail = (props:{ currentitem:IAssignment }) => {
  const { i18n } = useTranslation();
  var current_locale = i18n.language;
  const { t } = useTranslation();
  const vm = useContext(ItemDetailContext);
  var b = vm.b!=null && vm.b.length>0?"&"+vm.b:"";
  var _path = GetPath('emp', i18n.language);
  

  if(props.currentitem !== undefined && props.currentitem != null){
    
    var item:IAssignment = props.currentitem;
    var orgItem:IOrganization2;
    var isVacant = item.firstName==null || item.firstName==''?true:false;
    var displayName = !isVacant?formatDisplayName(item.firstName, item.lastName, item.middleName, current_locale=='fr'?item.honorificFr:item.honorific):t('vacant');
    var hasStaff =  item.detailedOrg?.positions!=null && item.detailedOrg?.positions.length>1?true:false;
    var hasReportingOrgs = item.detailedOrg?.childOrgs!=null && item.detailedOrg?.childOrgs.length>0?true:false;
    //console.log(item);
    var path_count = item.associatedOrg?.path != null? item.associatedOrg?.path.length:0;
    var margin = path_count>0? (path_count)*(20): 0;
    var margin_style = margin.toString()+"px";
    var updatedateArr = getDateParts(GetLastUpdatedDate(item.createTimestamp, item.updateTimestamp), current_locale);
    var updatedate = updatedateArr[0];
    var updatetime = updatedateArr[1];
    var _shareLink = window.location.protocol + '//' + window.location.host + window.location.pathname + "?id="+item._id?.toString();
    var phone_group = {};
    
    if(item.phones!=undefined)
    {
      phone_group = Phone_JSON_Groupby_Emp(item.phones, current_locale);
    }
    var address_group = {};
    if(item.addresses!=undefined){
      address_group = Address_JSON_Groupby(item.addresses, current_locale);
    }
    return(
      <>
      <nav aria-label={t('organization-hierarchy')}>        
        <OrgTreeView org={item.associatedOrg} expanded={true} querystring={vm.b} allow_link={true} allow_expand_collapse={false} do_not_show_last_node={false}></OrgTreeView>
      </nav>
      <section style={{marginLeft:margin_style}}>
        <span></span>
      <div className="ontario-callout-card" >
        
        <h2 className="ontario-callout__title ontario-h5">{displayName}</h2>
        

        <p>
          {current_locale=='fr'?item.positionTitleFr:item.positionTitle}
          {
            current_locale=='fr'?(item.assignmentType?.labelFr!=null && item.assignmentType?.labelFr.length>0?" ("+item.assignmentType?.labelFr+")":<></>)
            :
            (item.assignmentType?.label!=null && item.assignmentType?.label.length>0?" ("+item.assignmentType?.label+")":<></>)          
          }
        </p>
        
        <dl className="inline-dl">
          {//Phone#
            Object.keys(phone_group).map((key, index)=>
            {
              var phoneLabel = key=="PRIMARY"?t('phone'):key;
                            
              return(
                
                <React.Fragment key={index}>
                  <dt>{phoneLabel!=undefined&&phoneLabel!=''?phoneLabel:t('phone')}</dt>
                  <dd>
                    {
                      phone_group[key].data.map((phone:IPhone, index2)=>{
                        return(
                          <React.Fragment key={index2}>
                            {
                              current_locale=='fr'?
                              <div><a className="phoneLink" href={"tel:" + phone.phoneNumber}>{phone.phoneNumberFr}</a> {phone.descriptionFr!=null && phone.descriptionFr.length>0?(" - " + phone.descriptionFr):""}</div>
                              :
                              <div><a className="phoneLink" href={"tel:" + phone.phoneNumber}>{phone.phoneNumber}</a> {phone.description!=null && phone.description.length>0?(" - " + phone.description):""}</div>
                            }                              
                          </React.Fragment>                          
                        )
                      }
                      )
                    }
                  </dd>
                </React.Fragment>
              
              )
            })
          }          
          
          {//Email
            item.emails!=null && item.emails.length>0?
            <>
              <dt>{t('email')}</dt>
              <dd>
                {
                  item.emails.map((email, index) =>{
                    return(
                      email.primary==true?
                        <div key={'primaryemail'+index} className="email-copy">                          
                            <div>
                              <a href={"mailto: " + email.emailAddress}>
                                {email.emailAddress}
                              </a>
                            </div>
                            <span style={{paddingRight:"32px"}}></span>
                            <div>
                              <CopyButton string_to_copy={email.emailAddress}></CopyButton>
                            </div>                        
                        </div>
                      :<></>                  
                    )
                  }
                  )
                }
                {
                  item.emails.map((email, index_m) =>{
                    return(
                      email.primary==false?
                        <div key={"otheremail"+index_m} className="email-copy">                          
                            <div>
                              <a href={"mailto: " + email.emailAddress}>
                                {email.emailAddress}
                              </a>                            
                            </div>
                            <span style={{paddingRight:"32px"}}></span>
                            <div>
                              <CopyButton string_to_copy={email.emailAddress}></CopyButton>
                            </div>                   
                        </div>
                      :<></>                  
                    )
                  }
                  )
                }
              </dd>
            </>            
            :<></>
          }
          {//Addresses
            Object.keys(address_group).map((key, index)=>
            {
              var addressLabel = t(key);
                            
              return(
                
                <React.Fragment key={index}>
                  <dt>{addressLabel}</dt>
                  <dd>
                    {
                      address_group[key].data.map((p_add:IAddress, index2)=>{
                        let address_url=getGoogleMapsLink(current_locale=='fr'?p_add.addressFr:p_add.address, null, null, null, null);
                        return(
                          <div key={index2}>
                            <a href={address_url} target="blank" title={t('open-new-window-google-maps')}>
                              {current_locale=='fr'?p_add.addressFr:p_add.address}
                              <svg  className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" xlinkTitle={t('open-new-window')} aria-label={t('open-new-window')}>
                                <use xlinkHref="#ontario-icon-new-window"></use>
                              </svg>
                            </a>                              
                          </div>                          
                        )
                      }
                      )
                    }
                  </dd>
                </React.Fragment>
              
              )
            })
          }   
               
        </dl>
        {//Reports To - it was requested by Helene to remove this bec of bad data
            /*
            item.reportsTo!=null && item.reportsTo.active==true?
            <p className="ontario-margin-bottom-0-!">{t('reports-to')} <Link style={{fontWeight:600}} to={_path+'?id=' +  encodeURIComponent(item.reportsTo?.assignmentId) + b} >
            {current_locale=='fr'?item.reportsTo.individualNameFr:item.reportsTo.individualName}
            </Link></p>            
            :<></>
            */
        }
        <p className='ontario-margin-top-24-! no-max-width' style={{textAlign:"left"}}>
          {
            updatedate!=null && updatetime!=null?
            <span className='italic'>
              <Trans i18nKey='last-updated-1' shouldUnescape={true}>
                {{updatedate}}{{updatetime}}   
              </Trans>
            </span>
            
            :<></>
          }
          <span style={{display:"flex", float:"right" }} className="noprint">        
            {
              isIntranet()?
              <a title={t('share')} className="ontario-button ontario-button--tertiary no-min-width no-margin" href={"mailto: " + "?subject="+encodeURIComponent(t('share-email-subject-employee-intranet'))+"&body="+ encodeURIComponent(t('share-email-body-employee-intranet')+ _shareLink)}>            
                <svg className="ontario-icon" aria-hidden="true" focusable="false"  viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                  <use xlinkHref="/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-share"></use>
                </svg>            
              </a>
              :
              <a title={t('share')} className="ontario-button ontario-button--tertiary no-min-width no-margin" href={"mailto: " + "?subject="+encodeURIComponent(t('share-email-subject-employee'))+"&body="+ encodeURIComponent(t('share-email-body-employee')+ _shareLink)}>            
                <svg className="ontario-icon" aria-hidden="true" focusable="false"  viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                  <use xlinkHref="/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-share"></use>
                </svg>            
              </a>        
                    
            
            }
            {
            //Print button - removed bec....?????
            /*
            <button title={t('print')} type="button" onClick={vm.handlePrint} className="ontario-button ontario-button--tertiary no-min-width no-margin" style={{display:"none"}}>
              <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use xlinkHref="/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-print"></use>
              </svg>
            </button>
            */
            }
          </span>     
        </p>
                   
      </div>

      {//Google it was requested by Cristina to remove this bec takes up too much space and no $$$ for google API
      /*
            item.addresses!=null?
            item.addresses.map((p_add, index) =>{
              return(
                p_add.primary==true?
                  <EmbedGoogleMaps width="100%" height="450" address={p_add.street} province={p_add.province} city={p_add.city} postalcode={p_add.postalCode} country={null} key={index}></EmbedGoogleMaps>                  
                :<></>                  
              )
            }
            )
            :<></>*/
      }
      <AssignmentList assignmentItems={item.otherPositionAssignments} showName={false} header_title={t('other-assignments')} exclusion_list={[]} querystring={b}></AssignmentList>
      {
        item.head==true && hasStaff==true?
        <AssignmentList assignmentItems={item.detailedOrg?.positions} showName={true} header_title={t('staff')} exclusion_list={[item._id]} querystring={b}></AssignmentList>
        :<></>
      }
      {
        item.head==true && hasReportingOrgs==true?
        <OrganizationList orgItems={item.detailedOrg?.childOrgs} header_title={t('reporting-orgs')} exclusion_list={[]} querystring={b}></OrganizationList>
        :<></>
      }
      
      </section>
      </>
    )
  }
  else{
    return(<></>)
  }
  
} 



const OrganizationDetail = (props:{ currentitem:IOrganization2 }) => {
  const { i18n } = useTranslation();
  var current_locale = i18n.language;
  const { t } = useTranslation();
  const vm = useContext(ItemDetailContext);
  var b = vm.b!=null && vm.b.length>0?"&"+vm.b:"";
  var _path = GetPath('emp', i18n.language);
  if(props.currentitem !== undefined && props.currentitem != null){
    
    var item:IOrganization2 = props.currentitem;
    //console.log(item);
    var path_count = item.path != null? item.path.length:0;
    var margin = path_count>1? (path_count-1)*(20): 0;
    var margin_style = margin.toString()+"px";
    var updatedateArr = getDateParts(GetLastUpdatedDate(item.createTimestamp, item.updateTimestamp), current_locale);
    var updatedate = updatedateArr[0];
    var updatetime = updatedateArr[1];    
    var fullOrgName = formatOrgDisplayName(item.orgPrefix, item.orgName);
    var fullOrgNameFr = formatOrgDisplayName(item.orgPrefixFr, item.orgNameFr);
    var _shareLink = window.location.protocol + '//' + window.location.host + window.location.pathname + "?id="+item._id?.toString();   
    var phone_group = {};
    if(item.phones!=undefined)
    {
      phone_group = Phone_JSON_Groupby_Org(item.phones, current_locale);
    }
    var address_group = {};
    if(item.addresses!=undefined){
      address_group = Address_JSON_Groupby(item.addresses, current_locale);
    }
    return(
      <>
      <nav aria-label={t('organization-hierarchy')}>        
        <OrgTreeView org={item} expanded={true} querystring={vm.b} do_not_show_last_node={true} allow_link={true} allow_expand_collapse={false}></OrgTreeView>
      </nav>
      <section style={{marginLeft:margin_style}}>
      <div className="ontario-callout-card">
        
        <h2 className="ontario-callout__title ontario-h5">{current_locale=='fr'?fullOrgNameFr:fullOrgName}</h2>
        

        <div className="ontario-margin-bottom-16-!">
          {
            current_locale=='fr'?(item.descriptionFr!=null && item.descriptionFr.length>0?item.descriptionFr:<></>)
            :
            (item.description!=null && item.description.length>0?item.description:<></>)          
          }
        </div>
        <dl className="inline-dl">
          {//Phone#
            Object.keys(phone_group).map((key, index)=>
            {
              var phoneLabel = key;
              if(current_locale=='fr'){
                phoneLabel = phoneLabel+'\u00A0';

              }
              else{                
                if(phoneLabel?.startsWith("TTY")) phoneLabel=phoneLabel.slice(0,3)+phoneLabel.slice(3).toLowerCase();
                else phoneLabel = capitalizeOnlyFirstLetter(phoneLabel);

                if(phoneLabel?.includes("French")) phoneLabel = phoneLabel.replace('french', 'French');
              }
              
              return(
                
                <React.Fragment key={index}>
                  <dt>{phoneLabel!=undefined&&phoneLabel!=''?phoneLabel:t('phone')}</dt>
                  <dd>
                    {
                      phone_group[key].data.map((phone:IPhone, index2)=>{
                        return(
                          <React.Fragment key={index2}>
                            {
                              current_locale=='fr'?
                              <div><a className="phoneLink" href={"tel:" + phone.phoneNumber}>{phone.phoneNumberFr}</a> {phone.descriptionFr!=null && phone.descriptionFr.length>0?(" - " + phone.descriptionFr):""}</div>
                              :
                              <div><a className="phoneLink" href={"tel:" + phone.phoneNumber}>{phone.phoneNumber}</a> {phone.description!=null && phone.description.length>0?(" - " + phone.description):""}</div>
                              
                            }                              
                          </React.Fragment>                          
                        )
                      }
                      )
                    }
                  </dd>
                </React.Fragment>
              
              )
            })
          }
          {//Email
            item.emails!=null && item.emails.length>0?
            <>
              <dt>{t('email')}</dt>
              <dd>
                {
                  item.emails.map((email, index) =>{
                    return(
                      email.primary==true?
                        <div key={'primaryemail'+index} className="email-copy">                          
                            <div>
                              <a href={"mailto: " + email.emailAddress}>
                                {email.emailAddress}
                              </a>
                            </div>
                            <span style={{paddingRight:"32px"}}></span>
                            <div>
                              <CopyButton string_to_copy={email.emailAddress}></CopyButton>
                            </div>                        
                        </div>
                      :<></>                  
                    )
                  }
                  )
                }
                {
                  item.emails.map((email, index_m) =>{
                    return(
                      email.primary==false?
                        <div key={"otheremail"+index_m} className="email-copy">                          
                            <div>
                              <a href={"mailto: " + email.emailAddress}>
                                {email.emailAddress}
                              </a>                            
                            </div>
                            <span style={{paddingRight:"32px"}}></span>
                            <div>
                              <CopyButton string_to_copy={email.emailAddress}></CopyButton>
                            </div>                   
                        </div>
                      :<></>                  
                    )
                  }
                  )
                }
              </dd>
            </>            
            :<></>
          }
          {//Url
            item.urls!=null && item.urls.length>0?
              <>
              <dt>{t('website')}</dt>
              <dd>
                {
                  item.urls.map((url, indexUrl) =>{
                    var my_link = url.url;
                    if(!my_link.startsWith("http")){ my_link="https://"+my_link}
                    return(
                      <div key={indexUrl}>
                        <a href={my_link} target="blank" title={t('open-new-window-google-maps')}>
                          {url.url}
                          <svg  className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" xlinkTitle={t('open-new-window')} aria-label={t('open-new-window')}>
                            <use xlinkHref="#ontario-icon-new-window"></use>
                          </svg>
                        </a>
                      </div>
                    )
                  })
                }
              </dd>  
              </>
            :<></>
          }
          {//Addresses
            Object.keys(address_group).map((key, index)=>
            {
              var addressLabel = t(key);
                            
              return(
                
                <React.Fragment key={index}>
                  <dt>{addressLabel}</dt>
                  <dd>
                    {
                      address_group[key].data.map((p_add:IAddress, index2)=>{
                        let address_url=getGoogleMapsLink(current_locale=='fr'?p_add.addressFr:p_add.address, null, null, null, null);
                        return(
                          <div key={index2}>
                            <a href={address_url} target="blank" title={t('open-new-window-google-maps')}>
                              {current_locale=='fr'?p_add.addressFr:p_add.address}
                              <svg  className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" xlinkTitle={t('open-new-window')} aria-label={t('open-new-window')}>
                                <use xlinkHref="#ontario-icon-new-window"></use>
                              </svg>
                            </a>                              
                          </div>                          
                        )
                      }
                      )
                    }
                  </dd>
                </React.Fragment>
              
              )
            })
          }   
          

          {//Indirect Positions#
            item.indirectPositions!=null && item.indirectPositions.length>0?
            item.indirectPositions.map((pos, indexPos) =>{
              if(current_locale=='fr'){
                return(                
                  <React.Fragment key={'indirectpos'+indexPos}>
                    
                    <dt>{pos.relationshipType?.labelFr!=null?pos.relationshipType?.labelFr+'\u00A0':t('Contact')}</dt>
                    <dd>
                      <Link className='' to={_path+'?id=' +  encodeURIComponent(pos.assignmentId)  + b} >
                        {pos.individualNameFr}
                      </Link>                      
                    </dd>
                  </React.Fragment>                                  
                )  
              }
              else{
                return(                
                  <React.Fragment key={'indirectpos'+indexPos}>
                    
                    <dt>{pos.relationshipType?.label!=null?pos.relationshipType?.label:t('Contact')}</dt>
                    <dd>
                      <Link className='' to={_path+'?id=' +  encodeURIComponent(pos.assignmentId)  + b} >
                        {pos.individualName}
                      </Link>                      
                    </dd>
                  </React.Fragment>                                  
                )  
              }
              
            }
            )
          :<></>
        }
        
        </dl>
        
        <p className='ontario-margin-top-24-! no-max-width' style={{textAlign:"left"}}>
          {
            updatedate!=null && updatetime!=null?
            <span className='italic'>
              <Trans i18nKey='last-updated-1' shouldUnescape={true}>
                {{updatedate}}{{updatetime}}   
              </Trans>
            </span>            
            :<></>
          }
          
          <span style={{float:"right" }} className="noprint">
            {
              isIntranet()?
              <a title={t('share')} className="ontario-button ontario-button--tertiary no-min-width no-margin" href={"mailto: " + "?subject="+encodeURIComponent(t('share-email-subject-org-intranet'))+"&body="+ encodeURIComponent(t('share-email-body-org-intranet')+ _shareLink)}>            
                <svg className="ontario-icon" aria-hidden="true" focusable="false"  viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                  <use xlinkHref="/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-share"></use>
                </svg>                         
              </a>
              :
              <a title={t('share')} className="ontario-button ontario-button--tertiary no-min-width no-margin" href={"mailto: " + "?subject="+encodeURIComponent(t('share-email-subject-org'))+"&body="+ encodeURIComponent(t('share-email-body-org')+ _shareLink)}>            
                <svg className="ontario-icon" aria-hidden="true" focusable="false"  viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                  <use xlinkHref="/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-share"></use>
                </svg>                         
              </a>
            }
            {
              //Print button - removed bec....?????
              /*
            <button title={t('print')} type="button" onClick={vm.handlePrint} className="ontario-button ontario-button--tertiary no-min-width no-margin" style={{display:"none"}}>
              <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use xlinkHref="/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-print"></use>
              </svg>
            </button>*/
            }  
          </span>          
        </p>
        
             
      </div>

      {//Google it was requested by Cristina to be remove this bec takes up too much space and no $$$ for google API
      /*
            item.addresses!=null?
            item.addresses.map((p_add, indexGoogle) =>{
              return(
                p_add.primary==true?
                  <EmbedGoogleMaps width="100%" height="450" address={p_add.street} province={p_add.province} city={p_add.city} postalcode={p_add.postalCode} country={null} key={indexGoogle}></EmbedGoogleMaps>                  
                :<></>                  
              )
            }
            )
            :<></>*/
      }
      <AssignmentList assignmentItems={item.positions} showName={true} header_title={t('employees')} exclusion_list={[]} querystring={b} ></AssignmentList>
      <OrganizationList orgItems={item.childOrgs} header_title={t('reporting-orgs')} exclusion_list={[]} querystring={b}></OrganizationList>
      <IndirectOrganizationList categoryItems={item.indirectOrgs} header_title={t('works-with')} exclusion_list={[]} querystring={b}></IndirectOrganizationList>
      </section>
      </>
    )
  }
  else{
    return(<></>)
  }
  
} 

function GetLastUpdatedDate(createdate, updatedate)
{
  var updated_date = new Date(updatedate);
  var created_date = new Date(createdate);
  if(!isValidDate(updated_date) && !isValidDate(created_date)){
    return null;
  }
  else if(isValidDate(updated_date) && !isValidDate(created_date)){
    return updatedate;
  }
  else if(!isValidDate(updated_date) && isValidDate(created_date)){
    return createdate;
  }
  else{
    if(updated_date>created_date)
      return updatedate;
    else
      return createdate;
  }
}


function TestDetail(props){
  const vm = useContext(ItemDetailContext); 
  return(
    <>
    <h1>{props.currentitem.DisplayName}</h1>
        <ul>
          {
            Object.keys(props.currentitem).map((key, index)=>
            {
              return(
                
                <li key={index}>{key}: {props.currentitem[key]}</li>
              
              )
            })              
          }          
        </ul>        
    </>
  )

} 


function Phone_JSON_Groupby_Org(json_array, locale){
  var groupby_array_obj = {};
  json_array.map((current_item:IPhone)=>{
    var key:string = locale=='fr'?current_item.phoneType.labelFr!:current_item.phoneType.label!;
    if (!groupby_array_obj[key]) { groupby_array_obj[key] = { key, data: [] }; }
    groupby_array_obj[key].data.push(current_item);
    }
  );
  
  return groupby_array_obj;
}

function Phone_JSON_Groupby_Emp(json_array, locale){
  var groupby_array_obj = {};
  json_array.map((current_item:IPhone)=>{
    var key:string = locale=='fr'?current_item.phoneType.labelFr!:current_item.phoneType.label!;
    if(current_item.primary){
      key="PRIMARY";
    }
    if (!groupby_array_obj[key]) { groupby_array_obj[key] = { key, data: [] }; }
    groupby_array_obj[key].data.push(current_item);
    }
  );
  
  return groupby_array_obj;
}

function Address_JSON_Groupby(json_array, locale){
  var groupby_array_obj = {};
  
  json_array.map((current_item:IAddress)=>{
      if(current_item.primary){
        var key = 'primary-address';
        if (!groupby_array_obj[key]) { groupby_array_obj[key] = { key, data: [] }; }
        groupby_array_obj[key].data.push(current_item);
      }
      if(current_item.mailing){
        var key = 'mailing-address';
        if (!groupby_array_obj[key]) { groupby_array_obj[key] = { key, data: [] }; }
        groupby_array_obj[key].data.push(current_item);
      }
      if(!current_item.mailing && !current_item.primary){
        var key = 'other-address';
        if (!groupby_array_obj[key]) { groupby_array_obj[key] = { key, data: [] }; }
        groupby_array_obj[key].data.push(current_item);
      }
    }
  );
  
  return groupby_array_obj;
}


export default memo(ShowSearchItemDetail)