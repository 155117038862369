export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_AZURE_APPCLIENTID,
      authority: process.env.REACT_APP_AZURE_MSALAUTHORITYURL, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: process.env.REACT_APP_AZURE_MSALREDIRECTURL,
      //redirectUri: "https://infogo-ui-dev.azurewebsites.net",
      
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    }
};
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
   scopes: [
    "User.Read"
    // DEV NOTE: can probably remove the Func App API scope after the app registrations get admin consent on API scopes
    //    but for now include it so user consent can be granted on initial login and prompt. 
    ,process.env.REACT_APP_AZURE_FUNCTIONAPI_SCOPE
  ]
};

export const apiRequest = {
  scopes: [process.env.REACT_APP_AZURE_FUNCTIONAPI_SCOPE]
};

export const logoutRequest = {
  postLogoutRedirectUri: process.env.REACT_APP_AZURE_MSALREDIRECTURL
};

  // Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com"
};