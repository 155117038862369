
import React, { useEffect } from 'react'

const confirmationMessage = "You have unsaved changes. Continue?";

const OnUnloadConfirm = ({shouldPrompt}) => {
    useEffect(() => {
        //console.log('useeffect! ' + shouldPrompt);
        //console.log(shouldPrompt);
        const handleBeforeUnload = (event) => {
            //console.log(shouldPrompt);
            if (shouldPrompt) {
                event.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        };

        if(shouldPrompt==true){
            window.addEventListener("beforeunload", handleBeforeUnload);
            return()=>{
                window.removeEventListener("beforeunload", handleBeforeUnload);
            }
        }
        else{
            window.removeEventListener("beforeunload", handleBeforeUnload);
        }     
        
    }, [shouldPrompt]);
    return(
        <></>
    )
};

export default OnUnloadConfirm