import React from "react";
import { useEffect } from 'react';
import {showLoadingSpinner} from '../../js/ui'

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";



/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <button type="button" onClick={() => handleLogin("redirect")}>Sign in using Redirect</button>
    );
}

export const AutoSignInButton = ({loginType}) => {
    const { instance } = useMsal();

    const handleLogin = (z_loginType) => {
        if (z_loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
        else if (z_loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }

    useEffect(() => {
        handleLogin(loginType)      
    });


    return (
        <span>Logging In ....</span>
    );
}

export const SilentLogin = () =>{
    //showLoadingSpinner(true);
    const { instance } = useMsal();
    instance.loginRedirect(loginRequest).catch(e => {
        //showLoadingSpinner(false);
        console.log(e);
    });
    //showLoadingSpinner(false);
}
