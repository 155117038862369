import { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link, useLocation } from 'react-router-dom';
import {GetPath} from '../js/utils'
import { isIntranet } from "../js/ui";
function ODS_FOOTER() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const location = useLocation();
  var cl = i18n.language;
  var help_link = GetPath('help', cl);
  const scrollToTop = () => {
    window.scrollToTop();
  };
  let copyRightYear = new Date().getFullYear().toString().substr(-2);
  /*
  useEffect(() => {
    if(location.pathname.toString().toUpperCase().startsWith('/FR/') && cl!='fr'){
        i18n.changeLanguage('fr');
    }
    else if(!location.pathname.toString().toUpperCase().startsWith('/FR/') && cl!='en'){
        i18n.changeLanguage('en');
    }
    
  });
  */
  
  return (
    <footer className="ontario-footer ontario-footer--expanded" role="contentinfo" lang={cl}>
      <div className="ontario-footer__expanded-top-section">
        <div className="ontario-row">
          <div className="ontario-columns ontario-small-12 ontario-medium-6">
          {
            isIntranet()?
            <>
            <h2 className="ontario-h4">{t("footer-header1-intranet")}</h2>
            <p>{t("footer-description1-intranet")}</p>
            </>
            :
            <>
            <h2 className="ontario-h4">{t("footer-header1")}</h2>
            <p>{t("footer-description1")}</p>
            </>            
          }
          </div>
          <div className="ontario-columns ontario-small-12 ontario-medium-6">
            <h2 className="ontario-h4">{isIntranet()?t("footer-header2-intranet"):t("footer-header2")}</h2>
            <p>
              <Trans i18nKey="footer-description2">You can check our  <Link to={help_link}>help page</Link> for frequently asked questions and answers.</Trans>
            </p>
            {
              isIntranet()?
              <a
              className="ontario-footer__button ontario-button ontario-margin-bottom-0-!"
              href={"mailto:infogo@ontario.ca?subject="+encodeURIComponent(t('footer-email-subject-intranet'))}              
              >
                {t("footer-btn-email")}
              </a>
              :
              <></>
              /*<a
              className="ontario-footer__button ontario-button ontario-margin-bottom-0-!"
              href={"mailto:infogo@ontario.ca?subject="+encodeURIComponent(t('footer-email-subject'))}              
              >
                {t("footer-btn-email")}
              </a>*/
            }
            
          </div>
        </div>
      </div>
      <div className="ontario-row ontario-footer__expanded-bottom-section">
        <div className="ontario-columns ontario-small-12">
          <ul className="ontario-footer__links-container ontario-footer__links-container--inline">
            <li>
              <a
                className="ontario-footer__link"
                href={t("about-ontario-link")}
              >
                {t("about-ontario")}
              </a>
            </li>
            <li>
              <a
                className="ontario-footer__link"
                href={t("accessibility-link")}
              >
                {t("accessibility")}
              </a>
            </li>
            <li>
              <a
                className="ontario-footer__link"
                href={t("news-link")}
              >
                {t("news")}
              </a>
            </li>
            <li>
              <a
                className="ontario-footer__link"
                href={t("privacy-link")}
              >
                {t("privacy")}
              </a>
            </li>
            <li>
              <a
                className="ontario-footer__link"
                href={t("terms-of-use-link")}
              >
                {t("terms-of-use")}
              </a>
            </li>
          </ul>
          <div className="ontario-footer__copyright">
            <a
              className="ontario-footer__link"
              href={t('footer-copyright-link')}
            >
              &copy; {t("footer-copyright")} <span className="ontario-nbsp">2012&ndash;{copyRightYear}</span>
            </a>
          </div>
        </div>
      </div>
      <button
        onClick={scrollToTop}
        className="ontario-back-to-top--default"
        id="actual-btt-button"
        aria-label={t('scroll-to-top')}
        type="button"
      >
        <svg
          className="ontario-icon"
          xmlns="http://www.w3.org/2000/svg"
          height="32px"
          viewBox="0 0 24 24"
          width="32px"
          fill="#000000"
          aria-hidden="true"
        >
          <use href="#ontario-icon-arrow-up"></use>
        </svg>
        {t("top")}
      </button>
      <Test currentlanguage={cl}></Test>
    </footer>
  );
}

function Test(props){
  if(props.currentlanguage==='en'){
      return(        
              
              <span style={{display:"none"}}>
                  Site is in English Mode
              </span>
          
      )    
  }
  else{
      return(  
              <span style={{display:"none"}}>
                  Site is in Français Mode
              </span> 
      )
  }
    
}

export default ODS_FOOTER
