import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import {StringifyQueryObject} from './js/utils';

//This is for backward compatability
function Redirect(){
  
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  //const [redirectLocation, setRedirectLocation] = useState('');
  var pathname = "/";
  var search_par = {};
  var loc = "";
  //console.log(location);
  if(location.hash!=''){
    var hash_p = location.hash.split('/');
    //console.log(hash_p);

    if(hash_p.length>0){
      if('#forms'==hash_p[0].toLowerCase()){
        pathname="/forms"
      }
      else if(hash_p.length>1)
      {
        if('#orgprofile'==hash_p[0].toLowerCase()){
          pathname="/org"
        }
        else if('#empprofile'==hash_p[0].toLocaleLowerCase()){
          pathname="/emp"
        }
        search_par={"id": hash_p[1]}
        if(hash_p.length>2){
          if(hash_p[2].toUpperCase()=='FR'){
            pathname="/fr"+pathname;
          }
        }
      }
    }
  }

  useEffect(()=>{
    /*if (redirectLocation !='') {
      navigate({
        pathname: "/"        
      });
    }*/
    //console.log(redirectLocation)
    var q = StringifyQueryObject(search_par);
    navigate({
      pathname: pathname,
      search: q   
    });
    //console.log(pathname)
    },[])

  return(
      <>
      <span role="alert" aria-live="assertive">{t('redirecting')}....</span>
      </>
      
  )

}




export default Redirect;
