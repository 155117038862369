import React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {IOrg, IIndirect_Organization} from '../../interfaces/IOrganization'
import {GetPath, capitalizeOnlyFirstLetter} from '../../js/utils';

interface IIndirectOrganizationList{
  categoryItems?:IIndirect_Organization[],
  header_title: string,
  exclusion_list:number[],
  querystring?:string
}

const IndirectOrganizationList = (props:IIndirectOrganizationList)=>{
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  var current_locale = i18n.language;
  var has_others = props.categoryItems!=null && props.categoryItems.length>0?true:false;
  var b = props.querystring!=null && props.querystring.length>0?"&"+props.querystring:"";
  var _path = GetPath('org', i18n.language);
  if(has_others){
    return(
      <div className="ontario-margin-bottom-40-!">
      <h3 className='ontario-h5'>{props.header_title}:</h3>
      <ul className="ul-container">        
        {
          props.categoryItems!.map((cat_item, index) => {
            var cat:IIndirect_Organization=cat_item;
            if(props.exclusion_list.includes(cat.catgeoryId)==false){
              let categoryNameFr, categoryName;
              if(cat.categoryFr) categoryNameFr = capitalizeOnlyFirstLetter(cat.categoryFr);
              if(cat.category) categoryName = capitalizeOnlyFirstLetter(cat.category);
              return(
                <li key={index}><span className='bold'><h4 className='ontario-h6'>{current_locale=='fr'?categoryNameFr:categoryName}</h4></span>
                  <ul className='ul-container'>
                    {
                      cat.orgs?.map((org_item, index2) => {
                        var org:IOrg=org_item
                        return(
                          <li key={index2}><span></span>
                            {org.relationshipType!=null?
                              (current_locale=='fr'?org.relationshipType?.labelFr:org.relationshipType?.label)+ " "
                              :
                              <></>
                            }
                            
                            <Link className='' to={_path+'?id=' +  encodeURIComponent(org.orgId)  + b} >
                            {current_locale=='fr'?org.orgNameFr:org.orgName}
                            </Link>
                          </li>
                        )
                      })
                    }       
                  </ul>
                </li>                
              )

            }              

          })
        }
        
      </ul>
      </div>
    )
    
  }
  else{
    return(
      <></>
    )
  }

}


export default memo(IndirectOrganizationList)