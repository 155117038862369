import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {useSearchParams} from "react-router-dom";
import { useTranslation } from 'react-i18next';
// @ts-ignore
import ODS_HEADER from '../../components/Header.tsx';
import ODS_FOOTER from '../../components/Footer.js';
import { GetEmployee, SearchLookupItem, GetTopOrg } from '../../components/CurrentVer/APICalls';
// @ts-ignore
import PageAlert, {PageAlertRefObject} from '../../components/PageAlert.tsx'
// @ts-ignore
import StepIndicator from '../../components/StepIndicator.tsx';
import {scrollToTop} from '../../js/ui';
import {formatPageTitle} from '../../js/utils';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
// @ts-ignore
import UpdateEmployeeShowForm from '../../components/Intranet/UpdateEmployeeShowForm.tsx'
// @ts-ignore
import BreadCrumbs from '../../components/BreadCrumb.tsx'
// @ts-ignore
import { IIntranetForm } from '../../interfaces/IUpdateRequest.tsx';

const EditEmpPage = () => {
    const { t } = useTranslation();
    
    const { i18n } = useTranslation();
    // @ts-ignore
    document.title = formatPageTitle(t('title-edit-employee'), t);
    document.documentElement.lang=(i18n.language);
    const [currLocale, setCurrLocale] = useState(i18n.language);
    const [searchParams] = useSearchParams();
    
    
    let empId = searchParams.get('id');
    // @ts-ignore
    const [intranetForm, setForm] = useState<IIntranetForm>({});
    
    const alert_ref = useRef<PageAlertRefObject>(null);
    
    const updateAlerts = (action, new_alert, anchor)=>{
        if(action.toUpperCase()=="CLEAR")
            alert_ref.current?.Clear();
        else if(action.toUpperCase()=="APPEND")
            alert_ref.current?.Append(new_alert, anchor);
        else
            alert_ref.current?.Set(new_alert, anchor);             
    }

    const handleQueryChange = async (p) => {
        var u = await GetEmployee(p);
        var l = await SearchLookupItem({'formname': 'EDITEMPLOYEE', 'locale': i18n.language})
        var t= await GetTopOrg(u.associatedOrg.orgId);
        //var o= await GetCoordinators();
        if(u!=null){
            //console.log(l);
            u.topOrg = t;
            setForm({assignment:u, lookup: {position_lookup:l.valueListItems, coordinators:[], honorific_lookup:[]}, action_type:"EDIT", update_alert_fn:updateAlerts });
            scrollToTop();           
        }
    }  

    


    useEffect(() => {
        handleQueryChange(empId);
    }, [empId, currLocale])

    

    return (
        <>
        <AuthenticatedTemplate>        
          <div id="layout-header"><ODS_HEADER onLocaleChange={setCurrLocale} /></div>
          <noscript>{t('no-javascript')}</noscript>
          <main id="main-content" style={{"minHeight":"80%"}} tabIndex={-1}>
            <BreadCrumbs>
            </BreadCrumbs>
              <div className="ontario-row">
                <div className="ontario-main-content ontario-small-12 ontario-columns ontario-margin-top-24-!">                
                    <div id="app-main">
                        <PageAlert ref={alert_ref} alertType={'error'} alertHeader={t('form-validation-error')}></PageAlert>
                        <StepIndicator href={'forms'} title={'title-edit-employee'} label={'back-to-form'}></StepIndicator>
                        <div className="top-margin-2">                        
                        <UpdateEmployeeShowForm formSetting={intranetForm}></UpdateEmployeeShowForm>
                        </div>
                    </div>
                </div>
              </div>
          </main>
          <div id="layout-footer" className='autoTopMargin'><ODS_FOOTER /></div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>            
        </UnauthenticatedTemplate>
        </>
    );
    };
  

  

export default EditEmpPage;


