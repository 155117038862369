import { useState, createContext, useRef, useEffect } from 'react';
import React from 'react';
import { AuthenticatedTemplate } from "@azure/msal-react";
// @ts-ignore
import {ISearchParametersForUpdate} from '../../../interfaces/ISearchForm.tsx'
import {sanitizeString} from '../../js/utils';
// @ts-ignore
import InfogoInput from '../InfogoInput.tsx'
// @ts-ignore
import InfogoHintExpander from '../InfogoHintExpander.tsx'
import { useTranslation } from 'react-i18next';
import {useNavigate, createSearchParams} from 'react-router-dom';
import {GetLocalePath} from '../../js/utils';

const SearchParamContext = createContext<ISearchParametersForUpdate>({} as ISearchParametersForUpdate);

function UpdateEmployeeIndex () { 
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    
    const firstname_input = useRef<HTMLInputElement>(null);
    const lastname_input = useRef<HTMLInputElement>(null);
    const search_form = useRef<HTMLFormElement>(null);
    const search_div = useRef<HTMLDivElement>(null);
    const add_div = useRef<HTMLDivElement>(null);

    
    const [inputs, setInputs] = useState({lastname: '', firstname: '', updatetype:''});
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
        //console.log(inputs);         
    }

    const handleDropdownChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      if(search_div.current!==null){
        if(value !== undefined && value !== '' && value !== 'AddEmp'){
          search_div.current.style.display = "block";  
        }
        else if(value !== undefined && value !== '' && value == 'AddEmp'){
          search_div.current.style.display = "none";
        }
        else{
          search_div.current.style.display = "none";
        }
      }
      
      if(add_div.current!==null){
        if(value !== undefined && value !== '' && value == 'AddEmp'){
          add_div.current.style.display = "block";  
        }
        else if(value !== undefined && value !== '' && value !== 'AddEmp'){
          add_div.current.style.display = "none";
        }
        else{
          add_div.current.style.display = "none";
        }
      }

      setInputs(values => ({...values, [name]: value}));

               
  }

    const searchOnClick = (event) => {
        event.preventDefault();
        var temp_lastname = sanitizeString(inputs.lastname); 
        var temp_firstname = sanitizeString(inputs.firstname); 
        var temp_type = inputs.updatetype
        //console.log(temp_type);
        if(temp_type=='AddEmp'){
          navigate({
            pathname: GetLocalePath(i18n.language)+"AddEmp"            
          });
        }
        else{
          if(!search_form?.current?.checkValidity()){
            //search_form?.current?.reportValidity()
            return;
          }

          if(temp_lastname == null || temp_lastname.trim()==='' || temp_firstname == null || temp_firstname.trim()===''){      
            
          }
          else{
            navigate({
              pathname: GetLocalePath(i18n.language)+"updateempresults",
              search: createSearchParams({
                lastname: temp_lastname,
                firstname: temp_firstname,
                updatetype: temp_type.toString()
              }).toString()
            });

          }
        }        
    }
  /************* PREPARE HINTS ********************* */   
  var actions= ['add', 'edit','delete', 'move']
  var hintItems: { label:string; description:string }[] = [];   
  actions.map((item, index)=> 
    hintItems.push({label: t(item+'-employee'), description: t(item+'-employee-hint')})  
    )

    return (
      <AuthenticatedTemplate>
      <form ref={search_form} onSubmit={(e)=>{e.preventDefault();}} className="ontario-columns ontario-small-10 ontario-medium-6 ontario-large-offset-0 ontario-large-12">
        <label className="ontario-label" htmlFor="Employee_Update_Type">
          {t('action')}
        </label>
        <select required className="ontario-input ontario-dropdown" 
          id="Employee_Update_Type" 
          name="updatetype"
          onChange={handleDropdownChange}
          value={inputs.updatetype}
          >
          <option value="" >{t('select')}</option>
          <option value="AddEmp" >{t('add-employee')}</option>
          <option value="EditEmp" >{t('edit-employee')}</option>
          <option value="DeleteEmp" >{t('delete-employee')}</option>
          <option value="MoveEmp" >{t('move-employee')}</option>
        </select>
        <InfogoHintExpander id={'update-employee-action-hint'} title={t('update-employee-action-hint')}>
          <dl>
          {hintItems.map((item, index)=>{
            return(
              <React.Fragment key={index}>
                <dt>{item.label}</dt><dd>{item.description}</dd>
              </React.Fragment>              
            )                    
          })}
          </dl>
        </InfogoHintExpander>
        <div ref={search_div} style={{display:"none"}}>
        <hr />
        <InfogoInput className={"ontario-input--20-char-width"} required={true} label={'firstname'} name={'firstname'} id={'firstname_search'} onChange={handleChange}></InfogoInput>
        <InfogoInput className={"ontario-input--20-char-width"} required={true} label={'lastname'} name={'lastname'} id={'lastname_search'} onChange={handleChange}></InfogoInput>
                  
        
        
        <button className="ontario-button ontario-button--primary" onClick={searchOnClick} type="submit">
            {t('search')}
        </button>
        </div>
        
        <div ref={add_div} style={{display:"none"}}>
        <button className="ontario-button ontario-button--primary" onClick={searchOnClick} type="submit">
            {t('continue')}
        </button>
        </div>

      </form>       
      
      </AuthenticatedTemplate>
  );

    
};

  

export default(UpdateEmployeeIndex)