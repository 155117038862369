import { React } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './i18n/config';
//import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import { App } from './App.tsx';

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { AppInsightsContextProvider } from './AppInsightsContext';
import { appInsights } from "./AppInsights";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);

        appInsights.setAuthenticatedUserContext(account.username, account.localAccountId, true)
    }
});
  
  const container = document.getElementById('app');
  const root = ReactDOM.createRoot(container);
  root.render(
    <MsalProvider instance={msalInstance}>
      <AppInsightsContextProvider >
      <BrowserRouter>
        <App></App>
      </BrowserRouter>
      </AppInsightsContextProvider>            
    </MsalProvider>
    
  );



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

