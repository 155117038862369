import {OnTrackUM_AjaxCall, InfoGo_AjaxCall, GetCurrentQuery, StringifyQueryObject} from '../../js/utils'
import {IValidSearchParameters, newIValidSearchParameters} from '../../interfaces/ISearchForm.tsx'

async function SearchEmployee(par){
  const url = process.env.REACT_APP_SEARCHEMPLOYEEURL_AZFUNCTIONAPI_VER
  var relevant_par = {'keywords': par.keywords, 'locale': par.locale, 'searchtype': par.searchtype, 'sortcol': par.sortcol, 'sortdir': par.sortdir, 'page': par.page}
  var q = StringifyQueryObject(relevant_par);//`keywords=${encodeURIComponent(par.keywords)}&locale=${encodeURIComponent(par.locale)}`;
  var url_q = url+'&'+q;
  var u = await InfoGo_AjaxCall(url_q);
  //console.log(u);
  return u;
}



async function SearchOrganization(par){
  
  const url = process.env.REACT_APP_SEARCHORGANIZATIONURL_AZFUNCTIONAPI_VER
  var relevant_par = {'keywords': par.keywords, 'locale': par.locale, 'searchtype': par.searchtype, 'sortcol': par.sortcol, 'sortdir': par.sortdir, 'page': par.page}
  var q = StringifyQueryObject(relevant_par);//`keywords=${encodeURIComponent(par.keywords)}&locale=${encodeURIComponent(par.locale)}`;
  var url_q = url+'&'+q;
  //console.log(url_q);
  var u = await InfoGo_AjaxCall(url_q);
  //console.log(u);
  return u;
}

async function GetEmployee(assignmentId){
  
  const url = process.env.REACT_APP_GETEMPLOYEEURL_AZFUNCTIONAPI_VER
  var q = `assignmentId=${encodeURIComponent(assignmentId)}`;
  var url_q = url+'&'+q;
  var u = await InfoGo_AjaxCall(url_q);
  //console.log(u);
  return u;
}

async function GetOrganization(orgId){
  
  const url = process.env.REACT_APP_GETORGANIZATIONURL_AZFUNCTIONAPI_VER
  
  var q = `orgId=${encodeURIComponent(orgId)}`;
  var url_q = url+'&'+q;
  var u = await InfoGo_AjaxCall(url_q);
  //console.log(u);
  return u;
}

async function SearchCategory(){
  
  const url = process.env.REACT_APP_CATEGORIESURL_AZFUNCTIONAPI_VER
  
  //var q = `orgId=${encodeURIComponent(orgId)}`;
  var url_q = url;//+'&'+q;
  var u = await InfoGo_AjaxCall(url_q);
  //console.log(u);  
  return u;
}

async function GetTopOrg(orgId){
  
  if(orgId == null || orgId<1)
  return null;

  const url = process.env.REACT_APP_CATEGORIESURL_AZFUNCTIONAPI_VER
  var q = `orgId=${encodeURIComponent(orgId)}`;
  var url_q = url+'&'+q;
  var u = await InfoGo_AjaxCall(url_q);
  //console.log(u);  
  if(u.categories != null && u.categories[0].organizations !=null && u.categories[0].organizations[0]){
    return u.categories[0].organizations[0];
  }
  
  return null;
}

async function GetRefreshHistoryLatest(){
  
  const url = process.env.REACT_APP_FRESHHISTORYURL_AZFUNCTIONAPI_VER
  
  var q = `latestonly=${encodeURIComponent(true)}`;
  var url_q = url+'&'+q;
  var u = await InfoGo_AjaxCall(url_q);
  //console.log(u);
  return u;
}  

async function SendEmail(par){
  console.log(par);
  const url = process.env.REACT_APP_SENDEMAIL_AZFUNCTIONAPI_VER
  var relevant_par = {'fromemail': par.fromemail, 'toemail': par.toemail, 'ccemail': par.ccemail, 'bccemail': par.ccemail, 'subject': par.subject, 'message': par.message}
  console.log (relevant_par);
  var q = StringifyQueryObject(relevant_par);  
  var url_q = url+'&'+q;
  var u = await InfoGo_AjaxCall(url_q);
  //console.log(u);  
  return u;
} 

async function SaveUpdateRequest(par){
  //console.log(par);
  const url = process.env.REACT_APP_SAVEUPDATEREQUESTURL_AZFUNCTIONAPI_VER
  var relevant_par = par;
  //console.log (relevant_par);
  //var q = StringifyQueryObject(relevant_par);  
  var url_q = url;//+'&'+q;
  var u = await InfoGo_AjaxCall(url_q, relevant_par);
  //console.log(u);  
  return u;
} 
  
async function SearchEmployeeForUpdate(par){
  const url = process.env.REACT_APP_SEARCHEMPLOYEEFORUPDATEURL_AZFUNCTIONAPI_VER
  var relevant_par = {'lastname': par.lastname, 'firstname': par.firstname}
  var q = StringifyQueryObject(relevant_par);
  var url_q = url+'&'+q;
  var u = await InfoGo_AjaxCall(url_q);
  //console.log(u);
  return u;
}

async function SearchLookupItem(par){
  const url = process.env.REACT_APP_SEARCHVALUELISTURL_AZFUNCTIONAPI_VER
  var relevant_par = {'typecode': par.typecode, 'locale': par.locale?par.locale:'EN', 'formname': par.formname}
  var q = StringifyQueryObject(relevant_par);
  var url_q = url+'&'+q;
  var u = await InfoGo_AjaxCall(url_q);
  //console.log(u);
  return u;
}
/*
async function GetCoordinators(){
  
  var u = await GetOrganization(3385);
  //console.log(u);
  if(u==null || u.positions==null)
    return [];
  return u.positions.sort(function (a, b) {
    return a.positionTitle.localeCompare(b.positionTitle);
  });
;
}*/

async function GetCoordinators(orgId=0, assId=0){
  const url = process.env.REACT_APP_SEARCHCOORDINATORURL_AZFUNTIONAPI_VER
  var q = `orgId=${encodeURIComponent(orgId)}&assId=${encodeURIComponent(assId)}`;
  var url_q = url+'&'+q;
  var u = await InfoGo_AjaxCall(url_q);
  //console.log(u);
  return u;


  
}

export { SearchEmployee, SearchOrganization, GetOrganization, GetEmployee, SearchCategory, GetRefreshHistoryLatest, SendEmail, SearchEmployeeForUpdate, SearchLookupItem, GetTopOrg , SaveUpdateRequest, GetCoordinators};