
import React, { createContext, useContext, ReactNode } from 'react';
import { memo} from 'react';
import { useTranslation } from 'react-i18next';
import { IKeyValuePair } from '../interfaces/IGeneric'

export interface IDropdownButton
{
  title: string;
  id: string;
  children: ReactNode;
  //onClickFunction?:Function;
  className?:string; 
}

export interface IDropdownButtonItem
{
  itemkey:string;
  itemvalue: string;
  children: ReactNode;
  onClickFunction:Function;
}


interface IDropdownItemFunction{
  toggleFn:Function;
  onkeyupFn:Function;
}

const DropdownButtonContext = createContext<IDropdownItemFunction>({toggleFn: () => {}, onkeyupFn: () => {}});

const DropdownButton = (props:IDropdownButton) =>{
    const { t } = useTranslation();
    var content = React.Children.toArray(props.children); //:IKeyValuePair[]=props.children;
    
    /**************** ON BLUR ********************/
    const onBlur = (e) => {
      e.stopPropagation();
      var container = e.target.closest('.dropdown');
      var button = container.getElementsByClassName('dropdown-button');
      if (!e.currentTarget.contains(e.relatedTarget)) {
          e.target.closest('.dropdown').classList.remove("open"); 
          button[0].setAttribute("aria-expanded", "false");
      }       
    }

    /**************** TOGGLE ********************/
    const toggleContent = (e) => {
      e.stopPropagation();
      //alert('toggleContent');
      var container = e.target.closest('.dropdown');
      var button = container.getElementsByClassName('dropdown-button');
      if(container.classList.contains('open')){
        container.classList.remove("open");
        button[0].setAttribute("aria-expanded", "false");
      }
      else{
        container.classList.add("open");
        button[0].setAttribute("aria-expanded", "true");
        var ITEMS = container.getElementsByClassName('dropdown-item');
        if(ITEMS.length>0){ITEMS[0].focus()}
      }  
    
    }

    /************** KEY PAD ********************/
    const onKeyup = (e) => {
      if(e.keyCode==38 || e.keyCode==40){
        e.stopPropagation();
        e.preventDefault();
      }

      var container = e.target.closest('.dropdown');

      var ITEMS = container.getElementsByClassName('dropdown-item');
      var currentIndex = 0;
      var lastIndex = ITEMS.length-1;
      

      for (var i = 0; i < ITEMS.length; i++) {
          var a = e.target.getAttribute('data-itemkey');
          var b = ITEMS[i].getAttribute('data-itemkey');
          
          if(a == b){
            currentIndex=i;
            break;
          } 
      }
      if (e.keyCode == 38) {
          if (currentIndex === 0) {
            e.preventDefault();
            ITEMS[lastIndex].focus(); 
          } 
          else {
            e.preventDefault();
            ITEMS[currentIndex-1].focus();
          }
      }
      else if (e.keyCode == 40) {
          if (currentIndex === lastIndex) {
              e.preventDefault();
              ITEMS[0].focus();
          } 
          else {
              e.preventDefault();
              ITEMS[currentIndex+1].focus();
          }
      }
    
    }

    var buttonId = (props.id?props.id:props.title) + "-toggler-dropdown";
    var contentId = buttonId + "-content";
    return (
      <DropdownButtonContext.Provider value={{'toggleFn':toggleContent, 'onkeyupFn': onKeyup}}>
      <div className={"dropdown " + props.className} onBlur={(e) => (onBlur(e))} >
          <button className="dropdown-button" id={buttonId} aria-controls={contentId} aria-label={props.title} type="button" onClick={(e) => (toggleContent(e))} aria-haspopup="true"> 
            <svg className="ontario-icon dropdown-button-icon-open" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use xlinkHref="#ontario-icon-chevron-down"></use> 
            </svg>
            <svg className="ontario-icon dropdown-button-icon-close" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use xlinkHref="#ontario-icon-chevron-up"></use> 
            </svg> 
            <span>{props.title}</span>
          </button>
          <div id={contentId} className="dropdown-content" aria-labelledby={buttonId}>

          <ul role="menu">
            {
              props.children
            }
          </ul>
          
          </div>
      </div>
      </DropdownButtonContext.Provider>
    );
}

export const DropdownButtonItem = (props:IDropdownButtonItem) =>{
  const ddb_functions = useContext(DropdownButtonContext);
  var item_key = props.itemkey;
  var item_value = props.itemvalue;
  const itemSelect = (key, value, e) => {
    e.preventDefault();
    //alert('sort changed: ' + key + ' ' + value)
    if(props.onClickFunction!=undefined && props.onClickFunction!=null){        
      props.onClickFunction(key, value,  e);
    }
    ddb_functions.toggleFn(e);
  }
  const onkeyupFn = (e) => {
    ddb_functions.onkeyupFn(e)
  }

  return(
    <li role="presentation"><button data-itemkey={item_key} className="dropdown-item" role="menuitem" onClick={(e) => (itemSelect(item_key, item_value, e))} onKeyDown={(e)=>(onkeyupFn(e))} type='button'>{props.children}</button></li>
  )
}

export default memo(DropdownButton)