import React, { useEffect } from 'react';
import { useState, memo, useRef} from 'react';
import {useNavigate} from 'react-router-dom';

import {SaveUpdateRequest} from '../CurrentVer/APICalls';
// @ts-ignore
import {IUpdateRequest, ICoordinator} from '../../interfaces/IUpdateRequest.tsx'
// @ts-ignore
import InfogoInput from '../InfogoInput.tsx'
// @ts-ignore
import InfogoSelect from '../InfogoSelect.tsx'
// @ts-ignore
import InfogoTextArea from '../InfogoTextArea.tsx'
// @ts-ignore
import OnUnloadConfirm from '../OnUnloadConfirm.tsx'
// @ts-ignore
import InfogoHintExpander from '../InfogoHintExpander.tsx'
import {scrollToTop, confirm_dialog} from '../../js/ui';
import {GetLocalePath} from '../../js/utils';
import {IAssignment} from '../../interfaces/IAssignment'
import {IKeyValuePair} from '../../interfaces/IGeneric'
import { useTranslation } from 'react-i18next';
import { useMsal } from "@azure/msal-react";
import TableOfContents from './TableOfContents'
import { IIntranetForm, IIntranetFormLookup, IValueListItem } from '../../interfaces/IUpdateRequest';

const AddEmployeeForm = ({ formSetting }) => {
    var intranetFormSetting:IIntranetForm = formSetting;
    var currentitem:IAssignment = intranetFormSetting.assignment;
    var lookups:IIntranetFormLookup = intranetFormSetting.lookup;
    const { accounts } = useMsal();
    const sub_name = accounts[0] && accounts[0].name;
    const sub_em = accounts[0] && accounts[0].username;
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    var current_locale = i18n.language;
    const { t } = useTranslation();

    const [isDirty, setDirty] = useState(false);
    const edit_form = useRef<HTMLFormElement>(null);
    const coorname_input = useRef<HTMLInputElement>(null) as React.MutableRefObject<HTMLInputElement>;;
    const coorphone_input = useRef<HTMLInputElement>(null);
    const cooremail_input = useRef<HTMLInputElement>(null);

    const appendToAlertMessages = (new_alert, anchor="")=>{      
      if(intranetFormSetting.update_alert_fn!=null){
        intranetFormSetting.update_alert_fn("APPEND", new_alert, anchor);
      }     
    }
    const resetAlertMessages = ()=>{      
      if(intranetFormSetting.update_alert_fn!=null){
        intranetFormSetting.update_alert_fn("CLEAR");
      }     
    }

    /************* PREPARE LOOKUPS ********************* */
    var coordinators = lookups.coordinators;
    var coor_dl:IKeyValuePair[]=[];    
    coordinators.map((coor:ICoordinator, index)=>{
      coor_dl.push({key: coor.homeTopOrgId.toString(), value: coor.positionTitle})
    })

    var asstypes = lookups.position_lookup;
    var asstype_dl:IKeyValuePair[]=[];    
    asstypes.map((lookupItem:IValueListItem, index)=>{
      if(current_locale=='fr')
      { asstype_dl.push({key: lookupItem.code, value: lookupItem.labelFr}); }
      else
      { asstype_dl.push({key: lookupItem.code, value: lookupItem.label}); }      
    })

    var honors = lookups.honorific_lookup;
    var honor_dl:IKeyValuePair[]=[];    
    honors.map((lookupItem:IValueListItem, index)=>{
      if(current_locale=='fr')
      { honor_dl.push({key: lookupItem.code, value: lookupItem.labelFr}); }
      else
      { honor_dl.push({key: lookupItem.code, value: lookupItem.label}); }      
    })
    
    /************************************************** */

    //console.log(currentitem);
    const [inputs, setInputs] = useState({Publish_To: 'Intranet & Internet', Rejoining_OPS: 'No', First_Name: '', Last_Name: '', Middle_Name: '', Gender: 'Not specified', Honorific: '', Email: '' 
      ,Job_Title: '', Assignment_Type: '', Telephone_Number: '', Telephone_Ext: '', Name_of_Direct_Report: '', Email_of_Direct_Report:''    
      ,Ministry: '', Division: '', Branch: '', Unit: ''
      ,Additional_Info:'', Top_Org_Id: '', Assignment_Type_Display: '', Honorific_Display: ''
      ,Coordinator_Name:'', Coordinator_Phone:'', Coordinator_Email: ''
    });
    
    
    var update_req:IUpdateRequest = {'failToSend': false, 'type': 'ADD', 'itemId': '0', 'originalJSON': '' };
    
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
        //console.log(inputs); 
        if(!isDirty) {setDirty(true);}        
    }
    
    const handleChangeSelect = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      const text = event.target.options[event.target.selectedIndex].text;
      setInputs(values => ({...values, [name]: value}));
      setInputs(values => ({...values, [name+"_Display"]: text}));
      //console.log(text); 
      if(!isDirty) {setDirty(true);}        
    }

    const handleChangeForMinistry = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      if(value != null && value!=''){
        for(var i=0; i<coordinators.length; i++){
          if(coordinators[i].homeTopOrgId==value){
            
            if(coorname_input?.current){              
              coorname_input.current.value=coordinators[i].individualName!;
            }
            if(coorphone_input?.current){
              coorphone_input.current.value=coordinators[i].displayPhone!;
            }
            if(cooremail_input?.current){
              cooremail_input.current.value=coordinators[i].displayEmail!;
            }
            
            setInputs(values => ({...values, Ministry: coordinators[i].positionTitle!, Coordinator_Name: coordinators[i].individualName!, Coordinator_Phone: coordinators[i].displayPhone!, Coordinator_Email: coordinators[i].displayEmail!}));
            break;
          }
        }
      }
      else{
        
        if(coorname_input?.current){
          console.log("!");
          coorname_input.current.value='';
        }
        if(coorphone_input?.current){
          coorphone_input.current.value='';
        }
        if(cooremail_input?.current){
          cooremail_input.current.value='';
        }
      }
      
      setInputs(values => ({...values, [name]: value}));
      if(!isDirty) {setDirty(true);}         
    }
    
    const submitOnClick = async(event) => {
        event.preventDefault();
        resetAlertMessages();
        if(!edit_form?.current?.checkValidity()){
          //edit_form?.current?.reportValidity()
          scrollToTop();
          return;
        }
        
        else{
          if(isDirty) {setDirty(false);} 
          update_req.updatedJSON=JSON.stringify(inputs);
          update_req.coordinatorName=coorname_input.current?.value;
          update_req.coordinatorEmail=cooremail_input.current?.value;
          update_req.coordinatorPhone=coorphone_input.current?.value;
          
          console.log(update_req);
          var result2 = await SaveUpdateRequest(update_req);
          console.log(result2);
          if(result2=='RequestSaved'){
            navigate({
              pathname: GetLocalePath(i18n.language)+"confirmation",              
            });
          }
          else{
            appendToAlertMessages(result2.error_message);
            scrollToTop();
          }
        
        }
    }
  
    const resetOnClick = (event)=>{
      event.stopPropagation();
      event.preventDefault();
      //if the page is already dirty, the on onbeforeunload in OnUnloadConfirm will handle the prompt 
      //this is to prevent the confirm dialog from showing up twice
      if(isDirty){
        window.location.reload();
      }
      else{
        if(confirm_dialog(t('reset-confirmation'))) { window.location.reload(); }
      }            
    }
    
    //FORM STARTS HERE
    if(currentitem !== undefined && currentitem != null){    
      //console.log(currentitem);
      return(
        <>
        <OnUnloadConfirm shouldPrompt={isDirty}></OnUnloadConfirm>
        <TableOfContents/>
        <form ref={edit_form} onSubmit={(e)=>{e.preventDefault();}} className="intranet-form">        
          <div className="ontario-form-group">
            
  
            <fieldset className="ontario-fieldset">
                <legend className="ontario-fieldset__legend ontario-fieldset__legend--large">
                  <h2 className="ontario-h3 content-title">{t('employee-info')}</h2>
                </legend>

                <label className="ontario-label" htmlFor="Publish_To">
                {t('publish-to')}
                </label>
                <select required className="ontario-input ontario-dropdown" 
                id="Publish_To" 
                name="Publish_To"
                onChange={handleChange}
                value={inputs.Publish_To}>
                  <option value="Intranet & Internet" >{t('intranet-and-internet')}</option>
                  <option value="Intranet Only" >{t('intranet-only')}</option>
                </select>

                <label className="ontario-label" htmlFor="Rejoining_OPS">
                {t('rejoining-ops')}
                </label>
                <select required className="ontario-input ontario-dropdown" 
                id="Rejoining_OPS" 
                name="Rejoining_OPS"
                onChange={handleChange}
                value={inputs.Rejoining_OPS}>
                  <option value="Yes" >{t('yes')}</option>
                  <option value="No" >{t('no')}</option>
                </select>
  
                <InfogoInput className={"ontario-input--20-char-width"} required={true} label={'firstname'} name={'First_Name'} id={'txt_First_Name'} onChange={handleChange} value={inputs.First_Name} setAlertMessages={appendToAlertMessages}></InfogoInput>
                <InfogoInput className={"ontario-input--20-char-width"} label={'middlename'} name={'Middle_Name'} id={'txt_Middle_Name'} onChange={handleChange} value={inputs.Middle_Name}></InfogoInput>
                <InfogoInput className={"ontario-input--20-char-width"} required={true} label={'lastname'} name={'Last_Name'} id={'txt_Last_Name'} onChange={handleChange} value={inputs.Last_Name} setAlertMessages={appendToAlertMessages}></InfogoInput>
                <InfogoInput className={"ontario-input--20-char-width"} required={true} label={'email'} name={'Email'} id={'txt_Email'} onChange={handleChange} value={inputs.Email} setAlertMessages={appendToAlertMessages} type={'email'} ></InfogoInput>
  
                <label className="ontario-label" htmlFor="Gender">
                {t('gender')}
                </label>
                <select required className="ontario-input ontario-dropdown" 
                id="Gender" 
                name="Gender"
                onChange={handleChange}
                value={inputs.Gender}>
                  <option value="Male" >{t('male')}</option>
                  <option value="Female" >{t('female')}</option>
                  <option value="Not specified" >{t('not-specified')}</option>
                </select>
                <InfogoHintExpander id={'Gender-hint'} title={t('gender-hint')}>
                    {t('gender-hint-body')}
                </InfogoHintExpander>

                <InfogoSelect required={false} label={'honorific'} name={'Honorific'} id={'txt_Honorific'} onChange={handleChangeSelect} value={inputs.Honorific} setAlertMessages={appendToAlertMessages} includeBlank={true} blankText={''} options={honor_dl}></InfogoSelect>
                
            </fieldset>
  
            <fieldset className="ontario-fieldset position-info">
                <legend className="ontario-fieldset__legend ontario-fieldset__legend--large">
                  <h2 className='ontario-h3 content-title'>{t('position-info')}</h2>
                </legend>

                <InfogoInput className={"ontario-input--20-char-width"} required={true} label={'job-title'} name={'Job_Title'} id={'txt_Job_Title'} onChange={handleChange} value={inputs.Job_Title} setAlertMessages={appendToAlertMessages}></InfogoInput>
                <InfogoSelect required={true} label={'assignment-type'} name={'Assignment_Type'} id={'txt_Assignment_Type'} onChange={handleChangeSelect} value={inputs.Assignment_Type} setAlertMessages={appendToAlertMessages} includeBlank={true} blankText={'select'} options={asstype_dl}>
                  <InfogoHintExpander id={'assignment-type-hint'} title={t('assignment-type-hint')}>
                  <dl>
                  {lookups.position_lookup.map((item, index)=>
                    {
                      return(
                        current_locale=='fr'?
                        <React.Fragment key={index}>
                        <dt>{item.labelFr}</dt>
                        <dd>{item.descriptionFr}</dd>
                        </React.Fragment>
                        :
                        <React.Fragment key={index}>
                        <dt>{item.label}</dt>
                        <dd>{item.description}</dd>
                        </React.Fragment>
                      )                    
                    }
                  )}
                  </dl>
                  </InfogoHintExpander>
                </InfogoSelect>
                
                <InfogoInput className={"ontario-input--10-char-width"} required={true} label={'phone'} name={'Telephone_Number'} id={'txt_Telephone_Number'} onChange={handleChange} value={inputs.Telephone_Number} hint={'phone-hint'} type={"tel"} setAlertMessages={appendToAlertMessages} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}">
                  <InfogoHintExpander id={'phone-hint-expander'} title={t('phone-hint-expander')}>
                    {t('phone-hint-expander-body')}
                  </InfogoHintExpander>
                </InfogoInput>
                <InfogoInput className={"ontario-input--10-char-width"} required={false} label={'phone-ext'} name={'Telephone_Ext'} id={'txt_Telephone_Ext'} onChange={handleChange} value={inputs.Telephone_Ext}></InfogoInput>
                <InfogoInput className={"ontario-input--20-char-width"} required={true} label={'direct-report-name'} name={'Name_of_Direct_Report'} id={'txt_Name_of_Direct_Report'} onChange={handleChange} value={inputs.Name_of_Direct_Report} setAlertMessages={appendToAlertMessages}>
                  <InfogoHintExpander id={'direct-report-name-hint'} title={t('direct-report-hint')}>
                    {t('direct-report-hint-add')}
                  </InfogoHintExpander>
                </InfogoInput>
                
                <InfogoInput className={"ontario-input--20-char-width"} required={true} label={'direct-report-email'} name={'Email_of_Direct_Report'} id={'txt_Email_of_Direct_Report'} onChange={handleChange} value={inputs.Email_of_Direct_Report} setAlertMessages={appendToAlertMessages} type={'email'} ></InfogoInput>
                
            </fieldset>
  
            <fieldset className="ontario-fieldset">
                <legend className="ontario-fieldset__legend ontario-fieldset__legend--large">
                  <h2 className='ontario-h3 content-title'>{t('organization-info')}</h2>
                </legend>
                <InfogoSelect required={true} label={'ministry'} name={'Top_Org_Id'} id={'txt_Top_Org_Id'} onChange={handleChangeForMinistry} value={inputs.Top_Org_Id} setAlertMessages={appendToAlertMessages} includeBlank={true} blankText={'select-org'} options={coor_dl}>
                  <InfogoHintExpander id={'ministry-hint'} title={t('ministry-hint-add')}>
                    {t('ministry-hint-add-body')}
                  </InfogoHintExpander>
                </InfogoSelect>
                
                
  
                <InfogoInput className={"ontario-input--20-char-width"} required={true} label={'division'} name={'Division'} id={'txt_Division'} onChange={handleChange} value={inputs.Division} setAlertMessages={appendToAlertMessages}>
                  <InfogoHintExpander id={'division-hint'} title={t('division-hint')}>
                    {t('division-hint-body')}
                  </InfogoHintExpander>
                </InfogoInput>
                <InfogoInput className={"ontario-input--20-char-width"} required={true} label={'branch'} name={'Branch'} id={'txt_Branch'} onChange={handleChange} value={inputs.Branch} setAlertMessages={appendToAlertMessages}>
                  <InfogoHintExpander id={'branch-hint'} title={t('branch-hint')}>
                    {t('branch-hint-body')}
                  </InfogoHintExpander>
                </InfogoInput>
                <InfogoInput className={"ontario-input--20-char-width"} required={true} label={'unit'} name={'Unit'} id={'txt_Unit'} onChange={handleChange} value={inputs.Unit} setAlertMessages={appendToAlertMessages}>
                  <InfogoHintExpander id={'unit-hint'} title={t('unit-hint')}>
                    {t('unit-hint-body')}
                  </InfogoHintExpander>
                </InfogoInput>
                            
                <InfogoTextArea required={false} label={'additional-info'} name={'Additional_Info'} id={'txt_Additional_Info'} onChange={handleChange} value={inputs.Additional_Info} hint={'additional-info-hint'}></InfogoTextArea>
                
                
            
            </fieldset>
            
            <fieldset className="ontario-fieldset">
                <legend className="ontario-fieldset__legend ontario-fieldset__legend--large">
                  <h2 className='ontario-h3 content-title'>{t('coordinator-info')}</h2>
                </legend>
                <InfogoInput className={"ontario-input--20-char-width"} ref={coorname_input} label={'coordinator-name'} name={'Coordinator_Name'} id={'txt_Coordinator_Name'} readOnly={true}></InfogoInput>
                <InfogoInput className={"ontario-input--20-char-width"} ref={coorphone_input} label={'coordinator-phone'} name={'Coordinator_Phone'} id={'txt_Coordinator_Phone'} readOnly={true}></InfogoInput>
                <input ref={cooremail_input} className="ontario-input" name="Coordinator_Email" type="hidden" 
                id="Coordinator_Email"               
                />
            </fieldset>

            <fieldset className="ontario-fieldset">
                <legend className="ontario-fieldset__legend ontario-fieldset__legend--large">
                  <h2 className='ontario-h3 content-title'>{t('submitter-info')}</h2>
                </legend>
                <InfogoInput className={"ontario-input--20-char-width"} value={sub_name} label={'submitter-name'} name={'Submitter_Name'} id={'txt_Submitter_Name'} readOnly={true}></InfogoInput>
                <InfogoInput className={"ontario-input--20-char-width"} value={sub_em} label={'submitter-email'} name={'Submitter_Email'} id={'txt_Submitter_Email'} readOnly={true}></InfogoInput>
            </fieldset>
            <p className="ontario-hint" id="submit-hint">{t('submit-hint')}</p><br />
            <button className="ontario-button ontario-button--primary" onClick={submitOnClick} type="submit">
              {t('submit')}
            </button>                  
            <button className="ontario-button ontario-button--secondary" onClick={resetOnClick} type="reset">
              {t('reset')}
            </button>      
          </div>
        </form>     
  
        </>
      )
    }
    else{
      return(<></>)
    }
    
  }

  export default memo(AddEmployeeForm)