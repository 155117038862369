import { useEffect, useState } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import {toggleDropdownContent} from '../../js/ui'
import { loginRequest } from "../../authConfig";
import {SilentRequestAccessToken} from "../Azure/AzureRequestToken"
import { logoutRequest } from "../../authConfig";


function IntranetMenu () { 
    const { t } = useTranslation();
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState(null);
  
    const name = accounts[0] && accounts[0].name;
    //console.log(accounts[0]);

    const dropdownBtnClick = () => {      
        //toggleDropdownContent('ontario-header-menu-toggler', 'msal-toggler-dropdown', 1);
    }

    return (
        <>
        {/* 
        <div className='ontario-application-subheader__menu-container dropdown sort'>                    
            <button className="ontario-header__menu-toggler ontario-header-button ontario-header-button--with-outline" id="ontario-header-user-name" aria-controls="ontario-navigation" aria-label="open menu" type="button"
            onClick={dropdownBtnClick}>
                <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use xlinkHref="/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-account"></use>
                </svg>
                <span className='ontario-hide-for-small-only header-login-name'>{name}</span>                
            </button>            
        </div>*/
        }
        <div className='ontario-application-subheader__menu-container'>                    
            <div className="header-login-name-div" id="ontario-header-user-name">
                <svg className="ontario-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use xlinkHref="/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-account"></use>
                </svg>
                <span className='ontario-hide-for-small-only header-login-name' aria-hidden="true" title={t('logged-in-as') + name}>{name}</span>
                <span className='ontario-show-for-sr'>{t('logged-in-as') + name}</span>                
            </div>            
        </div>
        </>
    );
    
/*
    return (
            
                <div className='ontario-application-subheader__menu-container dropdown sort'>                    
                    <button className="ontario-header__menu-toggler ontario-header-button ontario-header-button--with-outline" id="ontario-header-menu-toggler" aria-controls="ontario-navigation" aria-label="open menu" type="button"
                    onClick={dropdownBtnClick}>
                        <svg className="ontario-icon" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use xlinkHref="/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-account"></use>
                        </svg>
                        <span className='ontario-hide-for-small-only'>{name}</span>
                        <svg className="ontario-icon" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" style={{display:"none"}}>
                            <use xlinkHref="#ontario-icon-chevron-down"></use> 
                        </svg> 
                    </button>
                    <div id="msal-toggler-dropdown" className="dropdown-content">
                        <SignOutButton></SignOutButton>
                    </div>
                </div>
        
    );*/
  };


function signOutClickHandler(instance, homeAccountId) {
    
    var my_logoutRequest = logoutRequest;
    my_logoutRequest.account = instance.getAccountByHomeId(homeAccountId);
    
    instance.logoutRedirect(my_logoutRequest);
}

function SignOutButton() {
    const { t } = useTranslation();
    const { instance, accounts, inProgress } = useMsal();
    var homeAccountId = accounts[0].homeAccountId;
    return (
        <a onClick={() => signOutClickHandler(instance, homeAccountId)} href="/#">
            <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use xlinkHref="/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-account"></use>
            </svg>
            {t('sign-out')}
        </a>
    );
}

export default(IntranetMenu)