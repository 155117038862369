import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import ODS_HEADER from '../../components/Header.tsx';
import ODS_FOOTER from '../../components/Footer.js';
import {GetCurrentQuery} from '../../js/utils';
import ShowUpdateEmployeeSearchResults from "../../components/Intranet/UpdateEmployeeSearchResults.tsx";
import StepIndicator from '../../components/StepIndicator.tsx';
// @ts-ignore
import BreadCrumbs from '../../components/BreadCrumb.tsx'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { formatPageTitle} from "../../js/utils"
const UpdateEmpResultsPage = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    let current_query= GetCurrentQuery();
    var title=t('title-update-employee');

    
    document.documentElement.lang=(i18n.language);
    
    if(current_query.updatetype=="EditEmp"){
        title=t('title-edit-employee');
    }
    else if(current_query.updatetype=="DeleteEmp"){
        title=t('title-delete-employee');
    }
    else if(current_query.updatetype=="MoveEmp"){
        title=t('title-move-employee');
    }
    document.title = formatPageTitle(title, t);

    const [currLocale, setCurrLocale] = useState(i18n.language);

    return (
        <>
        <AuthenticatedTemplate>        
          <div id="layout-header"><ODS_HEADER onLocaleChange={setCurrLocale} /></div>
          <noscript>{t('no-javascript')}</noscript>
          <main id="main-content" style={{"minHeight":"80%"}}>
            <BreadCrumbs>
            </BreadCrumbs>
              <div className="ontario-row">
                <div className="ontario-main-content ontario-small-12 ontario-columns ontario-margin-top-24-!">               
                    <div id="app-main">
                        <StepIndicator href={'forms'} title={title} label={'back-to-form'}></StepIndicator>
                        <div className="top-margin-2">                    
                        <ShowUpdateEmployeeSearchResults></ShowUpdateEmployeeSearchResults>
                        </div>
                    </div>
                </div>
              </div>
          </main>
          <div id="layout-footer" className='autoTopMargin'><ODS_FOOTER /></div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate></UnauthenticatedTemplate>
        </>
    );
};

export default UpdateEmpResultsPage;


