import React, { memo, useEffect } from 'react';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { useIsAuthenticated } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import {isIntranet} from '../js/ui'

import {GetLocalePath, GetPublicUrl, GetEnvVar, GetCurrentQuery, StringifyQueryObject} from '../js/utils';
import IntranetMenu from './Intranet/IntranetMenu';
import {AutoSignInButton} from './Azure/AzureLogin'

function ODS_HEADER(props:{ onLocaleChange:Function }){
    const isAuthenticated=useIsAuthenticated();

    const location = useLocation();
    const navigate = useNavigate();

    const searchFormContainer = useRef(null);
    const searchToggler = useRef(null);
    const searchClose = useRef(null);

    const { t } = useTranslation();
    const { i18n } = useTranslation();
    var cl = i18n.language;
    const [currentlanguage, setCurrentLanguage] = useState(cl);
    const [inputs, setInputs] = useState({ 'search': '' });
    
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
        //console.log(inputs);    
      }

    let current_query = GetCurrentQuery();
    function changeLanguage(e) {
        e.preventDefault();
        /*
        i18n.changeLanguage(e.target.attributes.value.value);
        //console.log(e);
        //console.log(e.target.attributes.value)
        setCurrentLanguage(i18n.language);
        //console.log(cl);
        if(props.onLocaleChange!== undefined){
            props.onLocaleChange(i18n.language);
            console.log(i18n.language);
        }*/
        var loc = e.target.attributes['data-value'].value;
        var current_page = location.pathname;
        

        if(loc=='fr'){
            if(current_query.hasOwnProperty('locale')){
                current_query.locale='fr';
            }
            var qs = StringifyQueryObject(current_query)
            //if(location.pathname!="")
            //{
                navigate({
                    pathname: '/fr'+location.pathname,
                    search: qs
                });
            /*}
            else {
                navigate({
                    pathname: '/fr/index',
                    search: location.search
                });
            }*/
        }
        else{
            var x = location.pathname.replace(/^\/fr\//, '');
            if(current_query.hasOwnProperty('locale')){
                current_query.locale='en';
            }
            var qs = StringifyQueryObject(current_query)
            navigate({
                pathname: "/"+location.pathname.replace(/^\/fr\//, ''),
                search: qs
            });
        }

        if(props.onLocaleChange!== undefined){
            props.onLocaleChange(i18n.language);
            console.log(i18n.language);
        }

    }
    /*
    const searchOnClick = (event) => {
        event.preventDefault();
        if(props.searchOnClickCallback!==undefined){
            props.searchOnClickCallback(inputs.search.trim());
        }        
      }

    const searchTogglerOnClick = () => {
        var searchOpenClass = "ontario-header--search-open";
        searchFormContainer.current.classList.toggle(searchOpenClass);
        searchToggler.current.classList.toggle(searchOpenClass);
        searchClose.current.classList.toggle(searchOpenClass);
    }
    */
    useEffect(() => {
        if(location.pathname.toString().toUpperCase().startsWith('/FR/') && cl!='fr'){
            i18n.changeLanguage('fr');
            setCurrentLanguage(i18n.language);
        }
        else if(!location.pathname.toString().toUpperCase().startsWith('/FR/') && cl!='en'){
            i18n.changeLanguage('en');
            setCurrentLanguage(i18n.language);
        }
        
    });

    
    

return(
<>
<a href="#main-content" className="ontario-show-on-focus skip-main" lang={cl}>{t('skip-to-main')}</a>
<div className="ontario-header__container" role="banner" lang={cl}>
      <header  className="ontario-application-header ontario-header" id="ontario-header">
          <div className="ontario-row">
              <div className="ontario-columns ontario-small-6 ontario-application-header__logo">
                  <a href={t('ontario-page-link')}>
                      <img src={GetPublicUrl() + '/ontario-design-system/logos/ontario-logo--desktop.svg'} alt="Government of Ontario" />
                  </a>
              </div>
              <div className="ontario-columns ontario-small-6 ontario-application-header__lang-toggle">
                { 
                    !isIntranet()?               
                    <LangToggleButton currentlanguage={currentlanguage} changeLanguage={changeLanguage}></LangToggleButton>
                    :
                    <></>
                }
              </div>
          </div>
             
      </header>
      
      <div className="ontario-application-subheader-menu__container">
          <section className="ontario-application-subheader">
              <div className="ontario-row">
                  <div className="ontario-columns ontario-small-12 ontario-application-subheader__container">
                        <p className="ontario-application-subheader__heading">
                            {
                                location.pathname=="" || location.pathname=="/" || location.pathname=="/fr/"?
                                <Link to={cl=='fr'?'/fr/':'/'} onClick={() => window.location.reload()}>{isIntranet()?t('info-go-intranet'):t('info-go')} {GetEnvVar('REACT_APP_TEST')}</Link>
                                :
                                <Link to={cl=='fr'?'/fr/':'/'}>{isIntranet()?t('info-go-intranet'):t('info-go')} {GetEnvVar('REACT_APP_TEST')}</Link>
                            }                            
                        </p>
                        {isIntranet()?/* FOR NOW PUBLIC HAS NO USE FOR subheader menu items */
                        <div className="ontario-application-subheader__menu-container">
                        
                        {// @ts-ignore
                        /*
                        false==true?<>
                        <form ref={searchFormContainer} name="searchForm" id="ontario-search-form-container" onSubmit={(e)=>{e.preventDefault();}}  className="ontario-header__search-container ontario-columns ontario-small-10 ontario-medium-offset-3 ontario-medium-6 ontario-large-offset-0 ontario-large-6">
                            <label htmlFor="ontario-search-input-field" className="ontario-show-for-sr">{t('search')}</label>
                            <input 
                            type="text" 
                            name="search" 
                            id="ontario-search-input-field"
                            autoComplete="off" 
                            aria-autocomplete="none" 
                            className="ontario-input ontario-header__search-input"
                            maxLength={100}
                            required={true}
                            value={inputs.search || ""} 
                            onChange={handleChange}
                            />
                            
                            <input className="ontario-header__search-reset" id="ontario-search-reset" type="reset" value="" aria-label="Clear field" />
                            <button className="ontario-header__search-submit" id="ontario-search-submit" type="submit" onClick={searchOnClick}>
                                <span className="ontario-show-for-sr">{t('submit')}</span>
                                <svg className="ontario-icon" aria-hidden="true" focusable="false"  viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use xlinkHref="#ontario-icon-search"></use>
                                </svg>
                            </button>
                        </form>
                        <div className="ontario-application-subheader__menu-container">
                            
                            <button ref={searchToggler} className="ontario-header__search-toggler ontario-header-button ontario-header-button--without-outline ontario-hide-for-large" id="ontario-header-search-toggler" aria-controls="ontario-search-form-container" type="button" >
                                <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use xlinkHref="#ontario-icon-search"></use>
                                </svg>
                                <span className="ontario-show-for-medium ontario-show">
                                    {t('search')}
                                </span>
                            </button>
                            <button ref={searchClose} className="ontario-header__search-close ontario-header-button ontario-header-button--without-outline" id="ontario-header-search-close" aria-label="close search bar" type="button">
                                <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use xlinkHref="#ontario-icon-close"></use>
                                </svg>
                                <span aria-hidden="true">{t('close')}</span>                                
                            </button>                          
                            
                        </div></>
                        :<></>*/
                        }
                        
                        {
                            isIntranet()?<>
                            <ul className='ontario-application-subheader__menu ontario-hide-for-small'>
                                <AuthenticatedTemplate>
                                    <li>
                                    <Link to={GetLocalePath(i18n.language)+'forms'}>
                                    <svg className="ontario-icon ontario-show-for-small-only" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                        <use xlinkHref="/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-edit"></use> 
                                    </svg>
                                    <span className='ontario-hide-for-small-only'>{t('employee-change-form')}</span>
                                    </Link>
                                    </li>                                                        
                                </AuthenticatedTemplate>
                            </ul>
                            <AuthenticatedTemplate>
                                <IntranetMenu></IntranetMenu>
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <AutoSignInButton loginType="redirect"></AutoSignInButton>
                            </UnauthenticatedTemplate>    
                            </>
                            :
                            <></>
                        }                
                        
                        </div>:<></>
                        }    
                  </div>
              </div>
              <div className="ontario-row">
                <div className="ontario-columns ontario-small-12 ontario-application-subheader__container">                
                    <p className="ontario-application-subheader__subtext ontario-h6">{t('appname')}</p>                    
                </div>
              </div>
                
          </section>
          {/*
          <nav className="ontario-navigation" id="ontario-navigation">
              <button className="ontario-header__menu-toggler ontario-header-button ontario-header-button--with-outline" id="ontario-header-nav-toggler" aria-controls="ontario-navigation" aria-label="close">
                  <svg className="ontario-icon" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                      <use xlinkHref="#ontario-icon-close"></use>
                  </svg>
                  <span>Menu</span>
              </button>
              <div className="ontario-navigation__container">
                  <ul>
                          <li className="ontario-show-for-small-only"><a href="#">Link
                                  1</a></li>
                          <li className="ontario-show-for-small-only"><a href="#">Link
                                  2</a></li>
                          <li className="ontario-hide-for-large"><a href="#">Link
                                  3</a></li>
                          <li className="ontario-hide-for-large"><a href="#">Link
                                  4</a></li>
                          <li className="ontario-hide-for-large"><a href="#">Link
                                  5</a></li>
                          <li><a href="#">Link 6</a></li>
                          <li><a href="#">Link 7</a></li>
                          <li><a href="#">Link 8</a></li>
                  </ul>
              </div>
          </nav>*/
          }
      </div>
</div>
</>

)

}




function LangToggleButton(props){

    const linkOnEnter = (event) =>{
        if(event.keyCode === 13){
            props.changeLanguage(event);
        }
      }

    if(props.currentlanguage==='fr'){
        return(        
            <a href="/#" className="ontario-header__language-toggler ontario-header-button ontario-header-button--without-outline" onClick={props.changeLanguage} data-value='en' tabIndex={0} onKeyDown={linkOnEnter}>
                <abbr title="English" aria-label="English" className="ontario-show-for-small-only" data-value='en'>
                    EN
                </abbr>
                <span className="ontario-show-for-medium" data-value='en' lang='en'>
                    English
                </span>
            </a>
        )    
    }
    else{
        return(        
            <a href="/#" className="ontario-header__language-toggler ontario-header-button ontario-header-button--without-outline" onClick={props.changeLanguage} data-value='fr' tabIndex={0} onKeyDown={linkOnEnter}>
                <abbr title="Français" aria-label="Français" className="ontario-show-for-small-only" data-value='fr'>
                    FR
                </abbr>
                <span className="ontario-show-for-medium" data-value='fr' lang='fr'>
                    Français
                </span>
            </a>
        )
    }
      
}


export default ODS_HEADER