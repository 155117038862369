import { useState, useRef } from 'react';
import {createSearchParams, useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import {sanitizeString, GetLocalePath} from '../../js/utils';


// @ts-ignore
import PageAlert, {PageAlertRefObject} from '../PageAlert.tsx';


function HomeSearchForm(props:{showHeader:boolean}) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const { t } = useTranslation();
  
  const searchReset = useRef(null);
  const searchInput = useRef<HTMLInputElement>(null);

  let defaultSortLabel = t('sort-option0');
  const [inputs, setInputs] = useState({ 'searchtype': 1, 'searchkeyword': '' });
  
  const alert_ref = useRef<PageAlertRefObject>(null);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
    //console.log(inputs);    
  }
  
  const onReset = (event) =>{
    event.preventDefault();
    setInputs(values =>({...values, 'searchkeyword':''}));
    if(searchInput.current) searchInput.current.focus();
  }

  const searchOnClick = (event) => {
    event.preventDefault();
    var raw_keyword = inputs.searchkeyword?.trim();
    var cleaned_keyword = sanitizeString(raw_keyword); 
    var default_searchType=1;
    if(cleaned_keyword == null ||cleaned_keyword.length < 2){
      if(raw_keyword != null && raw_keyword.length >1)
        alert_ref.current?.Set('error-search-keyword-invalid');
      else
        alert_ref.current?.Set('error-search-keyword-blank');           
    }
    else{
      navigate({
        pathname: GetLocalePath(i18n.language)+"results",
        search: createSearchParams({
          keywords: cleaned_keyword,
          searchtype: default_searchType.toString()
        }).toString()
      });      
    }
  }

  const textboxEnter = (event) =>{
    if(event.keyCode === 13){
      searchOnClick(event);
    }
  }

  return (
    <>
    
    <PageAlert ref={alert_ref} alertType={'error'} alertHeader={t('error')}></PageAlert>
    <form onSubmit={(e)=>{e.preventDefault();}}  id="homesearch">
      {
        props.showHeader!=null && props.showHeader==true?
        <h1 className='ontario-h2'>{t('ontario-search-input-field-label')}</h1>
        :
        <></>
      }
      
      
      <div className="" style={{position:"relative", paddingLeft:"0", paddingRight: "0"}} role="search">
        <label htmlFor="ontario-search-input-field" className="ontario-show-for-sr">{t('search')}</label>
        <input
          type="text" 
          name="searchkeyword" 
          id="ontario-search-input-field"
          autoComplete="off" 
          aria-autocomplete="none" 
          className='ontario-input ontario-search-input info-go'
          maxLength={100}
          required={true}
          aria-required={true}
          value={inputs.searchkeyword || ""} 
          onChange={handleChange}
          onKeyDown={textboxEnter}
          ref={searchInput}
          title={t('search-hint')}
          role="searchbox"
        />
        <input className="ontario-search-reset" type="reset" value="" aria-label="Clear field" onClick={onReset} ref={searchReset} />
                            
        <button className="ontario-button--primary ontario-search-submit" type="submit" aria-label={t('search')} onClick={searchOnClick}>      
          <span className="search-name" aria-hidden="true">{t('search')}</span>
          <svg className="ontario-icon search-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use href="#ontario-icon-search"></use>
          </svg>      
        </button>  
      </div>     

    </form>    
    
    </>
  )

}



export default HomeSearchForm