
import React, { memo, useRef } from 'react';
import {useSearchParams, useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';
// @ts-ignore
import ODS_HEADER from '../components/Header.tsx';
import ODS_FOOTER from '../components/Footer.js';
// @ts-ignore
import SearchForm, {SearchFormRefObj} from '../components/CurrentVer/SearchForm.tsx';
// @ts-ignore

import BreadCrumbs from '../components/BreadCrumb.tsx'
import {formatPageTitle} from '../js/utils';
const ResultSearch = (props) => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    var cl = i18n.language;
    document.title = formatPageTitle(t('title-results'), t);
    document.documentElement.lang=(i18n.language);
    const [searchParams] = useSearchParams();
    searchParams.get("__firebase_request_key")
    if(searchParams.get('keywords') == null || searchParams.get('keywords')==''){
        navigate({
            pathname: "/"            
          });
    }

    //const [currLocale, setCurrLocale] = useState('');
    const searchForm_ref = useRef<SearchFormRefObj>(null);
    function searchFormButton_ForceClick(currLocale){
      window.location.reload();
      //searchForm_ref.current.ClickSearchButton();
    }


    return (
        <>        
          <div id="layout-header"><ODS_HEADER onLocaleChange={searchFormButton_ForceClick} /></div>
          <noscript>{t('no-javascript')}</noscript>
          <main id="main-content" className='' style={{"minHeight":"80%"}} tabIndex={-1} lang={cl}>
            <BreadCrumbs>
            </BreadCrumbs>
            <div className="ontario-row">
              <div className="ontario-main-content ontario-small-12 ontario-columns ontario-margin-top-24-!">                
                  <div id="app-main">
                  <SearchForm /*locale={currLocale}*/ ref={searchForm_ref}></SearchForm>
                  </div>
              </div>
            </div>
          </main>
          <div id="layout-footer" className='autoTopMargin'><ODS_FOOTER /></div>
        </>
    )
    
    
  };
    
  export default memo(ResultSearch);

