import React, { createContext, useContext } from 'react';
import { ReactNode } from 'react'
import { useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { appInsights } from "../AppInsights";
import { uniqueID } from "../js/utils"

interface IAccordionFunction{
    toggleFn?:any
}

export interface IAccordionItem{
    id?:string;
    children:ReactNode;
    header:string;
    scrollable?:boolean;
}

const AccordionContext = createContext<IAccordionFunction>({});
const Accordion = (props) =>{
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    //const arr = React.Children.toArray(props.children);
    
    /*************** OPEN/CLOSE Item***********************/
    function openItem(header, content, open:boolean){
        var button = header.getElementsByClassName('ontario-accordion__button');
        if(open){
            if(!header.classList.contains("ontario-expander--active"))
                header.classList.add("ontario-expander--active");
            if(!content.classList.contains("ontario-expander__content--opened"))
                content.classList.add("ontario-expander__content--opened");                
        }
        else{
            if(header.classList.contains("ontario-expander--active"))
                header.classList.remove("ontario-expander--active");
            if(content.classList.contains("ontario-expander__content--opened"))
                content.classList.remove("ontario-expander__content--opened");
        }

        header.classList.contains("ontario-expander--active")
            ? button[0].setAttribute("aria-expanded", "true")
            : button[0].setAttribute("aria-expanded", "false");
        content.classList.contains("ontario-expander__content--opened")
            ? content.setAttribute("aria-hidden", "false")
            : content.setAttribute("aria-hidden", "true");
    }
    /************** OPEN/CLOSE ALL ********************* */
    function areAllAccordionsOpened(){
        var accordion_item_count=ref.current?.getElementsByClassName('ontario-accordion').length;
        var open_count=ref.current?.getElementsByClassName('ontario-expander--active').length;
        var ctrl = ref.current?.getElementsByClassName('ontario-accordion__controls');
        var toggleAllBtn = ref.current?.getElementsByClassName('ontario-accordion__button--expand-all');
        
        if(accordion_item_count==(open_count!=undefined?open_count:0)){            
            if(ctrl!=undefined && ctrl?.length>0){
                if(!ctrl[0].classList.contains('ontario-accordion__controls--active'))
                {ctrl[0].classList.add('ontario-accordion__controls--active');}
            }
            if(toggleAllBtn!=undefined && toggleAllBtn?.length>0){
                toggleAllBtn[0].setAttribute("aria-expanded", "true");
            }
        }
        else{
            if(ctrl!=undefined && ctrl?.length>0){
                if(ctrl[0].classList.contains('ontario-accordion__controls--active'))
                {ctrl[0].classList.remove('ontario-accordion__controls--active');}
            }
            if(toggleAllBtn!=undefined && toggleAllBtn?.length>0){
                toggleAllBtn[0].setAttribute("aria-expanded", "false");
            }
        }
    }
    /************* ON ITEM HEADER CLICK ************** */
    const toggleContent = (e) => {
        
        var header = e.target.closest('.ontario-accordion-heading');
        var container = e.target.closest('.ontario-accordion');        
        var content = container.getElementsByClassName('ontario-accordion__content');
        
        if(!header.classList.contains("ontario-expander--active"))
            openItem(header, content[0], true);
        else
            openItem(header, content[0], false);
        
        areAllAccordionsOpened();
        
        appInsights.trackEvent({ name: "Org Accordian Action", properties: { action: (header.classList.contains("ontario-expander--active") ? "open" : "close") + " item", item: container.id } });
    }
    /************* EXPAND ALL BUTTON CLICK *********************** */
    const toggleAll = (e) => {
        e.stopPropagation();
        var container = e.target.closest('.ontario-accordion__controls');
        var button = container.getElementsByClassName('ontario-accordion__button--expand-all');
        container.classList.toggle("ontario-accordion__controls--active");
        container.classList.contains("ontario-accordion__controls--active")
        ? button[0].setAttribute("aria-expanded", "true")
        : button[0].setAttribute("aria-expanded", "false");
        var open = container.classList.contains("ontario-accordion__controls--active")
        ?true:false

        var accordionItems = ref.current?.getElementsByClassName('ontario-accordion');
        Array.prototype.forEach.call(accordionItems, function(el) {
            var header = el.getElementsByClassName('ontario-accordion-heading');
            var content = el.getElementsByClassName('ontario-accordion__content');
            openItem(header[0], content[0], open);
        });
        
        appInsights.trackEvent({ name: "Org Accordian Action", properties: { action: (open ? "expand" : "collapse") + " all" } });
    }
    
    return (
        <AccordionContext.Provider value={{'toggleFn':toggleContent}}>
        <div ref={ref} className='ontario-accordions__container'>
          <div className="ontario-accordions__container">
            <div className="ontario-accordion__controls">
              <button className="ontario-accordion__button--expand-all" aria-expanded="false" onClick={(e) => (toggleAll(e))}>
                <span className="ontario-accordion--expand-open-all">{t('expand-all')}</span>
                <span className="ontario-accordion--expand-close-all">{t('collapse-all')}</span>
              </button>
            </div>
            
          </div>
          {
              /*React.Children.map(arr, (acc_item, index) => {
                    if(React.isValidElement(acc_item)){
                        return(
                            <AccordionItem key={index}>
                                {acc_item}                        
                            </AccordionItem>
                        )
                    }
                    else{ return(<key={index}></>)}                  
              }
              )*/
              props.children
          }  
        </div>
        </AccordionContext.Provider>
      );

}

export const AccordionItem = (props:IAccordionItem) =>{
    const accordion_functions = useContext(AccordionContext);
    var index = props.id==null || props.id==''?uniqueID().toString() : props.id;
    var header = props.header;
    var sectionScrollable = props.scrollable;
    sectionScrollable=sectionScrollable!=null && sectionScrollable!=undefined && sectionScrollable==true?true:false;
    return(
        <div className="ontario-accordion">
            <h3 className="ontario-accordion-heading">
                <button className="ontario-accordion__button" id={"accordion-button-id-"+(index)}  aria-controls={"accordion-content-"+(index)}  aria-expanded="false" data-toggle="ontario-collapse" onClick={(e) => (accordion_functions.toggleFn(e))}>
                    <span className="ontario-accordion__button-icon--close">
                        <svg className="ontario-icon"  aria-hidden="true" focusable="false"  viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-chevron-up"></use>
                        </svg>
                    </span>
                    <span className="ontario-accordion__button-icon--open">
                        <svg className="ontario-icon"  aria-hidden="true" focusable="false"  viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-chevron-down"></use>
                        </svg>
                    </span>
                    {header}
                </button>
            </h3>
            <section className="ontario-accordion__content" id={"accordion-content-"+(index)}  aria-labelledby={"accordion-button-id-"+(index)}  aria-hidden="true" data-toggle="ontario-expander-content" style={{maxHeight: sectionScrollable?"500px":"unset", overflowY: sectionScrollable?"scroll":"unset"}}>
                {props.children}     
            </section>
        </div>
    )
}

export default (Accordion)

