import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
// @ts-ignore
import ODS_HEADER from '../components/Header.tsx';
import ODS_FOOTER from '../components/Footer.js';
import HomeSearchForm from '../components/CurrentVer/HomeSearchForm.tsx';
import StepIndicator from '../components/StepIndicator.tsx';
import { GetOrganization } from '../components/CurrentVer/APICalls';
import ShowSearchItemDetail from "../components/CurrentVer/SearchItemDetail.tsx";
import { scrollToTop, isIntranet} from '../js/ui';
import { formatPageTitle } from '../js/utils';
import BreadCrumbs from '../components/BreadCrumb.tsx'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

const OrgPage = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    
    document.title = formatPageTitle(t('title-org-page'), t);
    document.documentElement.lang=(i18n.language);
    const [currLocale, setCurrLocale] = useState(i18n.language);
    const [searchParams] = useSearchParams();
    
    
    let orgId = searchParams.get('id');
    let b = searchParams.get('_b');
    let a = null;
    if(b!=null && b!=''){
        a = atob(b);        
    }    

    return (
        <>        
          <div id="layout-header"><ODS_HEADER onLocaleChange={setCurrLocale} /></div>
          <noscript>{t('no-javascript')}</noscript>
          <main id="main-content" className='' style={{"minHeight":"80%"}} tabIndex="-1" lang={currLocale}>
            <BreadCrumbs>                        
            </BreadCrumbs>
            <div className="ontario-row">
                <div className="ontario-main-content ontario-small-12 ontario-columns ontario-margin-top-24-!">                
                    <div id="app-main">
                        <HomeSearchForm></HomeSearchForm>
                        <StepIndicator href={b!=null && b!=''?'results':'index'} title={'title-org-page'} search={a} label={b!=null && b!=''?'back-to-results':'back-to-home'} hideBackLink={b!=null && b!=''?false:true}></StepIndicator>
                        {
                            isIntranet()?
                            <>
                            <AuthenticatedTemplate>
                                <PrepareOrgPage orgId={orgId}></PrepareOrgPage>
                            </AuthenticatedTemplate> 
                            <UnauthenticatedTemplate></UnauthenticatedTemplate> 
                            </>
                            :
                            <PrepareOrgPage orgId={orgId}></PrepareOrgPage>
                        }
                    </div>
                </div>
            </div>
          </main>
          <div id="layout-footer" className='autoTopMargin'><ODS_FOOTER /></div>
        </>
        
    );
};
  
const PrepareOrgPage = (props) => 
{
    const [currentorganization, setCurrentorganization] = useState({});

    const handleQueryChange = async (p) => {
        var u = await GetOrganization(p);
        if(u!=null){
            setCurrentorganization(u);
            scrollToTop();  
        }
    }  

    useEffect(() => {
        handleQueryChange(props.orgId);
    }, [props.orgId])

    return(
        <ShowSearchItemDetail currentitem={currentorganization}></ShowSearchItemDetail>
    )
}
  

export default OrgPage;


