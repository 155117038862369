import React from "react";
import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

const AppInsightsContextProvider = ({ children }) => {
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <AppInsightsErrorBoundary onError={() => (console.log('Error on application insights occurred'))} appInsights={reactPlugin}>
            { children }
            </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
    );
};
export { AppInsightsContextProvider };