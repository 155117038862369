export function scrollToTop(){
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
}

export function confirm_dialog(message) {
    return window.confirm(message);
}

//Spinnder
export function showLoadingSpinner(show){
  var spinner_container = document.getElementById('loading_spinner');//insider spinner component
  if(show){
    spinner_container.classList.add('flex');
    spinner_container.setAttribute('aria-hidden', false);
  }
  else{
    spinner_container.classList.remove('flex');
    spinner_container.setAttribute('aria-hidden', true);
  }
}


  
//-------------- DROP DOWN BUTTON deprecated, now contained in DropdownButton Component
//Old
/*export function toggleDropdownContent(buttonId, contentId, svg_index=0) {
  var dd_content = document.getElementById(contentId);  
  var parent = document.getElementById(buttonId);  
  //dd_content.classList.toggle("show");

  var closeDropdownContent = function(){
    dd_content.classList.remove("show");
    updateChevron('#ontario-icon-chevron-down');
    console.log("close");
  }

  var openDropdownContent = function(){
    dd_content.classList.add("show");
    updateChevron('#ontario-icon-chevron-up');
  }

  var updateChevron = function(icon){
    if(svg_index>=0){
        var svg = parent.getElementsByTagName("svg")[svg_index];
        var u = parent.getElementsByTagName("use")[svg_index];
        u.setAttribute("xlink:href", icon);        
      }
  }

  if(dd_content.classList.contains('show')){
    closeDropdownContent();
    //document.removeEventListener("click", closeDropdownContent);
  }
  else{
    openDropdownContent();
    //document.addEventListener("click", closeDropdownContent);
  }

}*/
/*
  export function toggleDropdownContent(e) {
    e.stopPropagation();
    var container = e.target.closest('.dropdown');
        
    var closeDropdownContent = function(){
      container.classList.remove("open");
    }
  
    var openDropdownContent = function(){
      container.classList.add("open");
      /*var content = container.getElementsByClassName("dropdown-content");
      if(content!=null && content.length>0){
        
        var contentItem = content[0].getElementsByTagName("a")[0];
        contentItem.focus();
      }*/
/*
    }
  
    if(container.classList.contains('open')){
        closeDropdownContent();
    }
    else{
        openDropdownContent();
    }   
  
  }

  export function onBlurDropdownContent(e){
    e.stopPropagation();
    if (!e.currentTarget.contains(e.relatedTarget)) {
        e.target.closest('.dropdown').classList.remove("open"); 
    }       
  }
*/
  

//------------ TABS
export function tablistTabOnClick(e){
  var tl = e.closest('.tab-list');
  //console.log(tl);
  var active_tabs = tl.getElementsByClassName('is-active');
  //console.log(active_tab);
  [].forEach.call(active_tabs, function (el) { el.classList.remove("is-active"); el.setAttribute("aria-selected", false); });
  
  
  var tab = e.closest('.tab-list-tab');
  tab.classList.add("is-active");
  tab.setAttribute("aria-selected", true);
}

export function tablistTabOnKeyUp(e){
    var tl = e.target.closest('.tab-list');
    var TABS = tl.getElementsByTagName('button');
    var currentTabIndex = 0;
    var lastTabIndex = TABS.length-1;
    //console.log(e.keyCode);

    for (var i = 0; i < TABS.length; i++) {
        var a = e.target.getAttribute('data-order');
        var b = TABS[i].getAttribute('data-order');
        
        if(a == b){
            currentTabIndex=i;
            break;
        } 
    }
    if (e.keyCode == 37 || e.keyCode == 38) {
        if (currentTabIndex === 0) {
          e.preventDefault();
          TABS[lastTabIndex].focus(); 
        } 
        else {
          e.preventDefault();
          TABS[currentTabIndex-1].focus();
        }
    }
    else if (e.keyCode == 39 || e.keyCode == 40) {
        if (currentTabIndex === lastTabIndex) {
            e.preventDefault();
            TABS[0].focus();
        } 
        else {
            e.preventDefault();
            TABS[currentTabIndex+1].focus();
        }
    }
  }

//----------- ACCORDION ---- Deprecated, combined into Accordion.tsx & InfogoHintExpander.tsx components
// js file is in public/otario-design-system/scripts/ontario-expand-collapse.js
/*
export function initalizeAccordion(){
  var expandableItems = Array.prototype.slice.call(
    document.querySelectorAll("[data-toggle='ontario-collapse']")
  );
  var expandAllButtons = Array.prototype.slice.call(
      document.querySelectorAll(".ontario-accordion__button--expand-all")
  );
  var accordionLinks = Array.prototype.slice.call(
      document.querySelectorAll(".ontario-accordion-link")
  );

  toggleExpander(expandableItems);
  toggleAllAccordions(expandAllButtons);
  toggleExpanderByLink(accordionLinks); 
}

function findElement(arr, callback) {
  for (var i = 0; i < arr.length; i++) {
      var match = callback(arr[i]);
      if (match) {
          return arr[i];
      }
  }
}

function openAllAccordions(item, content) {
    item.classList.remove("ontario-expander--active");
    item.classList.add("ontario-expander--active");
    item.firstElementChild.setAttribute("aria-expanded", "true");

    content.forEach(function (item) {
        item.classList.remove("ontario-expander__content--opened");
        item.classList.add("ontario-expander__content--opened");

        item.setAttribute("aria-hidden", "false");
    });
}

function closeAllAccordions(item, content) {
    item.classList.remove("ontario-expander--active");
    item.firstElementChild.setAttribute("aria-expanded", "false");

    content.forEach(function (item) {
        item.classList.remove("ontario-expander__content--opened");

        item.setAttribute("aria-hidden", "true");
    });
}

function toggleExpandAllButton(accordionExpandAllButton) {
  // toggle expand all button class
    accordionExpandAllButton.parentNode.classList.toggle(
    "ontario-accordion__controls--active"
    );

// toggle aria-expanded value of expand all button class if it contains active class
    accordionExpandAllButton.parentNode.classList.contains(
    "ontario-accordion__controls--active"
    )
    ? accordionExpandAllButton.setAttribute("aria-expanded", "true")
    : accordionExpandAllButton.setAttribute("aria-expanded", "false");
}

function allAccordionsOpenCheck() {
  // get all open accordions
    var openExpanders = document.getElementsByClassName(
        "ontario-expander--active"
    );

    // get total accordions
    var accordionContainers = Array.prototype.slice.call(
        document.querySelectorAll(".ontario-accordion")
    );

    if (openExpanders.length === accordionContainers.length) {
        toggleExpandAllButton(
            document.getElementsByClassName(
                "ontario-accordion__button--expand-all"
            )[0]
        );
    }

    if (openExpanders.length === 0) {
        var accordionExpandAllButton = document.getElementsByClassName(
            "ontario-accordion__button--expand-all"
        )[0];

        accordionExpandAllButton.setAttribute("aria-expanded", "false");

        accordionExpandAllButton.parentElement.classList.remove(
            "ontario-accordion__controls--active"
        );
    }
}

function toggleExpanderByLink(links, expandableItems) {
    var accordions = Array.prototype.slice.call(
        document.querySelectorAll(".ontario-accordion")
    );

    links.forEach(function (link) {
        link.addEventListener("click", function () {
            var id = this.hash.substring(1);

            var relatedAccordion = findElement(
                accordions,
                function (accordion) {
                    return accordion.id === id;
                }
            );

            relatedAccordion.classList.add("ontario-expander--active");

            window.setTimeout(function () {
                relatedAccordion.firstElementChild.focus();
            }, 0);

            var content = relatedAccordion.querySelector(
                "[data-toggle='ontario-expander-content']"
            );

            content.classList.add("ontario-expander__content--opened");
            content.setAttribute("aria-hidden", "false");
            relatedAccordion.setAttribute("aria-expanded", "true");
        });
    });
}

function toggleExpander(expanders) {
    expanders.forEach(function (item, index) {
        item.addEventListener("click", function () {
            item.parentNode.classList.toggle("ontario-expander--active");
            var content = item.parentNode.parentNode.querySelector(
                "[data-toggle='ontario-expander-content']"
            );

            content.classList.toggle("ontario-expander__content--opened");
            content.classList.contains("ontario-expander__content--opened")
                ? content.setAttribute("aria-hidden", "false")
                : content.setAttribute("aria-hidden", "true");

            this.parentNode.classList.contains("ontario-expander--active")
                ? this.setAttribute("aria-expanded", "true")
                : this.setAttribute("aria-expanded", "false");

            // if expander is an accordion
            if (
                document.getElementsByClassName(
                    "ontario-accordion__button--expand-all"
                ).length
            ) {
                allAccordionsOpenCheck();
            }
        });
    });
}

function toggleAllAccordions(expandAllButton) {
    expandAllButton.forEach(function (button, index) {
        button.addEventListener("click", function () {
            var accordionContainer = this.parentNode.parentNode;
            var accordionExpandAllButton = this;
            var accordionItems = Array.prototype.slice.call(
                accordionContainer.querySelectorAll(".ontario-accordion-heading")
            );

            toggleExpandAllButton(accordionExpandAllButton);

            // loop over every accordion item, add correct classes + aria attribuutes if the expand all button is active or not
            accordionItems.forEach(function (item, index) {
                var content = Array.prototype.slice.call(
                    item.parentNode.querySelectorAll(
                        "[data-toggle='ontario-expander-content']"
                    )
                );

                accordionExpandAllButton.parentNode.classList.contains(
                    "ontario-accordion__controls--active"
                )
                    ? openAllAccordions(item, content)
                    : closeAllAccordions(item, content);
            });
        });
    });
}
*/

//ENV STUFF

export function isIntranet(){
    var X = process.env.REACT_APP_IS_INTRANET;
    if(X && X=='YES'){
        return true;
    }
    return false;
}