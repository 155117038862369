import React, { useState, useEffect } from 'react';
import {useSearchParams} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {useNavigate} from 'react-router-dom';
// @ts-ignore
import ODS_HEADER from '../components/Header.tsx';
import ODS_FOOTER from '../components/Footer.js';
// @ts-ignore
import HomeSearchForm from '../components/CurrentVer/HomeSearchForm.tsx';
// @ts-ignore
import StepIndicator from '../components/StepIndicator.tsx';
import { GetEmployee, GetOrganization } from '../components/CurrentVer/APICalls';
// @ts-ignore
import ShowSearchItemDetail from "../components/CurrentVer/SearchItemDetail.tsx";
import {scrollToTop, isIntranet} from '../js/ui';
import {formatPageTitle} from '../js/utils';
// @ts-ignore
import BreadCrumbs from '../components/BreadCrumb.tsx'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

const EmpPage = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    
    document.title = formatPageTitle(t('title-emp-page'), t);
    document.documentElement.lang=(i18n.language);
    const [currLocale, setCurrLocale] = useState(i18n.language);
    const [searchParams] = useSearchParams();

    let empId = searchParams.get('id');
    let b = searchParams.get('_b');
    let a = null;
    if(b!=null && b!=''){
        a = atob(b);        
    }

    
    

    return (
        <>        
          <div id="layout-header"><ODS_HEADER onLocaleChange={setCurrLocale} /></div>
          <noscript>{t('no-javascript')}</noscript>
          <main id="main-content" className='' style={{"minHeight":"80%"}} tabIndex="-1" lang={currLocale}>
            <BreadCrumbs>
            </BreadCrumbs>
            <div className="ontario-row">
                <div className="ontario-main-content ontario-small-12 ontario-columns ontario-margin-top-24-!">                
                    <div id="app-main">
                        <HomeSearchForm></HomeSearchForm>
                        <StepIndicator href={b!=null && b!=''?'results':'index'} title={'title-emp-page'} search={a} label={b!=null && b!=''?'back-to-results':'back-to-home'} hideBackLink={b!=null && b!=''?false:true}></StepIndicator>
                        {
                            isIntranet()?
                            <>
                            <AuthenticatedTemplate>
                                <PrepareEmpPage empId={empId}></PrepareEmpPage>
                            </AuthenticatedTemplate> 
                            <UnauthenticatedTemplate></UnauthenticatedTemplate> 
                            </>
                            :
                            <PrepareEmpPage empId={empId}></PrepareEmpPage>
                        }
                        
                    </div>
                </div>
            </div>
          </main>
          <div id="layout-footer" className='autoTopMargin'><ODS_FOOTER /></div>
        </>
        
    );
};

const PrepareEmpPage = (props) =>
{
    
    const [currentemployee, setCurrentemployee] = useState({});

    const handleQueryChange = async (p) => {
        var u = await GetEmployee(p);
        //var l = await GetRefreshHistoryLatest();
        if(u!=null){
            if(u.head==true && u.associatedOrg!=null && u.associatedOrg.orgId !=null){
                var o = await GetOrganization(u.associatedOrg.orgId);
                u.detailedOrg = o;
                setCurrentemployee(u);
            }
            else{
                setCurrentemployee(u);
            }
            scrollToTop();           
        }
    }  

    useEffect(() => {
        handleQueryChange(props.empId);
    }, [props.empId])

    return(
        <ShowSearchItemDetail currentitem={currentemployee}></ShowSearchItemDetail>
    )
}


export default EmpPage;


