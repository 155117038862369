import { internals } from "@azure/msal-browser";

export interface ISearchFormVM {
  setShowresults?: any;
  setShowdetails?: any;
  setSearchparams?: any; 
  setFounditems?: any;  
}

export interface ISearchParameters {
  keywords?: string;
  searchtype: number;
  job?: number;
  locale?: string;
  sortdir?: string;
  sortcol?: string;
  sortlabel?: string;
  page?:number;
  toporg?:number;
  org?:number;
  category?:number;
}

export const newISearchParameters: ISearchParameters = {
  
  searchtype: 1,
  //job: 0,
  sortdir: 'asc',
  sortcol: 'Rank',
  //sortlabel:'',
  toporg:0,
  page:1
}

export interface IValidSearchParameters {
  keywords: string;
  searchtype: string;
  job: string;
  locale: string;
  sortdir: string;
  sortcol: string;
  sortlabel: string;
  page:string;
}

export const newIValidSearchParameters: IValidSearchParameters = {
  keywords: 'keywords',
  searchtype: 'searchtype',
  job: 'job',
  locale: 'locale',
  sortdir: 'sortdir',
  sortcol: 'sortcol',
  sortlabel:'sortlabel',
  page:'page'
}

export interface ISearchItemDetailVM{
  handlePrint?: any;
  handleEmployeeClick?: any;
  handleOrganizationClick?: any;
  b?: any;
}

export interface ISearchParametersForUpdate {
  lastname?: string;
  firstname?: string;  
}

