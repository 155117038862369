import React, { useEffect } from 'react';
import { createSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
// @ts-ignore
import ODS_HEADER from '../components/Header.tsx';
import ODS_FOOTER from '../components/Footer.js';
import HomeSearchForm from '../components/CurrentVer/HomeSearchForm.tsx';
import TopLevelOrganizationAccordion from '../components/CurrentVer/CategoryAccordion.tsx'
import { formatPageTitle } from '../js/utils.js';
import { isIntranet } from '../js/ui.js';
import BreadCrumbs from '../components/BreadCrumb.tsx'
import { AutoSignInButton } from '../components/Azure/AzureLogin.js'
import InfogoHintExpander from '../components/InfogoHintExpander.tsx'

const Home = (props) => {
  return (
    isIntranet()
      ?
      <><AuthenticatedTemplate>
        <Home2></Home2>
      </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <AutoSignInButton loginType="redirect"></AutoSignInButton>
        </UnauthenticatedTemplate>
      </>
      :
      <Home2></Home2>
  )
}


const Home2 = (props) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  var cl = i18n.language;
  var location = useLocation();
  document.title = formatPageTitle(t('title-home'), t);
  document.documentElement.lang = (cl);
  const searchOnClickCallback = (searchkeyword) => {
    if (searchkeyword == null || searchkeyword.trim() === '') {
      //alert(t('error-search-keyword-blank'));
      //setAlertMessages(values => ([...values, t('error-search-keyword-blank')]));
    }
    else {

      navigate({
        pathname: "results",
        search: createSearchParams({
          keywords: searchkeyword,
          locale: i18n.language
        }).toString()
      });

    }
  }

  useEffect(() => {
    if (location.pathname.toString().toUpperCase().startsWith('/FR/') && cl != 'fr') {
      i18n.changeLanguage('fr');
    }
    else if (!location.pathname.toString().toUpperCase().startsWith('/FR/') && cl != 'en') {
      i18n.changeLanguage('en');
    }

  }, []);

  const actions = ["employee-help", "organization-help"];
  var hintItems = [];
  actions.map((item, index) => {
    hintItems.push({ description: t(item + '-hint') })
  })
  return (
    <>
      <div id="layout-header"><ODS_HEADER searchOnClickCallback={searchOnClickCallback} /></div>
      <main className='' id="main-content" style={{ "minHeight": "80%" }} tabIndex="-1" lang={cl}>
        <BreadCrumbs>
        </BreadCrumbs>
        <div className="ontario-row">
          <div className="ontario-main-content ontario-small-12 ontario-columns ontario-margin-top-24-!">
            <div id="app-main">
              <section>
                <HomeSearchForm showHeader={true}></HomeSearchForm>
                <InfogoHintExpander id={'user-search-hint'} title={t('user-search-hint')}>
                  {hintItems.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <p>{item.description}</p>
                      </React.Fragment>
                    )
                  })}
                </InfogoHintExpander>
              </section>
              <hr />
              {
                isIntranet() ?
                  <AuthenticatedTemplate>
                    <section style={{ display: 'block' }}>
                      <TopLevelOrganizationAccordion header_title={t('browse-by-orgs')}></TopLevelOrganizationAccordion>
                    </section>
                  </AuthenticatedTemplate>
                  :
                  <section style={{ display: 'block' }}>
                    <TopLevelOrganizationAccordion header_title={t('browse-by-orgs')}></TopLevelOrganizationAccordion>
                  </section>
              }
            </div>
          </div>
        </div>
      </main>

      <div id="layout-footer" className='autoTopMargin'>
        {
          i18n.language == 'fr' ?
            <div className='ontario-row'>
              <div className='ontario-columns'>
                <div className="ontario-callout" style={{ marginTop: "4rem", marginBottom: "0px" }} role="complementary">
                  <p style={{ maxWidth: "unset" }}>Veuillez prendre note que les équivalents français des données qui se trouvent dans INFO-GO sont fournis à titre d’information seulement. Pour trouver les équivalents français à jour des noms d’organismes et des titres de poste, communiquez avec les terminologues du site <a href=" https://www.ontario.ca/fr/document/terminologie-du-gouvernement-de-lontario">ONTERM</a>.</p>
                </div>
              </div>
            </div>
            :
            <></>
        }
        <ODS_FOOTER />
      </div>
    </>
  )
};

export default Home;