import React from 'react';
import { useState} from 'react';
import { Link } from 'react-router-dom';

export interface ITreeNode
{
  key: string;
  title: string;
  children: ITreeNode[];
  expandedByDefault?: boolean;
  isRoot?: boolean;
  url?:string;
  collapsable?:boolean;
}
 
export function Tree({ treeData }) {
    return (
      <>
        {treeData.map((node) => (
          <TreeNode node={node} key={node.key} />
        ))}
      </>
    );
}

export function TreeNode({ node }) {
    const { children, title } = node;
    var expanded = node.expandedByDefault!=null && node.expandedByDefault==true?true:false;
    var isRoot = node.isRoot!=null && node.isRoot==true?true:false;
    var hasChildren = node.children != null && node.children.length>0?true:false;
    var hasLink = node.url!=null && node.url.length>0?true:false;
    var collapsable = node.collapsable!=null && node.collapsable==true?true:false;
    //const [showChildren, setShowChildren] = useState(false);
    //console.log(node);
    const handleClick = (event) => {
      //setShowChildren(!showChildren);
      var e = event.target;
      if(e.tagName!=="SVG"){
        e = e.closest('svg');
      }
      var svg_use = e.firstElementChild;
      
      var li = e.parentElement.closest('li');
      li.classList.toggle("showchildren");
      if(li.classList.contains("showchildren")){
        svg_use.setAttribute("xlink:href", "#ontario-icon-collapse");
      }
      else{
        svg_use.setAttribute("xlink:href", "#ontario-icon-expand");
      }
    };
    var class_string = "";
    if(expanded){
        class_string+="showchildren ";
    }
    if(isRoot){
        class_string+="root ";
    }

    return (
      <>
        <ul className={"treev "+(isRoot?"root":"")}>
            <li className={class_string}>
                {
                    hasChildren?
                    <span>
                        {
                          collapsable==true?
                          <svg onClick={(event) => (handleClick(event))} className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                          {expanded?<use xlinkHref="#ontario-icon-collapse"></use>:<use xlinkHref="#ontario-icon-expand"></use>} 
                          </svg>
                          :
                          <></>
                        }                        
                        {
                          hasLink?
                          <Link className=' no-deco' to={node.url} >
                            {title}
                          </Link>
                          :
                          <>{title}</>
                        }                        
                    </span>
                    :
                    <span>                        
                        {
                          hasLink?
                          <Link className=' no-deco' to={node.url} >
                            {title}
                          </Link>
                          :
                          <>{title}</>
                        }    
                    </span>
                }
                 
                    
                {/*showChildren && <Tree treeData={children} />*/}
                <Tree treeData={children} />
            </li>          
        </ul>
      </>
    );
}