import { useNavigate, useLocation } from "react-router-dom"
import {showLoadingSpinner, isIntranet} from './ui'
import qs from "qs"

import { loginRequest, apiRequest } from '../authConfig'
import { msalInstance } from '../index'

export function GetPublicUrl(){
  return process.env.PUBLIC_URL;
}

export function GetEnvVar(var_name){
  return process.env[var_name];
}


export function removeNonAlphaNumeric(string){

  var tempstring = string==undefined||string==null?'':string.trim();
  tempstring =tempstring.replace(/[`’‘]/g,'\''); 
  tempstring= tempstring.replace(/[^ \'\-A-Za-zÀ-ÖØ-öø-ÿ0-9]/g,' ');  
  //console.log(tempstring);
  return tempstring.trim();
}

export function removeNonNumeric(string){

  var tempstring = string==undefined||string==null?'':string.trim(); 
  tempstring= tempstring.replace(/[^0-9]/g,' ');  
  //console.log(tempstring);
  return tempstring.trim();
}

export function removeNonPhoneCharacters(string){

  var tempstring = string==undefined||string==null?'':string.trim(); 
  tempstring= tempstring.replace(/[^ \-0-9]/g,'');
  tempstring= tempstring.trim();
  tempstring= tempstring.replace(/\-\-/g,'\-');
  tempstring= tempstring.replace(/^\-/g,''); 
  tempstring= tempstring.replace(/\-$/g,'');  
  //console.log(tempstring);
  return tempstring.trim();
}

export function addSpaceToPostalCode(string){

  var tempstring = string==undefined||string==null?'':string.trim(); 
  tempstring= tempstring.replace(/(\b[A-Za-z][0-9][A-Za-z])([0-9][A-Za-z][0-9]\b)/g,'$1 $2');  
  //console.log(tempstring);
  return tempstring.trim();
}

export function removeExtraSpaces(string){

  var tempstring = string==undefined||string==null?'':string.trim(); 
  tempstring= tempstring.replace(/\s/g,'<>');
  tempstring = tempstring.replaceAll('><','');
  tempstring = tempstring.replaceAll('<>',' ');
  //console.log(tempstring);
  return tempstring.trim();
}

export function sanitizeString(string){
  var tempstring = removeNonAlphaNumeric(string);
  tempstring = removeExtraSpaces(tempstring);
  return tempstring.trim();
}

export function isStringNumber(string){
  return /^-?\d+$/.test(string==null?'':string);
}

export function isValidDate(dateObject){
  var h = dateObject.getHours();
  return !isNaN(h);
}

export async function OnTrackUM_AjaxCall(q){
    showLoadingSpinner(true);
    const bearer = process.env.REACT_APP_BEARER
    const url = process.env.REACT_APP_INFOGOSEARCHURL_ONTRACKUM_VER
    // Now, use JavaScript's native Fetch API to get
      // users list from Github API
    const response =  await fetch(url, {
        method: "POST", // *Type of request GET, POST, PUT, DELETE
        mode: "cors", // Type of mode of the request
        cache: "no-cache", // options like default, no-cache, reload, force-cache
        //credentials: "same-origin", // options like include, *same-origin, omit
        headers: {
          "Content-Type": "application/x-www-form-urlencoded" // request content type
          //"Content-Type": "application/json" // request content type
          ,"Authorization": bearer
          ,"accept": "application/json"
    
        },
        body: q,
        //redirect: "follow", // manual, *follow, error
        //referrerPolicy: "no-referrer", // no-referrer, *client
        // body: JSON.stringify(data) // Attach body with the request
      })
      .then(res => {
          // Unfortunately, fetch doesn't send (404 error) into the cache itself
          // You have to send it, as I have done below
          if(res.status >= 400) {
              throw new Error("Server responds with error!");
          }
          
          //console.log(res);
          return res;
      })
      .then(result => {
        showLoadingSpinner(false);
        return result;
        
      },
      // Note: it's important to handle errors here 
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components
      err => {
          showLoadingSpinner(false);
          alert(err);
      });  
      
      const users = await response.json();
      //console.log(users);
      return users;
}

export async function OldInfoGo_AjaxCall(url, data={}){
    //console.log(url);
    showLoadingSpinner(true);
    //const url = process.env.REACT_APP_INFOGOSEARCHURL
    // Now, use JavaScript's native Fetch API to get
    // users list from Github API
    var error_message = "";
    const response =  await fetch(url, {
        method: "POST", // *Type of request GET, POST, PUT, DELETE
        //mode: "cors", // Type of mode of the request
        cache: "no-cache", // options like default, no-cache, reload, force-cache
        //credentials: "same-origin", // options like include, *same-origin, omit
        headers: {
          "Content-Type": "application/x-www-form-urlencoded" // request content type
          //"Content-Type": "application/json" // request content type
          //,"Authorization": bearer
          ,"accept": "application/json"
    
        },
        body: JSON.stringify(data) // Attach body with the request
      })
      .then(res => {
          if (!res.ok) {
            throw new Error('Network response was not OK');
          }
          // Unfortunately, fetch doesn't send (404 error) into the cache itself
          // You have to send it, as I have done below
          if(res.status >= 400) {
              throw new Error("Server responds with error!");
          }
          
          //console.log(res);
          return res;
      })
      .then(result => {
        showLoadingSpinner(false);
        return result;
        
      },
      // Note: it's important to handle errors here 
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components
      err => {
          showLoadingSpinner(false);
          //alert(err);
          error_message=err;
      });  
      
      if(error_message==''){
        const response_json = await response.json();
        //console.log(users);
        return response_json;
      }
      else{
        return {error_message: error_message}
      }
}

export async function GetApiAccessToken() {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    
      /*
      * User is not signed in. Throw error or wait for user to login.
      * Do not attempt to log a user in outside of the context of MsalProvider
      */
     return null;
     //throw new Error("Error retrieving user access token")
  }
  const request = {
      ...apiRequest,
      account: accounts[0]
  };

  const authResult = await msalInstance.acquireTokenSilent(request);

  return authResult.accessToken;
}

export async function InfoGo_AjaxCall(url, data={}){
  //console.log(url);
  showLoadingSpinner(true);
  //const url = process.env.REACT_APP_INFOGOSEARCHURL
  // Now, use JavaScript's native Fetch API to get
  // users list from Github API
  var error_message = "";
  var method = "GET";
  //console.log(data);
  //console.log(data.length);
  var body = JSON.stringify(data);
  if(data!=null && body !=="{}"){
    method="POST";
  }
  //console.log(method);
  const headers = new Headers({
    "Access-Control-Allow-Origin": "*"
    ,"Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS"
    ,"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    //"Content-Type": "application/x-www-form-urlencoded" // request content type
    "Content-Type": "application/json; charset=utf-8" // request content type
    //,"Authorization": bearer
    ,"Accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*"
    
  });
  if (process.env.REACT_APP_IS_INTRANET == "YES") {
    var token = await GetApiAccessToken();
    headers.append("Authorization", "Bearer " + token);
  }
  var RequestInfo = {};
  if(method=="POST"){
    RequestInfo = {
      method: "POST", // *Type of request GET, POST, PUT, DELETE
      mode: "cors", // Type of mode of the request
      cache: "no-cache", // options like default, no-cache, reload, force-cache
      //credentials: "same-origin", // options like include, *same-origin, omit
      headers: headers,
      body: body // Attach body with the request
    }
  }
  else{
    RequestInfo = {
      method: "GET", // *Type of request GET, POST, PUT, DELETE
      mode: "cors", // Type of mode of the request
      cache: "no-cache", // options like default, no-cache, reload, force-cache
      //credentials: "same-origin", // options like include, *same-origin, omit
      headers: headers
    }
  }
  
  const response =  await fetch(url, RequestInfo)
    .then(res => {
        if (!res.ok) {
          throw new Error('Network response was not OK');
        }
        // Unfortunately, fetch doesn't send (404 error) into the cache itself
        // You have to send it, as I have done below
        if(res.status >= 400) {
            throw new Error("Server responds with error!");
        }
        
        //console.log(res);
        return res;
    })
    .then(result => {
      showLoadingSpinner(false);
      return result;
      
    },
    // Note: it's important to handle errors here 
    // instead of a catch() block so that we don't swallow
    // exceptions from actual bugs in components
    err => {
        showLoadingSpinner(false);
        //alert(err);
        error_message=err;
    });  
    
    if(error_message==''){
      const response_json = await response.json();
      //console.log(users);
      return response_json;
    }
    else{
      return {error_message: error_message}
    }
}

export function calculateTotalPages(total_results){
  var pagesize = process.env.REACT_APP_PAGESIZE;
  var temp = total_results/pagesize;
  return Math.ceil(temp);
}

export function calculateResultPageRange(currentPage, total_results){
  var pagesize = process.env.REACT_APP_PAGESIZE;
  var temp = currentPage>0?(((currentPage-1)*pagesize)+1):0;
  var temp2 = currentPage>0?(currentPage*pagesize):0;  
  
  //total_results/pagesize;
  return [temp, temp2>total_results?total_results:temp2];
}

export function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export function formatDisplayName(firstName, lastName, middleName, honorfifics){
  var full_name = firstName + " " + (middleName==null?"":(middleName+" ")) + lastName;
  return honorfifics!==null && honorfifics.trim().length>0? honorfifics+ " " + full_name: full_name;
}

export function formatPhoneNumber (phoneNumber_string){

  var ph = removeNonPhoneCharacters(phoneNumber_string).replaceAll('-', ' ').replaceAll(' ', '');
  if(ph.length>3){
    if(ph.substring(0,2)=='18'){
      ph = '1-' + ph.substring(1,4)+'-'+
      (ph.length>7?(ph.substring(4,7)+'-'+ph.substring(7)):ph.substring(4));
    }
    else{
      ph = ph.substring(0,3)+'-'+
      (ph.length>6?(ph.substring(3,6)+'-'+ph.substring(6)):ph.substring(3));
    }
    
  }
  //console.log(ph);
  return ph.trim();
}

export function formatOrgDisplayName(orgPrefix, orgName){
  /*var fullOrgName = orgPrefix!=null && orgPrefix!=undefined && orgPrefix!=''? orgPrefix + ' ':'';
  fullOrgName = fullOrgName + orgName;
  return fullOrgName;*/
  //Helene doesn't want the Org to show prefixes anymore 2023-03-24
  return orgName;
}

//---------------- QUERY STRING STUFF --------------------------
export function GetCurrentQuery() {
  const location = useLocation();
  const existingQueries = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })

  return (existingQueries);    
}

export function StringifyQueryObject(query){
  const queryString = qs.stringify(
      { ...query },
      { skipNulls: true, skipUndefined: true }
  );
  return queryString;
}

//------------------ locale stuff ------------------
export function GetLocalePath(lang){
  
  var locale_path = lang=='en'?'/':('/'+lang+'/');
  return locale_path;
}

export function GetPath(pathname, lang='en'){
  var locale_path = GetLocalePath(lang);
  var path = '';
  pathname = pathname.toLowerCase();
  
  switch(pathname){
    case 'results':
      path=locale_path+'results';
      break;
    case 'org':
      path=locale_path+'org';
      break;
    case 'emp':
      path=locale_path+'emp';
      break;
    case 'forms':
      path=locale_path+'forms';
      break;
    case 'help':
      path=locale_path+'help';
      break;
    case 'index':
    case 'home':
      path=locale_path+'index';
      break;
    default:
      path=locale_path+pathname;
      break;
  }
  return path;
}

//---------- GET IP ADDRESS ----------------

//---------- Format Page Title with Pipe ----------------
export function formatPageTitle(title,t){
  if(t==null || t==undefined){
    if(isIntranet())
      return title +' | INFO-GO Intranet';
    else
      return title +' | INFO-GO';
  }
  else
  {  
    if(isIntranet())
      return title +' | ' + t('info-go-intranet');
    else
      return title +' | ' + t('info-go');
  }
}

//----------- Copy to Clipboard ---------------------
export function copyToClipboard(event, str){
  navigator.clipboard.writeText(str);
  var x = event.target.text;  
}

//---------- Capitalize only the first Letter ----------------
export function capitalizeOnlyFirstLetter(label){
  return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
}

//------------- Date time format --------------
export function getDateParts(date_string, locale="EN"){
  var date = new Date(date_string!=null?date_string?.toString():"");
  if(!isValidDate(date)){ return [null, null]}
  var yyyy = date.getFullYear();
  var mm = (date.getMonth()+1);
  var dd = date.getDate();
  if(dd<10) 
  {
      dd=`0${dd}`;
  }
  if(mm<10) 
  {
      mm=`0${mm}`;
  } 
  var datePart = `${yyyy}-${mm}-${dd}`;

  var hh = date.getHours();
  var mi = (date.getMinutes());
  var sec = date.getSeconds();
  if(mi<10) 
  {
      mi=`0${mi}`;
  }
  if(sec<10) 
  {
    sec=`0${sec}`;
  }
  
  var timePart;
  if(locale.toUpperCase()=='FR'){
    if(hh<10) 
    {
        hh=`0${hh}`;
    }
    timePart = `${hh}\u00A0:${mi}\u00A0:${sec}`;
  }
  else{
    var ampm = "AM";
    if(hh>12){
      hh = hh-12;
      ampm="PM";
    }
    if(hh<10) 
    {
        hh=`0${hh}`;
    }
    timePart = `${hh}:${mi}:${sec} ${ampm}`;
  }
  return [datePart, timePart];
}

//-------------- CHECK IF MOBILE ----------------------
export function onMobile(){

  if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
    // the user is using a mobile device, so redirect to the mobile version of the website
    return true;
  }
  return false;

}

export function oniOS(){

  if (navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)) {
    // the user is using a mobile device, so redirect to the mobile version of the website
    return true;
  }
  return false;

}

//---------------- Browser Detection ----------------------

/*
// Opera 8.0+
export function isOpera(){ return (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0; }
// Firefox 1.0+
export function isFirefox() { typeof InstallTrigger !== 'undefined'; }
// Safari 3.0+ "[object HTMLElementConstructor]" 
export function  isSafari()  { /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)); }
// Internet Explorer 6-11
export function isIE() { return  false || !!document.documentMode; }
// Edge 20+
export function isEdge() { return !isIE && !!window.StyleMedia; }
// Chrome 1 - 79
export function isChrome() { return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime); }
// Edge (based on chromium) detection
export function isEdgeChromium() { return isChrome && (navigator.userAgent.indexOf("Edg") != -1); }
// Blink engine detection
export function isBlink() { return (isChrome || isOpera) && !!window.CSS; }
*/
export function browserDetect(){
                 
  let userAgent = navigator.userAgent;
  let browserName;
  
  if(userAgent.match(/chrome|chromium|crios/i)){
      browserName = "chrome";
    }else if(userAgent.match(/firefox|fxios/i)){
      browserName = "firefox";
    }  else if(userAgent.match(/safari/i)){
      browserName = "safari";
    }else if(userAgent.match(/opr\//i)){
      browserName = "opera";
    } else if(userAgent.match(/edg/i)){
      browserName = "edge";
    }else{
      browserName="No browser detection";
    }
  
   return browserName;
  
}
//----------------- for random element ID ----------------
export function uniqueID() {
  return Math.floor(Math.random() * Date.now())
}

