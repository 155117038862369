
import React from 'react';
import { useState, memo, useRef} from 'react';
import {useNavigate} from 'react-router-dom';

import {SaveUpdateRequest} from '../CurrentVer/APICalls';
// @ts-ignore
import OrgTreeView from '../CurrentVer/OrgTree.tsx'
// @ts-ignore
import {IUpdateRequest} from '../../interfaces/IUpdateRequest.tsx'
// @ts-ignore
import InfogoInput from '../InfogoInput.tsx'
// @ts-ignore
import InfogoSelect from '../InfogoSelect.tsx'
// @ts-ignore
import InfogoTextArea from '../InfogoTextArea.tsx'
// @ts-ignore
import OnUnloadConfirm from '../OnUnloadConfirm.tsx'
// @ts-ignore
import InfogoHintExpander from '../InfogoHintExpander.tsx'

import {scrollToTop, confirm_dialog} from '../../js/ui';
import {capitalizeOnlyFirstLetter, GetLocalePath} from '../../js/utils';
import {IAssignment} from '../../interfaces/IAssignment'
import {IKeyValuePair} from '../../interfaces/IGeneric'
import { useTranslation } from 'react-i18next';
import { useMsal } from "@azure/msal-react";
import TableOfContents from './TableOfContents'
import { IIntranetForm, IIntranetFormLookup } from '../../interfaces/IUpdateRequest';

const DeleteEmployeeForm = ({ formSetting }) => {
    var intranetFormSetting:IIntranetForm = formSetting;
    var currentitem:IAssignment = intranetFormSetting.assignment;
    var lookups:IIntranetFormLookup = intranetFormSetting.lookup;
    const { accounts } = useMsal();
    const sub_name = accounts[0] && accounts[0].name;
    const sub_em = accounts[0] && accounts[0].username;
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    var current_locale = i18n.language;
    const { t } = useTranslation();
    
    const [isDirty, setDirty] = useState(false);
    const edit_form = useRef<HTMLFormElement>(null);
  
    const appendToAlertMessages = (new_alert, anchor="")=>{      
      if(intranetFormSetting.update_alert_fn!=null){
        intranetFormSetting.update_alert_fn("APPEND", new_alert, anchor);
      }     
    }
    const resetAlertMessages = ()=>{      
      if(intranetFormSetting.update_alert_fn!=null){
        intranetFormSetting.update_alert_fn("CLEAR");
      }     
    }
  
      var item_email = "";
      currentitem.emails?.forEach(e => {
        if(e.primary){
          item_email=e.emailAddress;
        }
      });
      var item_phone = "";
      var item_phone_ext = "";
      
      currentitem.phones?.forEach(e => {
        if(e.primary){
          item_phone=e.phoneNumber?e.phoneNumber:'';
          var p_temp=item_phone.split('ext');
          if(p_temp.length>1){
            item_phone = p_temp[0].trim();
            item_phone_ext = p_temp[1].trim().replace('.','');
          }
        }
      });
    
    
      var coor_name = '';
      var coor_phone ='';
      var coor_email = '';
      var curr_unit = "";
      var curr_branch = "";
      var curr_division = "";
      var curr_ministry = "";
  
      if(currentitem.topOrg != null){
        coor_name = currentitem.topOrg.coordinatorName!=null?currentitem.topOrg.coordinatorName:'';
        coor_phone = currentitem.topOrg.coordinatorPhone!=null?currentitem.topOrg.coordinatorPhone:'';
        coor_email = currentitem.topOrg.coordinatorEmail!=null?currentitem.topOrg.coordinatorEmail:'';
        curr_ministry = currentitem.topOrg.orgName!=null?currentitem.topOrg.orgName: 'N/A';
      }
  
      
      if(currentitem.associatedOrg != null){
        curr_unit = currentitem.associatedOrg.orgName!=null?currentitem.associatedOrg.orgName:"N/A";
        if(currentitem.associatedOrg.path!=null){
          if(currentitem.associatedOrg.path.length>1){
            curr_division=currentitem.associatedOrg.path[1].orgName!=null?currentitem.associatedOrg.path[1].orgName:"N/A";
          }
          if(currentitem.associatedOrg.path.length>2){
            curr_branch=currentitem.associatedOrg.path[2].orgName!=null?currentitem.associatedOrg.path[2].orgName:"N/A";
          }
        }
        
      }    
  
    /************* PREPARE LOOKUPS ********************* */
    var positions = lookups.position_lookup;
    var pos_dl:IKeyValuePair[]=[];    
    positions.map((lookupItem, index)=>{
      if(current_locale=='fr')
      { pos_dl.push({key: lookupItem.code, value: lookupItem.labelFr}); }
      else
      { pos_dl.push({key: lookupItem.code, value: lookupItem.label}); }      
    })
 
    /************************************************** */

    //console.log(currentitem);
    const [inputs, setInputs] = useState({Publish_To: 'Intranet & Internet', First_Name: currentitem.firstName, Last_Name: currentitem.lastName, Email: item_email 
      ,Job_Title: currentitem.positionTitle, Employee_Status: '', Telephone_Number: item_phone, Telephone_Ext: item_phone_ext, Name_of_Direct_Report: '', Email_of_Direct_Report:''    
      ,Ministry: curr_ministry, Division: curr_division, Branch: curr_branch, Unit: curr_unit
      ,Additional_Info:'', Employee_Status_Display: ''
      ,Coordinator_Name: coor_name, Coordinator_Phone: coor_phone, Coordinator_Email: coor_email
      });
    var orig_inputs={First_Name: currentitem.firstName, Last_Name: currentitem.lastName, Email: item_email 
        ,Job_Title: currentitem.positionTitle, Employee_Status: '', Telephone_Number: item_phone, Telephone_Ext: item_phone_ext, Name_of_Direct_Report: '', Email_of_Direct_Report:''
        ,Ministry: curr_ministry, Division: curr_division, Branch: curr_branch, Unit: curr_unit
        ,Coordinator_Name: coor_name, Coordinator_Phone: coor_phone, Coordinator_Email: coor_email
        };
    
    var update_req:IUpdateRequest = {'failToSend': false, 'type': 'DELETE', 'itemId': currentitem._id?.toString(), 'originalJSON': JSON.stringify(orig_inputs) };
    
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
        if(!isDirty) {setDirty(true);}         
    }
    
    const handleChangeSelect = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      const text = event.target.options[event.target.selectedIndex].text;
      setInputs(values => ({...values, [name]: value}));
      setInputs(values => ({...values, [name+"_Display"]: text}));
      //console.log(text); 
      if(!isDirty) {setDirty(true);}        
    }

    const submitOnClick = async(event) => {
        event.preventDefault();
        resetAlertMessages();
        if(!edit_form?.current?.checkValidity()){
          //edit_form?.current?.reportValidity()
            //console.log('nope')
            scrollToTop();
            return;
        }
        
        else{
          if(isDirty) {setDirty(false);}
          update_req.updatedJSON=JSON.stringify(inputs);
          //console.log(inputs);
          var result2 = await SaveUpdateRequest(update_req);
          console.log(result2);
          if(result2=='RequestSaved'){
            resetAlertMessages();
            navigate({
              pathname: GetLocalePath(i18n.language)+"confirmation",
              
            });
          }
          else{
            appendToAlertMessages(result2.error_message);
            scrollToTop();
          }
        
        }
    }
  
    const resetOnClick = (event)=>{
      event.stopPropagation();
      event.preventDefault();
      //if the page is already dirty, the on onbeforeunload in OnUnloadConfirm will handle the prompt 
      //this is to prevent the confirm dialog from showing up twice
      if(isDirty){
        window.location.reload();
      }
      else{
        if(confirm_dialog(t('reset-confirmation'))) { window.location.reload(); }
      }            
    }
    // To follow capitalization standard
    pos_dl.forEach(obj=>{
      if(obj.key!=='LEAVINGOPS'){
        obj.value = capitalizeOnlyFirstLetter(obj.value);
      }
    })
  
    //FORM STARTS HERE
    if(currentitem !== undefined && currentitem != null){    
  
      return(
        <>
        <OnUnloadConfirm shouldPrompt={isDirty}></OnUnloadConfirm>
        <TableOfContents/>
        <form ref={edit_form} onSubmit={(e)=>{e.preventDefault();}} className="intranet-form">        
          <div className="ontario-form-group">
  
            <fieldset className="ontario-fieldset">
                <legend className="ontario-fieldset__legend ontario-fieldset__legend--large">
                <h2 className='ontario-h3 content-title'>{t('employee-info')}</h2>
                </legend>

                <label className="ontario-label" htmlFor="Publish_To">
                {t('publish-to')}
                </label>
                <select required className="ontario-input ontario-dropdown" 
                id="Publish_To" 
                name="Publish_To"
                onChange={handleChange}
                value={inputs.Publish_To}>
                  <option value="Intranet & Internet" >{t('intranet-and-internet')}</option>
                  <option value="Intranet Only" >{t('intranet-only')}</option>
                </select>

                <InfogoInput className={"ontario-input--20-char-width"} readOnly={true} label={'firstname'} name={'First_Name'} id={'txt_First_Name'}  value={inputs.First_Name}></InfogoInput>
                <InfogoInput className={"ontario-input--20-char-width"} readOnly={true} label={'lastname'} name={'Last_Name'} id={'txt_Last_Name'} value={inputs.Last_Name}></InfogoInput>
                <InfogoInput className={"ontario-input--20-char-width"} readOnly={true} label={'email'} name={'Email'} id={'txt_Email'} value={inputs.Email}></InfogoInput>
                <InfogoSelect required={true} label={'employee-status'} name={'Employee_Status'} id={'txt_Employee_Status'} onChange={handleChangeSelect} value={inputs.Employee_Status} setAlertMessages={appendToAlertMessages} includeBlank={true} blankText={'select'} options={pos_dl}></InfogoSelect>
            
            </fieldset>
  
            <fieldset className="ontario-fieldset">
                <legend className="ontario-fieldset__legend ontario-fieldset__legend--large">
                <h2 className='ontario-h3 content-title'>{t('position-info')}</h2>
                </legend>
                <InfogoInput className={"ontario-input--20-char-width"} readOnly={true} label={'job-title'} name={'Job_Title'} id={'txt_Job_Title'} value={inputs.Job_Title}></InfogoInput>
                <InfogoInput className={"ontario-input--10-char-width"} readOnly={true} label={'phone'} name={'Telephone_Number'} id={'txt_Telephone_Number'} value={inputs.Telephone_Number}></InfogoInput>
                <InfogoInput className={"ontario-input--10-char-width"} readOnly={true} label={'phone-ext'} name={'Telephone_Ext'} id={'txt_Telephone_Ext'} value={inputs.Telephone_Ext}></InfogoInput>
                <InfogoInput className={"ontario-input--20-char-width"} required={true} label={'direct-report-name'} name={'Name_of_Direct_Report'} id={'txt_Name_of_Direct_Report'} onChange={handleChange} value={inputs.Name_of_Direct_Report} setAlertMessages={appendToAlertMessages}>
                  <InfogoHintExpander id={'direct-report-name-hint'} title={t('direct-report-hint')}>
                      {t('direct-report-hint-delete')}
                  </InfogoHintExpander>   
                </InfogoInput>
                             
                <InfogoInput className={"ontario-input--20-char-width"} required={true} label={'direct-report-email'} name={'Email_of_Direct_Report'} id={'txt_Email_of_Direct_Report'} onChange={handleChange} value={inputs.Email_of_Direct_Report} setAlertMessages={appendToAlertMessages} type={'email'} ></InfogoInput>
                <InfogoTextArea required={false} label={'additional-info'} name={'Additional_Info'} id={'txt_Additional_Info'} onChange={handleChange} value={inputs.Additional_Info} hint={'additional-info-hint'}></InfogoTextArea>
                
            </fieldset>
  
            <fieldset className="ontario-fieldset">
                <legend className="ontario-fieldset__legend ontario-fieldset__legend--large">
                <h2 className='ontario-h3 content-title'>{t('organization-hierarchy')}</h2>
                </legend>
                <OrgTreeView org={currentitem.associatedOrg} expanded={true} querystring={''} do_not_show_last_node={false} allow_link={false} allow_expand_collapse={false}></OrgTreeView>
            </fieldset>
       

            <fieldset className="ontario-fieldset">
                <legend className="ontario-fieldset__legend ontario-fieldset__legend--large">
                <h2 className='ontario-h3 content-title'>{t('coordinator-info')}</h2>
                </legend>
                <InfogoInput className={"ontario-input--20-char-width"} value={coor_name} label={'coordinator-name'} name={'Coordinator_Name'} id={'txt_Coordinator_Name'} readOnly={true}></InfogoInput>
                <InfogoInput className={"ontario-input--20-char-width"} value={coor_phone} label={'coordinator-phone'} name={'Coordinator_Phone'} id={'txt_Coordinator_Phone'} readOnly={true}></InfogoInput>
            </fieldset>
              
            <fieldset className="ontario-fieldset">
                <legend className="ontario-fieldset__legend ontario-fieldset__legend--large">
                <h2 className='ontario-h3 content-title'>{t('submitter-info')}</h2>
                </legend>
                <InfogoInput className={"ontario-input--20-char-width"} value={sub_name} label={'submitter-name'} name={'Submitter_Name'} id={'txt_Submitter_Name'} readOnly={true}></InfogoInput>
                <InfogoInput className={"ontario-input--20-char-width"} value={sub_em} label={'submitter-email'} name={'Submitter_Email'} id={'txt_Submitter_Email'} readOnly={true}></InfogoInput>
            </fieldset>
            <p className="ontario-hint" id="submit-hint">{t('submit-hint')}</p><br />
            <button className="ontario-button ontario-button--primary" onClick={submitOnClick} type="submit">
              {t('submit')}
            </button>                  
            <button className="ontario-button ontario-button--secondary" onClick={resetOnClick} type="reset">
              {t('reset')}
            </button>      
          </div>
        </form>
  
  
  
        
  
        </>
      )
    }
    else{
      return(<></>)
    }
    
  }

  export default memo(DeleteEmployeeForm)