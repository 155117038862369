import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import { isIntranet } from './js/ui'

const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
var ai = new ApplicationInsights({
    config: {
        connectionString: (isIntranet()? process.env.REACT_APP_APPINSIGHTS_CONNECTION_INTRANET:process.env.REACT_APP_APPINSIGHTS_CONNECTION_PUBLIC),
        enableAutoRouteTracking: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
ai.loadAppInsights();

export default (Component) => withAITracking(reactPlugin, Component)
export const appInsights = ai
export {reactPlugin}
