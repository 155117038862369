import React from 'react';
// @ts-ignore
import Accordion, {AccordionItem} from '../Accordion.tsx';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchCategory } from '../CurrentVer/APICalls';
import {ICategory} from '../../interfaces/ICategory';
import {GetPath, onMobile} from '../../js/utils';
import { Link } from 'react-router-dom';

const TopLevelOrganizationAccordion = ({header_title})=>{
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  var current_locale = i18n.language;
  const [cats, setCats] = useState([]);
  var _path = GetPath('org', i18n.language);
  const getTopLevelOrgs = async () => {
    var x = await SearchCategory();
    setCats(x.categories);    
  }  
  

  useEffect(() => {
    getTopLevelOrgs();    
  }, [])

  if(cats.length>0){
    return(
      <>
      <h2 className=''>{header_title}</h2>
      <Accordion>
      {
        cats.map((cat_item:ICategory) => {
          var sectionScrollable=cat_item.organizations!=null && cat_item.organizations?.length>13?true:false;
          sectionScrollable = onMobile()?false:sectionScrollable;
          return(
              <AccordionItem header={current_locale=='fr'?cat_item.categoryNameFr!:cat_item.categoryName!} scrollable={sectionScrollable} key={cat_item.categoryId} id={cat_item.categoryId.toString()}>
                <ul className='ul-container'>
                {
                  cat_item.organizations?.map((org) =>{
                    return(
                      <li key={org.orgId}><span></span>
                        <Link to={_path+'?id=' +  encodeURIComponent(org.orgId!=null?org.orgId:0)} >
                          {current_locale=='fr'?org.orgNameFr:org.orgName}
                        </Link>
                      </li>
                    )
                  }
                  )
                }
                </ul>
              </AccordionItem>
            
          )
        })
      }
      </Accordion>
      </>
    )
  }
  else{
    return(<></>)
  }
}



export default memo(TopLevelOrganizationAccordion)