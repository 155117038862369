import React, {ReactNode} from 'react';
import { useRef, forwardRef} from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface ICustomInputProps {
  errorMessage?: string;
  hint?: string;
  setAlertMessages?:Function;
  children?: ReactNode;
}

export type InputProps = ICustomInputProps & React.HTMLProps<HTMLTextAreaElement>
const InfogoInput = forwardRef<HTMLTextAreaElement, InputProps> ((props, forwardedRef) => {
  const fallbackRef = useRef<HTMLTextAreaElement>(null);
  const ref = forwardedRef!=undefined && forwardedRef!=null?forwardedRef:fallbackRef;
      
  const { t } = useTranslation();
  var label = t(props.label?props.label:'');
  var className_loc="";
  if(props.className!=null && props.className!=undefined)
  {
    className_loc=props.className;
  }
  var anchor_id = props.id?props.id + "-anchor":"";
  var error_id = props.id?props.id + "-error":"";
  const onError = (event)=>{
    event.preventDefault();
    var txt = event.target;
    var errorMsg = txt.validationMessage;
    errorMsg = props.errorMessage!=null && props.errorMessage!=undefined && props.errorMessage!=''?t(props.errorMessage):t(errorMsg);
    txt.setAttribute('aria-invalid', 'true');
    txt.setAttribute('aria-describedby', error_id);
    if(!txt.classList.contains('ontario-input__error')){
      txt.classList.add('ontario-input__error');
    }

    var container = event.target.closest('.infogotextarea');
    var err_div = container.getElementsByClassName('ontario-error-messaging');
    if(err_div.length >0 && err_div[0].classList.contains('hide')){
      err_div[0].classList.remove('hide');
    }

    if(err_div.length >0){
      var err_msg = err_div[0].getElementsByClassName('ontario-error-messaging__content');
      if(err_msg.length>0){
        err_msg[0].innerText=errorMsg;
      }
      if(props.setAlertMessages!=null){
      props.setAlertMessages(label+' - ' + errorMsg, anchor_id);
      }
    }
  }

  const onInput = (event)=>{
    event.preventDefault();
    var txt = event.target;
    txt.setAttribute('aria-invalid', 'false');
    txt.setAttribute('aria-describedby', '');
    if(txt.classList.contains('ontario-input__error')){
      txt.classList.remove('ontario-input__error');
    }

    var container = event.target.closest('.infogotextarea');
    var err_div = container.getElementsByClassName('ontario-error-messaging');
    if(err_div.length >0 && !err_div[0].classList.contains('hide')){
      err_div[0].classList.add('hide');
    }

    if(props.onChange!=null){
      props.onChange(event);
    }
  }

  /*
  useEffect(() => {
    console.log(ref.current)
  }, [ref])
  */
  return (
    <div className='infogotextarea' id={anchor_id}>
      <label className={"ontario-label"} htmlFor={props.id}>
        {t(props.label?props.label:'')}
        {props.required?<span className="ontario-label__flag">{t('required-label')}</span>:<></>}
      </label>
      {
        props.hint?<p id={props.id?props.id + "-hint":""} className="ontario-hint">{t(props.hint)}</p>:<></>
      }            
      {
        (props.required==true || props.errorMessage || props.pattern)
        ?
        <div className="ontario-error-messaging hide" role="alert" id={error_id}>
            <span className="ontario-error-messaging__icon">
                <svg className="ontario-icon" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="#ontario-icon-alert-error"></use>
                </svg>
            </span>
            <span className="ontario-error-messaging__content">
                                  
            </span>
        </div>
        :
        <></>
      }

      <textarea className={"ontario-input ontario-textarea " +  + className_loc} name={props.name}
      required={props.required?props.required:false}
      aria-required={props.required?props.required:false}
      readOnly={props.readOnly?props.readOnly:false}
      disabled={props.disabled?props.disabled:false}
      maxLength={props.maxLength?props.maxLength:1000}
      id={props.id}      
      onInvalid={(e) => (onError(e))}      
      onChange={(e) => (onInput(e))}
      value={props.value}
      ref={ref}
      />
      {props.children}
    </div>
  
  )
}) 







export default InfogoInput