import React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {IOrg} from '../../interfaces/IOrganization'
import {GetPath} from '../../js/utils';

interface IOrganizationList{
  orgItems?: IOrg[],
  header_title?:string,
  querystring?:string,
  exclusion_list?:number[]
}

const OrganizationList = (props:IOrganizationList)=>{
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    var current_locale = i18n.language;
    var has_others = props.orgItems!=null && props.orgItems.length>0?true:false;
    var b = props.querystring!=null && props.querystring.length>0?"&"+props.querystring:"";
    var _path = GetPath('org', i18n.language);
    if(has_others){
      return(
        <div className="ontario-margin-bottom-40-!">
        <h3 className='ontario-h5'>{props.header_title}:</h3>
        <ul className='ul-container'>
        {
          props.orgItems!.map((org_item, index) => 
          { 
            var org:IOrg=org_item;
            
            //Employee Item
            if(props.exclusion_list?.includes(org.orgId)==false){
              if(current_locale=='fr'){           
              
                return(
                
                  <li key={index}><span></span>
                    <Link className='' to={_path+'?id=' +  encodeURIComponent(org.orgId)  + b} >
                          {org.orgNameFr}
                    </Link>
                  </li>                 
        
                )
              }
              else{
                
                return(
                
                  <li key={index}><span></span>
                    <Link className='' to={_path+'?id=' +  encodeURIComponent(org.orgId)  + b} >
                          {org.orgName}
                    </Link>
                  </li>
                    
                )
              }
            }
            else{
              return(<></>)
            }
          }                
          )
        }
        </ul>
        
        </div>
      )
      
    }
    else{
      return(
        <></>
      )
    }
  
}



export default memo(OrganizationList)