import React from 'react';
import { memo} from 'react';
import { useTranslation } from 'react-i18next';

import {copyToClipboard} from '../js/utils'


const CopyButton = ({string_to_copy}) =>{
  const { t } = useTranslation();
  
  const copyOnClick = async (e, str) => {
    copyToClipboard(e, str);
    e.target.getElementsByTagName('span')[0].classList.remove("hide");
  }

  const copyOnMouseOut = async (e) => {
    e.target.getElementsByTagName('span')[0].classList.add("hide");
  }

  const onKeyup = (e) => {
    if(e.keyCode==13/* ENTER */ || e.keyCode==49/* SpaceBar */){
      e.stopPropagation();
      e.preventDefault();
      e.onClick(e);
    }
  }

  return(
    
    <button onClick={(e)=>copyOnClick(e, string_to_copy)} className="mini-tag-button ontario-button--tertiary tooltip" type='button' aria-label={t('copy-to-clipboard')} title={t('copy-to-clipboard')} onMouseOut={(e)=>copyOnMouseOut(e)} onBlur={(e)=>copyOnMouseOut(e)} >
      <span className="tooltiptext hide" aria-live={"assertive"} role="alert">{t('copied')}</span>
      {t('copy')}
    </button>
    
  )
}


export default memo(CopyButton)