import { useEffect, useState, memo } from 'react';
import React from 'react';
import {useTranslation } from 'react-i18next';

import { AuthenticatedTemplate } from "@azure/msal-react";

import { SearchEmployeeForUpdate} from '../CurrentVer/APICalls';
// @ts-ignore
import {ISearchParametersForUpdate} from '../../../interfaces/ISearchForm.tsx'

import {GetCurrentQuery, StringifyQueryObject} from '../../js/utils';
import {useNavigate} from 'react-router-dom';
import {GetLocalePath} from '../../js/utils';


// @ts-ignore
import SearchResult from '../CurrentVer/SearchResult.tsx';
import {useSearchParams} from 'react-router-dom';
import {scrollToTop} from '../../js/ui';


const ShowUpdateEmployeeSearchResults = () =>
{   

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
        
    let current_query= GetCurrentQuery();
    const [queryString] = useSearchParams();
    const [searchparams, setSearchparams] = useState<ISearchParametersForUpdate>({...current_query});
    const [founditems, setFounditems] = useState([]);
    let resultcount_for_this_page = founditems.length; //Items returned for this page
    var action_word = "";
    if(searchparams.updatetype=="EditEmp"){
      action_word=t('edit');
    }
    else if(searchparams.updatetype=="DeleteEmp"){
      action_word=t('delete');
    }
    else if(searchparams.updatetype=="MoveEmp"){
      action_word=t('move');
    }



    const doSearch = async (search_par:ISearchParametersForUpdate) => {
      if(search_par.lastname!=undefined && search_par.firstname!=undefined && search_par.lastname != '' && search_par.firstName != ''){
        var result = await SearchEmployeeForUpdate(search_par);
        
        if(result.error_message==null || result.error_message==''){
          if(result.individuals !=null){
            var sorted = result.individuals;
            if(sorted.length>1){
              setFounditems(sorted);
            }
            else{
              var q = {'id': sorted[0].assignments[0].assignmentId }
              var x = StringifyQueryObject(q);
              navigate({
                pathname: GetLocalePath(i18n.language)+searchparams.updatetype, 
                search: x           
            });
            }
          }
          else{
            setFounditems(result);
          }
          
        }
        scrollToTop();
      }    
    }  

    const continueOnClick = (event) => {
      event.preventDefault();
      //navigate(-1);
      navigate({
          pathname: GetLocalePath(i18n.language)+"forms",            
      });
  }

    useEffect(() => {
      //console.log('useEffect fired');
      doSearch(searchparams);
    }, [searchparams])

    if(resultcount_for_this_page >0){
      
      return (
        <AuthenticatedTemplate>
        <div className='ontario-row ontario-margin-top-24-! ontario-margin-bottom-24-!'>
          <div className='ontario-column ontario-medium-12' aria-live='assertive' aria-atomic="true">
              {resultcount_for_this_page>1?<p className='ontario-lead-statement'>Multiple records found. Please select a record to {action_word}.</p>:<></>}
              <SearchResult founditems={founditems} keywords={''} emp_link_path={searchparams.updatetype} org_link_path={''} use_current_query_in_links={false} disable_secondary_links={true}></SearchResult>
          </div>
        </div>
        </AuthenticatedTemplate>
      );
    }
    
    else{
      return(
        <div className='ontario-row ontario-margin-top-24-! ontario-margin-bottom-24-!'>
        <div className='ontario-column ontario-medium-12'>
          <span style={ { fontWeight: 'bold' } } >
          {t('no-results')}            
          </span>
          <ul>
            <li>{t('no-results-1')}</li>
            <li>{t('no-results-3')}</li>
          </ul>
          

        </div>
      </div>
  
      )
    }    

} 

export default memo(ShowUpdateEmployeeSearchResults)