import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import ODS_HEADER from '../components/Header.tsx';
import ODS_FOOTER from '../components/Footer.js';
import {Link} from 'react-router-dom'
import StepIndicator from '../components/StepIndicator.tsx';
import Accordion, {AccordionItem} from '../components/Accordion.tsx'
import {isIntranet, scrollToTop} from '../js/ui.js';
import { formatPageTitle, GetPath } from '../js/utils.js';
import BreadCrumbs from '../components/BreadCrumb.tsx'

const Help = (props) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();  
  var cl = i18n.language;
  document.title = formatPageTitle(t('title-help'), t);
  document.documentElement.lang=(cl);
  
  useEffect(() => {
    scrollToTop();
  }, [])

  return (
      <>                
        <div id="layout-header"><ODS_HEADER /></div>        
        <main className='' id="main-content" style={{"minHeight":"80%"}} tabIndex="-1" lang={cl}>
            <BreadCrumbs>
            </BreadCrumbs>
            <div className="ontario-row">
            <div className="ontario-main-content ontario-small-12 ontario-columns ontario-margin-top-24-!">                
                <div id="app-main">
                <StepIndicator href={'index'} title={'title-help'} label={'back-to-home'} hideBackLink={true}></StepIndicator>
                <div style={{marginTop:"2.5rem"}}>
                {
                  isIntranet()
                  ?<IntranetHelp lang={cl}></IntranetHelp>
                  :<PublicHelp lang={cl}></PublicHelp>
                }
                </div>
                </div>                
            </div>
            </div>
        </main>
        <div id="layout-footer" className='autoTopMargin'><ODS_FOOTER /></div>
      </>
  )
  
  
};

const PublicHelp = (props) =>{
  return(
    <>
    {
      props.lang=='fr'?<Help_fr />
      :
      <Help_en />    
    }
    </>
  )
}

const IntranetHelp = (props) =>{
  return(
    <>
    {
      props.lang=='fr'?<Intranet_Help_fr />
      :
      <Intranet_Help_en />    
    }
    </>
  )
}

const Help_en = (props) =>{

    return(

      <>
      
      <h2 className=''>Frequently asked questions and answers</h2>
      <Accordion>
        
        <AccordionItem header={"What is INFO-GO?"}>
          <p className='info-go'>
          INFO-GO is an electronic publication of the Ontario Government’s directory. This directory provides information on the government’s employees and organizations in English and French, including:
          </p>
          <ul>
            <li>employee name, positions, office locations, and work contact information.</li>
            <li>organization name, description, office locations, and contact information.</li>
          </ul>
        </AccordionItem>

        <AccordionItem header={"How do I search for an employee?"}>
          <p className='info-go'>
          In the search box, enter at least 2 characters of an employee's name, email, job title, or phone number, click on the "Search" button, and filter the results by employees.
          </p>
        </AccordionItem>

        <AccordionItem header={"How do I search for an organization?"}>
          <p  className='info-go'>In the search box, enter at least 2 characters of an organization's name or office location, click on the "Search" button, and filter the results by organizations.</p>
        </AccordionItem>

        <AccordionItem header={"How can I improve my search results?"}>
          <p  className='info-go'>There are several ways to improve your search results:</p>
          <ul>
            <li>when searching using a name:
              <ul>
                <li>name with or without accents are accepted</li>
              </ul>
            </li>
            <li>when searching using a phone number:
              <ul>
                <li>number with or without hyphen is accepted</li>
                <li>please exclude the country code and extensions</li>
              </ul>
            </li>
            <li>when searching using an address:
              <ul>
                <li>building name, street number, street name, and postal code are accepted</li>
                <li>please exclude floor number, unit/suite number, and street directions</li>
              </ul>
            </li>
          </ul>
        </AccordionItem>

        <AccordionItem header={"How do I browse the government’s organizations?"}>
          <p className='info-go'>While on the INFO-GO Home page, expand the organization categories that are listed under the "Browse the government's organizations" section and select an organization to view its details.</p>
        </AccordionItem>

        <AccordionItem header={"How do I share the search results with my colleague?"}>
          <p className='info-go'>Simply copy the URL from the browser's address bar and share the URL of the search results with your colleague.</p>
        </AccordionItem>

        <AccordionItem header={"How do I share the contact information of an employee with my colleague?"}>
          <p className='info-go'>There are two ways to share the contact information of an employee. While viewing an employee's profile:</p>
          <ul>
            <li>simply copy the URL from the browser's address bar and share the URL with your colleague, or</li>
            <li>use the "Share" button to email the URL of the employee's profile page to your colleague.</li>
          </ul>
          <p className='info-go'>The "Share" button is indicated by an icon that looks like three dots forming a triangle shape with two lines connecting the dots.</p>
        </AccordionItem>

        <AccordionItem header={"How do I share the details of an organization with my colleague?"}>
          <p className='info-go'>There are two ways to share the details of an organization. While viewing an organization's information:</p>
          <ul>
            <li>simply copy the URL from the browser's address bar and share the URL with your colleague, or</li>
            <li>use the "Share" button to email the URL of the employee's profile page to your colleague.</li>
          </ul>
          <p className='info-go'>The "Share" button is indicated by an icon that looks like three dots forming a triangle shape with two lines connecting the dots.</p>
        </AccordionItem>

        <AccordionItem header={"What are the supported browsers?"}>
          <p className='info-go'>INFO-GO is designed to be compatible with the latest version of the following browsers as of March 31st, 2023:</p>
          <ul>
            <li>Google Chrome</li>
            <li>Microsoft Edge (Windows 10)</li>
            <li>Mozilla Firefox</li>
            <li>Safari</li>
          </ul>
          <p className='info-go'>Using an unsupported browser may result in the website not functioning properly, including issues with web accessibility compliance.</p>
        </AccordionItem>

        <AccordionItem header={"How do I obtain an extract of the Ontario Government's directory?"}>
          <p className='info-go'>To meet the requirements outlined in the <a href="https://www.ontario.ca/page/ontarios-open-data-directive">Ontario's Open Data Directive</a>, an extract of the Ontario Government's directory is made available through the <a href="https://data.ontario.ca/dataset/government-of-ontario-employee-and-organization-directory-info-go">Ontario Data Catalogue</a>.</p>
        </AccordionItem>
        
        

      </Accordion>
      </>

    )


}

const Help_fr = (props) =>{

  return(

    <>
    
    <h2 className=''>Foire aux questions et réponses</h2>
    <Accordion>
      
      <AccordionItem header={"Qu'est-ce qu'INFO-GO?"}>
        <p className='info-go'>
        INFO-GO est une publication électronique du Répertoire des employés et des bureaux du gouvernement de l’Ontario. Ce répertoire fournit des données sur les employés et les bureaux du gouvernement en français et en anglais. On y trouve plus précisément&nbsp;:
        </p>
        <ul>
          <li>nom de l’employé, poste, adresse de bureau, et coordonnées du lieu de travail.</li>
          <li>nom du bureau, description, adresse du bureau et coordonnées.</li>
        </ul>
      </AccordionItem>

      <AccordionItem header={"Comment effectuer une recherche pour trouver un employé?"}>
        <p className='info-go'>
        Dans le champ de recherche, entrez au moins deux caractères du nom de l’employé ou de son courriel, poste ou numéro de téléphone, plus cliquez sur la touche «&nbsp;Recherche&nbsp;» et filtrez les résultats par employé.
        </p>
      </AccordionItem>

      <AccordionItem header={"Comment effectuer une recherche pour trouver un bureau?"}>
        <p  className='info-go'>Dans le champ de recherche, entrez au moins deux caractères du nom de bureau ou emplacement du bureau, plus cliquez sur la touche «&nbsp;Recherche&nbsp;» et filtrez les résultats par bureau.</p>
      </AccordionItem>

      <AccordionItem header={"Comment puis-je améliorer les résultats de ma recherche?"}>
        <p  className='info-go'>Il y a plusieurs façons d’améliorer les résultats de votre recherche&nbsp;: </p>
        <ul>
          <li>Quand on effectue une recherche en inscrivant un nom&nbsp;:
            <ul>
              <li>On peut inscrire un nom avec ou sans accent</li>
            </ul>
          </li>
          <li>Quand on effectue une recherche en inscrivant un numéro de téléphone&nbsp;:
            <ul>
              <li>On peut inscrire un numéro avec ou sans tiret</li>
              <li>Veuillez exclure le code et l’extension d’identification du pays</li>
            </ul>
          </li>
          <li>Quand on effectue une recherche en inscrivant une adresse&nbsp;: 
            <ul>
              <li>On peut inscrire le nom de l’édifice, le numéro municipal, le nom de la rue ou le code postal</li>
              <li>Veuillez exclure le numéro de l’étage, le numéro du bureau ou de l’unité et les points cardinaux</li>
            </ul>
          </li>
        </ul>
      </AccordionItem>

      <AccordionItem header={"Que dois-je faire pour consulter la liste des bureaux du gouvernement?"}>
        <p className='info-go'>À partir de la page d’accueil INFO-GO, élargissez les catégories de bureaux qui figurent sous la section «&nbsp;Consulter la liste des bureaux du gouvernement&nbsp;», puis sélectionnez un bureau pour afficher les détails correspondants.</p>
      </AccordionItem>

      <AccordionItem header={"Que dois-je faire pour partager les résultats de la recherche avec un collègue?"}>
        <p className='info-go'>Il suffit de copier l’URL de la page des résultats de recherche figurant dans la barre d’adresse du navigateur et de la partager avec votre collègue.</p>
      </AccordionItem>

      <AccordionItem header={"Que dois-je faire pour partager les coordonnées d’un employé avec un collègue?"}>
        <p className='info-go'>Pour partager les coordonnées d’un employé, on peut procéder de deux manières. À partir du profil de l’employé&nbsp;:</p>
        <ul>
          <li>Il suffit de copier l’URL figurant dans la barre d’adresse du navigateur et de la partager avec votre collègue</li>
          <li>On peut également utiliser la touche «&nbsp;Partager&nbsp;» pour transmettre par courriel l’URL de la page de profil de l’employé à votre collègue.</li>
        </ul>
        <p className='info-go'>La touche «&nbsp;Partager&nbsp;» est représentée par une icône qui ressemble à trois points formant un triangle, les points étant reliés par deux lignes.</p>
      </AccordionItem>

      <AccordionItem header={"Que dois-je faire pour partager les détails concernant un bureau avec un collègue?"}>
        <p className='info-go'>Pour partager les détails concernant un bureau, on peut procéder de deux manières. À partir des données concernant le bureau en question&nbsp;:</p>
        <ul>
          <li>Il suffit de copier l’URL figurant dans la barre d’adresse du navigateur et de la partager avec votre collègue</li>
          <li>On peut également utiliser la touche «&nbsp;Partager&nbsp;» pour transmettre par courriel l’URL de la page des données concernant le bureau à votre collègue.</li>
        </ul>
        <p className='info-go'>La touche «&nbsp;Partager&nbsp;» est représentée par une icône qui ressemble à trois points formant un triangle, les points étant reliés par deux lignes.</p>
      </AccordionItem>

      

      <AccordionItem header={"Quels sont les navigateurs qui sont pris en charge?"}>
        <p className='info-go'>INFO-GO est conçu pour être compatible avec les versions les plus récentes des navigateurs suivants au 31&nbsp;mars&nbsp;2023&nbsp;:</p>
        <ul>
          <li>Google Chrome</li>
          <li>Microsoft Edge (Windows 10)</li>
          <li>Mozilla Firefox</li>
          <li>Safari</li>
        </ul>
        <p className='info-go'>L’utilisation d’un navigateur qui n’est pas pris en charge peut nuire au bon fonctionnement du site Web ou entraîner des problèmes de conformité aux normes d’accessibilité web.</p>
      </AccordionItem>

      <AccordionItem header={"Que dois-je faire pour obtenir un extrait du Répertoire des employés et des bureaux du gouvernement de l’Ontario?"}>
        <p className='info-go'>Afin de satisfaire aux exigences de la <a href="https://www.ontario.ca/fr/page/directive-sur-les-donnees-ouvertes-de-lontario">Directive sur les données ouvertes de l’Ontario</a>, un extrait du Répertoire des employés et des bureaux du gouvernement peut être obtenu à partir du <a href="https://data.ontario.ca/fr/dataset/government-of-ontario-employee-and-organization-directory-info-go">Catalogue de données ouvertes de l’Ontario</a>.</p>
      </AccordionItem>

    </Accordion>
    </>

  )


}

const Intranet_Help_en = (props) =>{

  return(

    <>
    
    <h2 className=''>Frequently asked questions and answers</h2>
    <Accordion>
      
      <AccordionItem header={"What is INFO-GO Intranet?"}>
        <p className='info-go'>
        INFO-GO Intranet is an electronic publication of the Ontario Government’s directory that is available to only internal staff. This internal directory provides information on the government’s employees and organizations that is not available to the public, including:
        </p>
        <ul>
          <li>employee name, positions, office locations, and work contact information.</li>
          <li>organization name, description, office locations, and contact information.</li>
        </ul>
      </AccordionItem>

      <AccordionItem header={"How do I search for an employee?"}>
        <p className='info-go'>
        In the search box, enter at least 2 characters of an employee's name, email, job title, or phone number, click on the "Search" button, and filter the results by employees.
        </p>
      </AccordionItem>

      <AccordionItem header={"How do I search for an organization?"}>
        <p  className='info-go'>In the search box, enter at least 2 characters of an organization's name or office location, click on the "Search" button, and filter the results by organizations.</p>
      </AccordionItem>

      <AccordionItem header={"How can I improve my search results?"}>
        <p  className='info-go'>There are several ways to improve your search results:</p>
        <ul>
          <li>when searching using a name:
            <ul>
              <li>name with or without accents are accepted</li>
            </ul>
          </li>
          <li>when searching using a phone number:
            <ul>
              <li>number with or without hyphen is accepted</li>
              <li>please exclude the country code and extensions</li>
            </ul>
          </li>
          <li>when searching using an address:
            <ul>
              <li>building name, street number, street name, and postal code are accepted</li>
              <li>please exclude floor number, unit/suite number, and street directions</li>
            </ul>
          </li>
        </ul>
      </AccordionItem>

      <AccordionItem header={"How do I browse the government’s organizations?"}>
        <p className='info-go'>While on the INFO-GO Intranet Home page, expand the organization categories that are listed under the "Browse the government's organizations" section and select an organization to view its details.</p>
      </AccordionItem>

      <AccordionItem header={"How do I share the search results with another internal staff?"}>
        <p className='info-go'>Content from INFO-GO Intranet cannot be shared with the public. To share the search results with another internal staff, simply copy the URL from the browser's address bar and share the URL of the search results using a secured method of your choice.</p>
      </AccordionItem>

      <AccordionItem header={"How do I share the contact information of an employee with another internal staff?"}>
        <p className='info-go'>Content from INFO-GO Intranet cannot be shared with the public. To share the contact information of an employee with another internal staff, choose one of the following two options while viewing an employee's profile:</p>
        <ul>
          <li>simply copy the URL from the browser's address bar and share the URL with your colleague using a secured method of your choice, or</li>
          <li>use the "Share" button to email the URL of the employee's profile page to your colleague.</li>
        </ul>
        <p className='info-go'>The "Share" button is indicated by an icon that looks like three dots forming a triangle shape with two lines connecting the dots.</p>
      </AccordionItem>

      <AccordionItem header={"How do I share the details of an organization with another internal staff?"}>
        <p className='info-go'>Content from INFO-GO Intranet cannot be shared with the public. To share the details of an organization with another internal staff, choose one of the following two options while viewing an organization's information:</p>
        <ul>
          <li>simply copy the URL from the browser's address bar and share the URL with your colleague using a secured method of your choice, or</li>
          <li>use the "Share" button to email the URL of the organization's details page to your colleague.</li>
        </ul>
        <p className='info-go'>The "Share" button is indicated by an icon that looks like three dots forming a triangle shape with two lines connecting the dots.</p>
      </AccordionItem>

      <AccordionItem header={"How do I report an error or a discrepancy?"}>
        <p className='info-go'>Please send an email to <a href="mailto:infogo@ontario.ca">infogo@ontario.ca</a>.</p>
      </AccordionItem>
      
      <AccordionItem header={"How do I submit a request to add a new employee?"}>
        <p className='info-go'>While on the INFO-GO Intranet Home page, navigate to the “Update employee information” page by using the “Update employee information” link in the header. Once you are on the “Update employee information” page:</p>
        <ol>
          <li>choose the “Add employee” action from the dropdown,</li>
          <li>click on the “Continue” button,</li>
          <li>follow the on-screen instructions to complete the “Add a new employee” form, and</li>
          <li>click the “Submit” button.</li>
        </ol>
        <p className='info-go'>All fields that have the “(required)” indicator must be filled to submit the form.</p>
      </AccordionItem>

      <AccordionItem header={"How do I submit a request to edit an employee?"}>
        <p className='info-go'>While on the INFO-GO Intranet Home page, navigate to the “Update employee information” page by using the “Update employee information” link in the header. Once you are on the “Update employee information” page:</p>
        <ol>
          <li>choose “Edit employee” action from the dropdown,</li>
          <li>provide a first and last name to retrieve the employee’s profile,</li>
          <li>if there are multiple records that match the search criteria, select the desire profile to edit, </li>
          <li>follow the on-screen instructions to complete the “Edit an employee” form, and</li>
          <li>click the “Submit” button.</li>
        </ol>
        <p className='info-go'>All fields that have the “(required)” indicator must be filled to submit the form.</p>
      </AccordionItem>

      <AccordionItem header={"How do I submit a request to delete an employee?"}>
        <p className='info-go'>While on the INFO-GO Intranet Home page, navigate to the “Update employee information” page by using the “Update employee information” link in the header. Once you are on the “Update employee information” page:</p>
        <ol>
          <li>choose the “Delete employee” action from the dropdown,</li>
          <li>provide a first and last name to retrieve the employee’s profile,</li>
          <li>if there are multiple records that match the search criteria, select the desire profile to edit, </li>
          <li>follow the on-screen instructions to complete the “Delete an employee” form, and</li>
          <li>click the “Submit” button.</li>
        </ol>
        <p className='info-go'>All fields that have the “(required)” indicator must be filled to submit the form.</p>
      </AccordionItem>

      <AccordionItem header={"How do I submit a request to move an employee?"}>
        <p className='info-go'>While on the INFO-GO Intranet Home page, navigate to the “Update employee information” page by using the “Update employee information” link in the header. Once you are on the “Update employee information” page:</p>
        <ol>
          <li>choose the “Move employee” action from the dropdown,</li>
          <li>provide a first and last name to retrieve the employee’s profile,</li>
          <li>if there are multiple records that match the search criteria, select the desire profile to edit, </li>
          <li>follow the on-screen instructions to complete the “Move an employee” form, and</li>
          <li>click the “Submit” button.</li>
        </ol>
        <p className='info-go'>All fields that have the “(required)” indicator must be filled to submit the form.</p>
      </AccordionItem>

      <AccordionItem header={"How do I submit a request to update multiple employees at the same time?"}>
        <p className='info-go'>Submitting a request to update multiple employees at the same time is a feature that we are considering. Meanwhile, please contact your <Link to={GetPath('org','en')+"?id=3385"}>Ministry Lead Coordinator</Link> to update multiple employees.</p>
      </AccordionItem>

      <AccordionItem header={"How do I submit a request to update a vacant position?"}>
        <p className='info-go'>Please contact your <Link to={GetPath('org','en')+"?id=3385"}>Ministry Lead Coordinator</Link> and provide them with the details about the vacant position.</p>
      </AccordionItem>

      <AccordionItem header={"How do I submit a request to update an organization’s information?"}>
        <p className='info-go'>Your request is directed to your <Link to={GetPath('org','en')+"?id=3385"}>Ministry Lead Coordinator</Link> for processing within 48 hours. You will also receive an email with a copy of your request.</p>
        <p>Once your request has been processed:</p>
        <ul>
          <li>updates that were published to Intranet will reflect only on INFO-GO Intranet, and</li>
          <li>updates that were published to both Internet and Intranet will be reflected on INFO-GO and INFO-GO Intranet.</li>
        </ul>
      </AccordionItem>

      <AccordionItem header={"How are my data collected and updated on INFO-GO Intranet?"}>
        <p className='info-go'>Upon collecting your data during the onboarding procedure, your <Link to={GetPath('org','en')+"?id=3385"}>Ministry Lead Coordinator</Link> should start the process to have your information captured and updated in an information management system called Organization Management System (OMS). Once OMS processed the changes, your information will be reflected on INFO-GO Intranet the next day. </p>
      </AccordionItem>

      <AccordionItem header={"What are the supported browsers?"}>
        <p className='info-go'>INFO-GO Intranet is designed to be compatible with the latest version of the following browsers as of March 31st, 2023:</p>
        <ul>
          <li>Google Chrome</li>
          <li>Microsoft Edge (Windows 10)</li>
          <li>Mozilla Firefox</li>
          <li>Safari</li>
        </ul>
        <p className='info-go'>Using an unsupported browser may result in the website not functioning properly, including issues with web accessibility compliance.</p>
      </AccordionItem>


    </Accordion>
    </>

  )


}

const Intranet_Help_fr = (props) =>{

return(

  <>
  
  <h2 className=''>Foire aux questions et réponses</h2>
  <Accordion>
    
    <AccordionItem header={"What is INFO-GO?"}>
      <p className='info-go'>
      INFO-GO is an electronic publication of the Ontario Government’s directory. This directory provides information on the government’s employees and organizations in English and French, including:
      </p>
      <ul>
        <li>employee name, positions, office locations, and work contact information.</li>
        <li>organization name, description, office locations, and contact information.</li>
      </ul>
    </AccordionItem>

    <AccordionItem header={"How do I search for an employee?"}>
      <p className='info-go'>
      In the search box, enter at least 2 characters of an employee's name, email, job title, or phone number, click on the "Search" button, and filter the results by employees.
      </p>
    </AccordionItem>

    <AccordionItem header={"How do I search for an organization?"}>
      <p  className='info-go'>In the search box, enter at least 2 characters of an organization's name or office location, click on the "Search" button, and filter the results by organizations.</p>
    </AccordionItem>

    <AccordionItem header={"How can I improve my search results?"}>
      <p  className='info-go'>There are several ways to improve your search results:</p>
      <ul>
        <li>when searching using a name
          <ul>
            <li>name with or without accents are accepted</li>
          </ul>
        </li>
        <li>when searching using a phone number:
          <ul>
            <li>number with or without hyphen is accepted</li>
            <li>please exclude the country code and extensions</li>
          </ul>
        </li>
        <li>when searching using an address:
          <ul>
            <li>building name, street number, street name, and postal code are accepted</li>
            <li>please exclude floor number, unit/suite number, and street directions</li>
          </ul>
        </li>
      </ul>
    </AccordionItem>

    <AccordionItem header={"How do I browse the government’s organizations?"}>
      <p className='info-go'>While on the INFO-GO Home page, expand the organization categories that are listed under the "Browse the government's organizations" section and select an organization to view its details.</p>
    </AccordionItem>

    <AccordionItem header={"How do I share the search results with my colleague?"}>
      <p className='info-go'>Simply copy the URL from the browser's address bar and share the URL of the search results with your colleague.</p>
    </AccordionItem>

    <AccordionItem header={"How do I share the contact information of an employee with my colleague?"}>
      <p className='info-go'>There are two ways to share the contact information of an employee. While viewing an employee's profile:</p>
      <ul>
        <li>simply copy the URL from the browser's address bar and share the URL with your colleague, or</li>
        <li>use the "Share" button to email the URL of the employee's profile page to your colleague.</li>
      </ul>
      <p className='info-go'>The "Share" button is indicated by an icon that looks like three dots forming a triangle shape with two lines connecting the dots.</p>
    </AccordionItem>

    <AccordionItem header={"How do I share the details of an organization with my colleague?"}>
      <p className='info-go'>There are two ways to share the details of an organization. While viewing an organization's information:</p>
      <ul>
        <li>simply copy the URL from the browser's address bar and share the URL with your colleague, or</li>
        <li>use the "Share" button to email the URL of the employee's profile page to your colleague.</li>
      </ul>
      <p className='info-go'>The "Share" button is indicated by an icon that looks like three dots forming a triangle shape with two lines connecting the dots.</p>
    </AccordionItem>

    <AccordionItem header={"How do I report an error or a discrepancy?"}>
      <p className='info-go'>Please send an email to <a href="mailto:infogo@ontario.ca">infogo@ontario.ca</a>.</p>
    </AccordionItem>

    <AccordionItem header={"What are the supported browsers?"}>
      <p className='info-go'>INFO-GO is designed to be compatible with the latest version of the following browsers as of March 31st, 2023:</p>
      <ul>
        <li>Google Chrome</li>
        <li>Microsoft Edge (Windows 10)</li>
        <li>Mozilla Firefox</li>
        <li>Safari</li>
      </ul>
      <p className='info-go'>Using an unsupported browser may result in the website not functioning properly, including issues with web accessibility compliance.</p>
    </AccordionItem>

    <AccordionItem header={"How do I obtain an extract of the Ontario Government's directory?"}>
      <p className='info-go'>To meet the requirements outlined in the <a href="https://www.ontario.ca/page/ontarios-open-data-directive">Ontario's Open Data Directive</a>, an extract of the Ontario Government's directory is made available through the <a href="https://data.ontario.ca/dataset/government-of-ontario-employee-and-organization-directory-info-go">Ontario Data Catalogue</a>.</p>
    </AccordionItem>

    <AccordionItem header={""}>
      <p className='info-go'></p>
    </AccordionItem>

  </Accordion>
  </>

)


}

export default Help;