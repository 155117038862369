import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";


i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    fr: {
      translations: require('./locales/fr/translations.json')
    }
  },
  keySeparator:false,
  ns: ['translations'],
  defaultNS: 'translations',
  locales: ['en', 'fr'],
  defaultLocale: 'en',
});

export default i18n;
