import { useState} from 'react';
import { useTranslation } from 'react-i18next';
import {useNavigate, Link} from 'react-router-dom';
// @ts-ignore
import ODS_HEADER from '../../components/Header.tsx';
import ODS_FOOTER from '../../components/Footer.js';
import {GetPath, formatPageTitle} from '../../js/utils';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
// @ts-ignore
import BreadCrumbs from '../../components/BreadCrumb.tsx'
// @ts-ignore
import StepIndicator from '../../components/StepIndicator.tsx';

const ConfirmationPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { i18n } = useTranslation();

    document.title = formatPageTitle(t('title-confirmation'), t);
    document.documentElement.lang=(i18n.language);
    const [currLocale, setCurrLocale] = useState(i18n.language);

    const continueOnClick = (event) => {
        event.preventDefault();
        //navigate(-1);
        navigate({
            pathname: GetPath('forms',i18n.language),            
        });
    }

    return (
        <AuthenticatedTemplate>        
          <div id="layout-header"><ODS_HEADER onLocaleChange={setCurrLocale} /></div>
          <noscript>{t('no-javascript')}</noscript>
          <main id="main-content" style={{"minHeight":"80%"}} tabIndex={-1}>
            <BreadCrumbs>
            </BreadCrumbs>
              <div className="ontario-row">
                <div className="ontario-main-content ontario-small-12 ontario-columns ontario-margin-top-24-!">                
                    <div id="app-main">
                        <StepIndicator href={'forms'} title={'title-confirmation'} label={'back-to-form'} hideBackLink={true}></StepIndicator>
                        <div className="ontario-alert ontario-alert--success">
                            <div className="ontario-alert__header">
                                <div className="ontario-alert__header-icon">
                                    <svg className="ontario-icon" alt="" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                        <use href="#ontario-icon-alert-success"></use>
                                    </svg>
                                </div>
                                <h2 className="ontario-alert__header-title ontario-h4">Your request has been submitted</h2>
                            </div>
                            <div className="ontario-alert__body">
                                <p>Your <Link to={GetPath('org',i18n.language)+'?id=' +  encodeURIComponent(3385)} >Ministry Lead Coordinator</Link> will review and process your request within 48 hours. Please look out for an email confirmation with a summary of your request.</p>
                            </div>
                        </div>
                        
                        
                        <p>
                        <button className="ontario-button ontario-button--primary" onClick={continueOnClick} type="button">
                            {t('back-to-form')}
                        </button>
                        </p>

                    </div>
                </div>
              </div>
          </main>
          <div id="layout-footer" className='autoTopMargin'><ODS_FOOTER /></div>
        </AuthenticatedTemplate>
        
    );
    };
  

  

export default ConfirmationPage;


