import { useTranslation } from "react-i18next";
function Spinner(props){
    const { t } = useTranslation();
    return(        
        <div className="spinner-container" id="loading_spinner" aria-live="assertive" role="alert" aria-label={t('loading')}>
            <div className="spinner"></div>
           
            <p>{t('loading')}</p>
            
        </div>
    )
}




export default Spinner;