import React,{ memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const TableOfContents = () => {
  const [contents, setContents] = useState([]);
  const { t } = useTranslation();
  useEffect(()=>{
    let contentTiles = document.querySelectorAll('.content-title')
    contentTiles.forEach((contentTile, index) => contentTile.setAttribute('id', 'content-'+(index+1)))
    setContents(Array.from(contentTiles))
  },[])

  return(
    <>
    <h2 id="table-of-contents" className="ontario-h3">{t('table-of-contents')}
    <a className="ontario-h5 ontario-margin-left-16-! ontario-show-on-focus" href="#toc-end">{' '+t('skip-navigation')}</a></h2>
    <ul>
      {contents.map((content, index)=>(
        <li key={index}><a href={"#content-"+(index+1)}>{content.innerText}</a></li>
      ))}
    </ul>
    <hr id="toc-end"/>
    </>
  )
}
export default memo(TableOfContents)