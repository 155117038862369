import React from 'react';
import { useState, useContext, createContext, memo, useRef} from 'react';
import {useSearchParams} from "react-router-dom";

import { useReactToPrint } from 'react-to-print';
// @ts-ignore
import EditEmployeeForm from './EditEmployeeForm.tsx';
// @ts-ignore
import AddEmployeeForm from './AddEmployeeForm.tsx';
// @ts-ignore
import DeleteEmployeeForm from './DeleteEmployeeForm.tsx';
// @ts-ignore
import MoveEmployeeForm from './MoveEmployeeForm.tsx';

import {ISearchItemDetailVM} from '../../interfaces/ISearchForm'
import {IAssignment} from '../../interfaces/IAssignment'
import { useTranslation } from 'react-i18next';
import { IIntranetForm, IIntranetFormLookup } from '../../interfaces/IUpdateRequest';
// @ts-ignore


const ItemDetailContext = createContext<ISearchItemDetailVM>({} as ISearchItemDetailVM)

function UpdateEmployeeShowForm({formSetting}){
    //console.log(props);
    var intranetFormSetting:IIntranetForm = formSetting;
    const [searchParams] = useSearchParams();
    
    const detailElement = useRef<HTMLDivElement>(null);

    
      if(intranetFormSetting.assignment?.individualId!==undefined){
        
        if(intranetFormSetting.action_type=="EDIT"){
          return(
            
              <div ref={detailElement}>                
                <EditEmployeeForm formSetting={intranetFormSetting}></EditEmployeeForm>
              </div>
            
            )
        }
        else if(intranetFormSetting.action_type=="ADD"){
          return(
            
              <div ref={detailElement}>                
                <AddEmployeeForm formSetting={intranetFormSetting}></AddEmployeeForm>
              </div>
            
            )
        }
        else if(intranetFormSetting.action_type=="DELETE"){
          return(
            
              <div ref={detailElement}>                
                <DeleteEmployeeForm formSetting={intranetFormSetting}></DeleteEmployeeForm>
              </div>
            
            )
        }
        else if(intranetFormSetting.action_type=="MOVE"){
          return(
            
              <div ref={detailElement}>                
                <MoveEmployeeForm formSetting={intranetFormSetting}></MoveEmployeeForm>
              </div>
            
            )
        }
        else{
          return(
            
              <div ref={detailElement}>
                ERROR
              </div>
            
            )
        }
        
    
      }
      else{
        return(<></>)
      }
      
}


export default memo(UpdateEmployeeShowForm)