import React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {IOtherAssignment} from '../../interfaces/IAssignment'
import {GetPath} from '../../js/utils';



const AssignmentList = ({assignmentItems, showName, header_title, exclusion_list, querystring})=>{
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    var current_locale = i18n.language;
    var has_others = assignmentItems!=null && assignmentItems.length>0?true:false;
    var b = querystring!=null && querystring.length>0?"&"+querystring:"";

    if(has_others){
      return(
        <div className="ontario-margin-bottom-40-!">
        <h3 className='ontario-h5'>{header_title}:</h3>
        {
          assignmentItems.map((assignment_item) => 
          { 
            var searchType = assignment_item.individualId!==undefined?1:2;
            var ass:IOtherAssignment=assignment_item;
            var _path = GetPath('emp', i18n.language);
            var _pathOrg = GetPath('org', i18n.language);
            //Employee Item
            if(exclusion_list.includes(ass.assignmentId)==false){
              if(current_locale=='fr'){           
              
                return(
                
                  <ul className='ul-container-inline' key={ass.assignmentId.toString()}>
                    {
                      showName?
                      <>
                      <li><span></span>
                        <Link className='' to={_path + '?id=' +  encodeURIComponent(ass.assignmentId)  + b} >
                              {ass.individualNameFr}
                        </Link>
                      </li>
                      <li><span></span>
                        {ass.positionTitleFr}
                        {
                          ass.assignmentType?.labelFr!=null && ass.assignmentType.labelFr.length>0?" ("+ass.assignmentType.labelFr+")":<></>                            
                        }
                      </li>
                      </>
                      
                      :
                      <li><span></span>
                      <h4 className='ontario-h6 inline-header'>
                        <Link className='' to={_path+'?id=' +  encodeURIComponent(ass.assignmentId)  + b} >
                              {ass.positionTitleFr}
                        </Link>                  
                        {
                          ass.assignmentType?.labelFr!=null && ass.assignmentType.labelFr.length>0?" ("+ass.assignmentType.labelFr+")":<></>                            
                        }
                        </h4>
                      </li>
                    }                
                      
                    {
                      ass.associatedOrg?.orgNameFr!=null && ass.associatedOrg?.orgNameFr.length>0?
                      <li><span></span>
                        <Link className='' to={_pathOrg+'?id=' +  encodeURIComponent(ass.associatedOrg?.orgId)  + b} >
                              {ass.associatedOrg?.orgNameFr}
                        </Link>
                        {(ass.associatedOrg?.topOrg!=null && ass.associatedOrg.topOrg.orgNameFr!=null?" ("+ass.associatedOrg.topOrg.orgNameFr+")":<></>)}
                      </li>                    
                      :<></> 
                    }
                    
                    {    
                      ass.primaryPhoneFr!=null && ass.primaryPhoneFr.length>0?
                      <li><span></span>
                      <a className="phoneLink" href={"tel:" + ass.primaryPhone}>{ass.primaryPhoneFr}</a>
                      </li>
                      :<></>
                    }
                    {
                      ass.primaryEmail!=null && ass.primaryEmail.length>0?
                      <li><span></span><a href={"mailto: " + ass.primaryEmail} className="email">
                        {ass.primaryEmail}
                        </a>
                      </li>
                      :<></>
                    }                                                   
                                
                  </ul>
        
                  )
              }
              else{
                
                return(
                
                  <ul className='ul-container-inline' key={ass.assignmentId.toString()}>
                    {
                      showName?
                      <>
                      <li><span></span>
                        <h4 className='ontario-h6 inline-header'><Link className='' to={_path+'?id=' +  encodeURIComponent(ass.assignmentId)  + b} >
                              {ass.individualName}
                        </Link>
                        </h4>
                      </li>
                      <li><span></span>
                        {ass.positionTitle}  
                        {
                          ass.assignmentType?.label!=null && ass.assignmentType.label.length>0?" ("+ass.assignmentType.label+")":<></>                            
                        }
                      </li>
                      </>
                      :
                      <li><span></span>
                        <h4 className='ontario-h6 inline-header'>
                          <Link className='' to={_path+'?id=' +  encodeURIComponent(ass.assignmentId)  + b} >
                              {ass.positionTitle}
                        </Link>
                           
                        {
                          ass.assignmentType?.label!=null && ass.assignmentType.label.length>0?" ("+ass.assignmentType.label+")":<></>                            
                        }
                        </h4>
                      </li>
                    }
                    
                    {
                      ass.associatedOrg?.orgName!=null && ass.associatedOrg?.orgName.length>0?
                      <li><span></span>
                        <Link className='' to={_pathOrg+'?id=' +  encodeURIComponent(ass.associatedOrg?.orgId)  + b} >
                              {ass.associatedOrg?.orgName}
                        </Link>
                        {
                          (ass.associatedOrg?.topOrg!=null && ass.associatedOrg.topOrg.orgName!=null?" ("+ass.associatedOrg.topOrg.orgName+")":<></>)                
                        }
                      </li>
                      :
                      <></> 
                    }
                      
                    
                    {
        
                      ass.primaryPhone!=null && ass.primaryPhone.length>0?
                      <li><span></span><a className="phoneLink" href={"tel:" + ass.primaryPhone}>{ass.primaryPhone}</a>
                      
                      </li>
                      :<></>
                    }
                    {
                      ass.primaryEmail!=null && ass.primaryEmail.length>0?
                      <li><span></span><a href={"mailto: " + ass.primaryEmail} className="email">
                      {ass.primaryEmail}
                      </a>
                      </li>
                      :<></>
                    }                                                   
                                
                  </ul>
        
                  )
              }
            }
            else{
              return(<></>)
            }
          }                
        )
        }
        </div>
      )
      
    }
    else{
      return(
        <></>
      )
    }
  
  }

  export default memo(AssignmentList)