import React from 'react';
import {useNavigate} from 'react-router-dom';
import {GetPath, capitalizeOnlyFirstLetter} from '../js/utils';
import {confirm_dialog} from '../js/ui'
import { useRef, forwardRef} from 'react';
import { useTranslation } from 'react-i18next';

interface IBackStepperProps {
  search?:string
  isPageDirty?:boolean;
  hideBackLink?:boolean;
}

export type BackStepperLinkProps = React.HTMLProps<HTMLButtonElement> & IBackStepperProps;
const StepIndicator = forwardRef<HTMLButtonElement, BackStepperLinkProps> ((props, forwardedRef) => {
  const fallbackRef = useRef<HTMLButtonElement>(null);
      
  const ref = forwardedRef!=null && forwardedRef!=undefined? forwardedRef : fallbackRef;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  var label = props.label!=null && props.label!=undefined?props.label.toString():'';
  var title = props.title!=null && props.title!=undefined?props.title.toString():'';
  var pathname = props.href!=null && props.href!=undefined?props.href.toString():'';
  var searchquery = props.search!=null && props.search!=undefined?props.search.toString():'';
  const backToResults = (event) => {
    event.preventDefault(); 
    if(searchquery.length>0){
      navigate({
          pathname: GetPath(pathname,i18n.language),
          search: searchquery
      });
    }
    else{
      navigate({
        pathname: GetPath(pathname, i18n.language)        
    });
    }
  }

  const onButtClick = (event) =>{
    event.preventDefault();
    if(props.isPageDirty){
      if(confirm_dialog(t('changes-not-saved'))) { backToResults(event); }
    }
    else{
      backToResults(event);
    }
  }

  return (    
    <div className="ontario-step-indicator">
      <div className='ontario-step-indicator--with-back-button' role={props.hideBackLink!=null && props.hideBackLink==true?"":"navigation"}>
          
        <button className="ontario-button ontario-button--tertiary noprint" onClick={onButtClick} type="button" ref={ref} style={{visibility:props.hideBackLink!=null && props.hideBackLink==true?"hidden":"visible"}}>            
          <svg className="ontario-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
            <use xlinkHref="#ontario-icon-chevron-left"></use>
          </svg>{t(label)}
        </button>
        
        {
          title.length>0?
          <span className="ontario-h4"><h1 className="infogo-h4">{t(title)}</h1></span>
          :
          <></>
        }
      </div>
      <hr />
    </div>  
  )
}) 







export default StepIndicator